import * as React from "react";

import {ThreeDots} from "react-loader-spinner"


export const ThreeDotLoader = ( visibility: any) => {
  return (
    visibility.visibility && (
      <div className="DotLoaderHolder">
        <span className="d-flex justify-content-center align-items-center">
          <ThreeDots
            height={80}
            width={80}
            radius={9}
            color="#615070"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            visible={true}
          />
        </span>
      </div>
    )
  );
}

