import {
    CREATE_GROUPS,
    DELETE_GROUPS,
    LIST_GROUPS,
    UPLOAD_GROUPS_ICON
} from "../EndPoint"
import TokenService from "../TokenService/TokenService";
import IResponse from "../IResponse";
import IGroupService from "./IGroupService";
import instance, { multiPartInstance } from "../AxiosInstance";


export default class GroupService {

    //// Get all agents 
    public static async GetGroupList(pageNumber?: number, pageSize?: number, searchText?:string): Promise<IResponse> {
        return new Promise(async (resolve, reject) => {
            await instance.get(pageSize && pageNumber ? `${LIST_GROUPS}?limit=${pageSize}&page=${pageNumber}&search=${searchText}` : LIST_GROUPS).then(res => {
                if (res.status == 200) {
                    resolve(res.data)
                }
            }).catch(err => {
                console.log(err)
            })
        })
    }


    // Create group API
    public static async CreateGroup(groupObj: IGroupService): Promise<IResponse> {
        return new Promise(async (resolve, reject) => {
            await instance.post(CREATE_GROUPS, JSON.stringify(groupObj)).then(res => {
                if (groupObj.uploadIcon) {
                    let file = new FormData()
                    file.append('files', groupObj.uploadIcon)
                    this.UploadGeoupIcon(groupObj.name, file).then(resF => {
                        resolve(res.data)
                    })
                } else resolve(res.data)
            }).catch(error => {
                console.log(error)
            })

        })
    }


    // Delete Group API
    public static async DeleteGroup(gpName: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            // const data = JSON.stringify({name:gpName})
            instance.delete(`${DELETE_GROUPS}?name=${gpName}`,).then(res => {
                if (res.status == 200) {
                    resolve(res.data)
                }
            }).catch(error => {
                console.log(error)
            })
        })
    }
    public static async UpdateGroup(groupObj: IGroupService) {
        return new Promise((resolve, reject) => {

        })
    }

    public static async UploadGeoupIcon(name: string, file: FormData): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            multiPartInstance.post(`${UPLOAD_GROUPS_ICON}/groups-icon?name=${name}`, file).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }


}