import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Store } from './Stores/Store';
import { Provider } from 'react-redux';
import BotSessions from './Components/Bot/BotSessions';
import ResetPassword from './Components/Login/ResetPassword';
import BotPreview from './Components/Bot/BotPreview';

class Doc extends React.Component {
  componentDidMount() {
    document.title = "zeaiQ.ai"
  }

  render() {
    return (
      <></>
    )
  }
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <React.StrictMode>
    <Doc />
    <Provider store={Store} >
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<App />} />
          <Route path='/Bot/:apiKey/:assistantId' element={<BotSessions />} />
          <Route path='/ResetPassword' element={<ResetPassword />} />
          <Route path='/BotPreview/:apiKey/:assistantId' element={<BotPreview />} />
          <Route path="*" element={<p><center><h1>404 page</h1></center></p>}></Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
