import React, { useState, useRef } from "react";
import { toastError, toastSuccess } from "../../Toast/Toast";
import { ThreeDotLoader } from "../../Loader/Loader";

import './EmbedOnSite.scss';

interface Props {
    agentId: string;
}

const EmbedOnSite: React.FC<Props> = ({ agentId }) => {
    const [showLoader, setshowLoader] = useState(false);
    const [iFrameValue, setIFrameValue] = useState("");
    const [chatValue, setChatValue] = useState("");

    const copyToClipboard = (value: string) => {
        navigator.clipboard.writeText(value);
        toastSuccess('Copied to clipboard!');
    };

    const updateForm = (target: any) => {
        let name = target.name;
        let val = target.value;
        if (name === "iFrame") {
            setIFrameValue(val);
        } else {
            setChatValue(val)
        }
    };

    return (
        <>
            <div className="container">
                <div className="tab-content">
                    <form>
                        <div className="col-12 col-md-8 formHolder nameHolder mb-4">
                            <label className="form-label">
                                iFrame <span className="danger">*</span>
                            </label>
                            <div className="input-group">
                                <textarea
                                    placeholder="Enter the iframe"
                                    name="iFrame"
                                    className="form-control"
                                    onChange={(event) => updateForm(event.target)}
                                    value={iFrameValue}
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-secondary"
                                        onClick={() => copyToClipboard(iFrameValue)}
                                    >
                                        Copy
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8 formHolder keyHolder mb-4">
                            <label className="form-label">
                                Float Chat <span className="danger">*</span>
                            </label>
                            <div className="input-group">
                                <textarea
                                    placeholder="Enter the float "
                                    name="chat"
                                    className="form-control"
                                    onChange={(event) => updateForm(event.target)}
                                    value={chatValue}
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-secondary"
                                        onClick={() => copyToClipboard(chatValue)}
                                    >
                                        Copy
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
        </>
    );
};

export default EmbedOnSite;
