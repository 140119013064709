import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { LuCheck, LuChevronDown, LuClock3, LuMinus } from "react-icons/lu";

const StatusProps = () => {
    const [showDetailedStatus, setShowDetailedStatus] = useState(false);

    const [expandedIndex, setExpandedIndex] = useState<number | null>(null); // Explicitly specify the type as number or null
    const inlineWidth = {
        width: "25%",
    };

    const toggleDetails = () => {
        setShowDetailedStatus(!showDetailedStatus);
    };

  
    const toggleAccordion = (index: number) => {
        // Explicitly specify the type of 'index' parameter
        setExpandedIndex(index === expandedIndex ? null : index);
    };


    return (
        <div className="popup-container create-template-popup status-popup">
            <div className="shadeClose"></div>
            <div className="popup-inner">
                <div className="inner-design">
                    <div className="popup-header">
                        <button
                            type="button"
                            // onClick={() => {
                            //     closeStatusPopup();
                            // }}
                            className="close"
                        >
                            <FaTimes />
                        </button>
                    </div>

                    <div className="popup-content">
                        <div className="main-status">
                            <div className="top-info mb-5">
                                <h3 className="mb-3">Please wait srouce updating...</h3>
                                <div className="status-bar">
                                    <div className="progress">
                                        <div className="progress-bar" style={inlineWidth}>
                                            25%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-info d-flex gap-3 align-items-center">
                                <div className="left-tags d-flex gap-2">
                                    <div className="info-tag completed">
                                        <span>5</span> completed
                                    </div>
                                    <div className="info-tag queue">
                                        <span>5</span> in queue
                                    </div>
                                    <div className="info-tag inprogrss">
                                        <span>5</span> in progress
                                    </div>
                                    <div className="info-tag failed">
                                        <span>5</span> failed
                                    </div>
                                </div>
                                <div className="ms-auto right-button">
                                    <button
                                        className={
                                            showDetailedStatus
                                                ? "text-button btn btn-secondary small expanded"
                                                : "text-button btn btn-secondary small"
                                        }
                                        onClick={() => {
                                            toggleDetails();
                                        }}
                                    >
                                        Details{" "}
                                        <span>
                                            <LuChevronDown />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {showDetailedStatus && (
                            <div className="detailed-status mt-4">
                                <div className="status-accordion">
                                    <div
                                        className={`accordion-item ${expandedIndex === 1 ? "active" : ""
                                            }`}
                                        onClick={() => toggleAccordion(1)}
                                    >
                                        <h2 className="accordion-header d-flex align-items-center">
                                            Files{" "}
                                            <span className="ms-auto">
                                                <LuChevronDown />
                                            </span>
                                        </h2>
                                        {expandedIndex === 1 && (
                                            <div className="accordion-content">
                                                <ul>
                                                    <li className="d-flex succes-item gap-3 ">
                                                        <span className="icon">
                                                            <LuCheck />
                                                        </span>
                                                        <span>
                                                            <strong>filename.pdf</strong> uploaded
                                                            succefully.
                                                        </span>
                                                    </li>
                                                    <li className="d-flex inprogress-item gap-3">
                                                        <span className="icon">
                                                            <LuClock3 />
                                                        </span>
                                                        <span>
                                                            <strong>filename2.pdf</strong> uploading.
                                                        </span>
                                                    </li>
                                                    <li className="d-flex queue-item gap-3">
                                                        <span className="icon">
                                                            <LuMinus />
                                                        </span>
                                                        <span>
                                                            <strong>filename3.pdf</strong> in queue.
                                                        </span>
                                                    </li>
                                                    <li className="d-flex failed-item gap-3">
                                                        <span className="icon">
                                                            <IoIosClose />
                                                        </span>
                                                        <span>
                                                            <strong>filename4.pdf</strong> failed.
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={`accordion-item ${expandedIndex === 2 ? "active" : ""
                                            }`}
                                        onClick={() => toggleAccordion(2)}
                                    >
                                        <h2 className="accordion-header d-flex align-items-center">
                                            Website{" "}
                                            <span className="ms-auto">
                                                <LuChevronDown />
                                            </span>
                                        </h2>
                                        {expandedIndex === 2 && (
                                            <div className="accordion-content">
                                                <ul>
                                                    <li className="d-flex succes-item gap-3 ">
                                                        <span className="icon">
                                                            <LuCheck />
                                                        </span>
                                                        <span>
                                                            <strong>www.google.com</strong> updated
                                                            succefully.
                                                        </span>
                                                    </li>
                                                    <li className="d-flex inprogress-item gap-3">
                                                        <span className="icon">
                                                            <LuClock3 />
                                                        </span>
                                                        <span>
                                                            <strong>www.google.com</strong> updating.
                                                        </span>
                                                    </li>
                                                    <li className="d-flex queue-item gap-3">
                                                        <span className="icon">
                                                            <LuMinus />
                                                        </span>
                                                        <span>
                                                            <strong>www.google.com</strong> in queue.
                                                        </span>
                                                    </li>
                                                    <li className="d-flex failed-item gap-3">
                                                        <span className="icon">
                                                            <IoIosClose />
                                                        </span>
                                                        <span>
                                                            <strong>www.google.com</strong> failed.
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={`accordion-item ${expandedIndex === 3 ? "active" : ""
                                            }`}
                                        onClick={() => toggleAccordion(3)}
                                    >
                                        <h2 className="accordion-header d-flex align-items-center">
                                            FAQ{" "}
                                            <span className="ms-auto">
                                                <LuChevronDown />
                                            </span>
                                        </h2>
                                        {expandedIndex === 3 && (
                                            <div className="accordion-content">
                                                <ul>
                                                    <li className="d-flex succes-item gap-3 ">
                                                        <span className="icon">
                                                            <LuCheck />
                                                        </span>
                                                        <span>
                                                            <strong>question1</strong> updated
                                                            succefully.
                                                        </span>
                                                    </li>
                                                    <li className="d-flex inprogress-item gap-3">
                                                        <span className="icon">
                                                            <LuClock3 />
                                                        </span>
                                                        <span>
                                                            <strong>question2</strong> updating.
                                                        </span>
                                                    </li>
                                                    <li className="d-flex queue-item gap-3">
                                                        <span className="icon">
                                                            <LuMinus />
                                                        </span>
                                                        <span>
                                                            <strong>question3</strong> in queue.
                                                        </span>
                                                    </li>
                                                    <li className="d-flex failed-item gap-3">
                                                        <span className="icon">
                                                            <IoIosClose />
                                                        </span>
                                                        <span>
                                                            <strong>question4</strong> failed.
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={`accordion-item ${expandedIndex === 4 ? "active" : ""
                                            }`}
                                        onClick={() => toggleAccordion(4)}
                                    >
                                        <h2 className="accordion-header d-flex align-items-center">
                                            Database{" "}
                                            <span className="ms-auto">
                                                <LuChevronDown />
                                            </span>
                                        </h2>
                                        {expandedIndex === 4 && (
                                            <div className="accordion-content">
                                                <ul>
                                                    <li className="d-flex succes-item gap-3 ">
                                                        <span className="icon">
                                                            <LuCheck />
                                                        </span>
                                                        <span>
                                                            <strong>database1</strong> created
                                                            succefully.
                                                        </span>
                                                    </li>
                                                    <li className="d-flex inprogress-item gap-3">
                                                        <span className="icon">
                                                            <LuClock3 />
                                                        </span>
                                                        <span>
                                                            <strong>database2</strong> creating.
                                                        </span>
                                                    </li>
                                                    <li className="d-flex queue-item gap-3">
                                                        <span className="icon">
                                                            <LuMinus />
                                                        </span>
                                                        <span>
                                                            <strong>database3</strong> in queue.
                                                        </span>
                                                    </li>
                                                    <li className="d-flex failed-item gap-3">
                                                        <span className="icon">
                                                            <IoIosClose />
                                                        </span>
                                                        <span>
                                                            <strong>database4</strong> failed.
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatusProps