import React, { useContext, useEffect, useRef, useState } from 'react'
import ICompanyInfo from '../../Services/DashboardServices/IDashboardService';
import DashboardService from '../../Services/DashboardServices/DashboardService';
import { toastError, toastSuccess } from '../Toast/Toast';
import moment from 'moment';
import { useDispatch } from "react-redux";
import { saveCompanyName } from '../../Stores/CompanyName/ComapanyAction';
import { LuPen, LuUpload } from 'react-icons/lu';
import { FaTimes } from 'react-icons/fa';
import Datetime from 'react-datetime';
import IUser from '../../Services/UserManagement/IUserManagementService';
import UserContext from '../../Context/UserContext/UserContext';
import { IPermissions } from "../../Services/RoleManagement/IRoleManagementService";
import PermissionContext from "../../Context/PermissionContext/PermissionContext";

interface Props{
	PermissionList:any
}

const ClientDetails:React.FC<Props>=({PermissionList})=> {

	// const user = useContext<IUser>(UserContext)
	// const permission = useContext<IPermissions>(PermissionContext)
	const [showLoader, setShowLoader] = useState(false);
	const [showForm, setshowForm] = useState(false);
	const [clientLogoUrl, setClientLogoUrl] = useState("")
	const ISCreate = useRef<boolean>(false)
	const dispatch = useDispatch()
	const [clientLogo, setClientLogo] = useState<File>()
	const [createFormData, setCreateFormData] = useState<ICompanyInfo>({
		name: "",
		description: "",
		poc: "",
		package: "",
		email: "",
		phone: "",
		expiredOn: "",
		createdOn: "",
	});

	const createNewForm = () => {
		if (!PermissionList.editClientDetails) return;
		setshowForm(true);
	}

	const closeEosPopup = () => {
		setshowForm(false);
		// getComapanyInfo()

	};

	useEffect(() => {
		getComapanyInfo();
		getClientLogo()
	}, []);

	const getComapanyInfo = async () => {
		// setShowLoader(true)
		DashboardService.GetCompanyInfo().then(res => {
			let data = res.response
			const isEmpty = Object.values(data).every(x => x === null || x === '')
			if (isEmpty) {
				ISCreate.current = isEmpty
			} else {
				setCreateFormData(data)
			}
		}).catch(err => {
			console.log(err)
		}).finally(() => {
			setShowLoader(false)
		})
	};

	const createForm = () => {
		if (!validateData()) return
		setShowLoader(true)
		DashboardService.CreateCompanyInfo(createFormData).then(res => {
			toastSuccess("Company Info Updated Successfully")
			dispatch(saveCompanyName(createFormData.name))
			setshowForm(false);
			getComapanyInfo();
			getClientLogo()
			getClientLogo()
		}).catch(err => {
			toastError()
			console.log(err)
		}).finally(() => {
			setShowLoader(false)
		})
	};

	const getClientLogo = () => {
		setShowLoader(true)
		DashboardService.GetCompanyLogo().then(res => {
			setClientLogoUrl(res.response)
		}).catch(err => {
			console.log(err)
		}).finally(() => {
			setShowLoader(false)
		})
	}

	const validateData = () => {
		let flag = true
		// if (!createFormData.Image && !createFormData.ImageResponse) {
		// 	toastError("Please Upload Company Logo")
		// 	flag = false
		// }
		if (!createFormData.name) {
			toastError("Please Enter Company Name")
			flag = false
		}
		if (!createFormData.description) {
			toastError("Please Enter Description")
			flag = false
		}
		const emailRegex = /^\S+@\S+\.\S+$/;
		if (!emailRegex.test(createFormData.email.trim())) {
			toastError("Please Enter Valid Email");
			flag = false;
		}

		if (!createFormData.poc) {
			toastError("Please Enter POC")
			flag = false
		}

		if (!createFormData.expiredOn) {
			toastError("Please Enter ExpiryDate")
			flag = false
		}
		if (!createFormData.createdOn) {
			toastError("Please Enter CreatedDate")
			flag = false
		}
		if (createFormData.phone.length > 20) {
			toastError("Enter correct phone number")
			flag = false
		}

		const expDate = moment(createFormData.expiredOn, 'DD-MM-YYYY').toDate();
		const creDate = moment(createFormData.createdOn, 'DD-MM-YYYY').toDate();
		if (expDate < creDate) {
			toastError("Future dates not allowed");
			flag = false;
		}

		return flag
	}

	const updateForm = (target: any) => {
		let name = target.name;
		let val = target.value;
		if (name === "poc") {
			val = val.replace(/[^A-Za-z" "]/ig, '')
		}
		if (name === "phone") {
			val = val.replace(/\D/g, '')
		}
		if (name === "Image") {
			const selectedImage = target.files[0];
			const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
			if (selectedImage && allowedImageTypes.includes(selectedImage.type)) {
				setClientLogo(selectedImage)
				setClientLogoUrl('')
				setCreateFormData((preVal) => ({
					...preVal,
					logo: selectedImage,
				}));
			} else {
				toastError("Please upload files type jpeg/ico/png")
			}
		} else {
			setCreateFormData((preVal) => ({
				...preVal,
				[name]: val,
			}));
		};
	}

	function getTotalLength(inputVal: any) {
		if (inputVal === null) {
			return 0;
		}
		return inputVal.length + (inputVal.match(/\n/g) || []).length;
	}

	// form
	const updateDateForm = (value: any, name: any) => {
		let val = value;
		if (moment.isMoment(val)) {
			val = val.format('DD-MM-YYYY h.mm.ss A');
		}
		setCreateFormData({
			...createFormData,
			[name]: val,
		});
	};

	return (
		<div className='clientDetails'>
			{createFormData && (
				<div className='clientDetailsInner'>
					<button className="plain-btn edit-option" onClick={() => { createNewForm() }}><LuPen /></button>
					<div className="row mb-4">
						<div className="col-4 col-md-3 logo-holder">
							<div className="logo-pic">
								{clientLogoUrl ? (
									<img src={`data:${clientLogoUrl};base64,${clientLogoUrl}`} alt="" />
								) : (
									<img src={require("../../common/images/placeholder.png")} />
								)}
							</div>
						</div>
						<div className="col-12 col-md-9 company-details">
							<h3 className="mb-2">{createFormData.name}</h3>
							<p className="0">{createFormData.description}</p>
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-md-6 mb-3 subs-info">
							<p>Created on</p>
							<h5 className="secondary-font-medium">{createFormData.createdOn}</h5>
						</div>
						<div className="col-12 col-md-6 mb-3 subs-info">
							<p>POC</p>
							<h5 className="secondary-font-medium">{createFormData.poc}</h5>
						</div>
						<div className="col-12 col-md-6 mb-3 subs-info">
							<p>Package</p>
							<h5 className="secondary-font-medium">{createFormData.package}</h5>
						</div>
						<div className="col-12 col-md-6 mb-3 subs-info">
							<p>Email</p>
							<h5 className="secondary-font-medium">{createFormData.email}</h5>
						</div>
						<div className="col-12 col-md-6 subs-info">
							<p>Expiry Date</p>
							<h5 className="secondary-font-medium">{createFormData.expiredOn}</h5>
						</div>
						<div className="col-12 col-md-6 subs-info">
							<p>Phone</p>
							<h5 className="secondary-font-medium">{createFormData.phone}</h5>
						</div>
					</div>
				</div>
			)}
			{showForm && (
				<div className="popup-container medium-template-popup dashboard-popup">
					<div className="shadeClose" onClick={() => { closeEosPopup(); }}></div>
					<div className="popup-inner">
						<div className="inner-design">
							<div className="popup-header">
								<h3>Company Details</h3>
								<button type="button" onClick={() => { closeEosPopup(); }} className="close"><FaTimes /></button>
							</div>

							<div className="popup-content">
								<div className="d-flex gap-4">
									<div className="upload-button-container mb-4">
										<div className="uploadButton">
											{clientLogoUrl && (
												<img src={`data:${clientLogoUrl};base64,${clientLogoUrl}`} alt="" />
											)}


											{/* before upload */}
											{!clientLogoUrl && !clientLogo && (
												<div className="uploadTrigger">
													<div className="icon"><LuUpload /></div>
													<label className="form-label">
														Upload Company Logo</label>
												</div>
											)}

											{/* preview */}
											{clientLogo && (
												<div className="dpDisplay">
													<img src={URL.createObjectURL(clientLogo)} alt="Preview" />
												</div>
											)}
											{/* upload button */}
											<input
												type="file"
												name="Image"
												onChange={(event) => updateForm(event.target)}
												className="form-control"
												title=""
												accept=".jpeg, .jpg, .png,.ico"
											// title={clientLogo?.length && clientLogo[0].name}
											/>
										</div>
									</div>
									<div className="w-100">
										<div className="col-12 col-md-12 formHolder nameHolder mb-4">
											{/* <label className="form-label">Company Name <span className="danger">*</span></label> */}
											<input
												type="text"
												placeholder="Enter the company name *"
												name="name" className="form-control" onChange={(event) => updateForm(event.target)}
												value={createFormData.name}
											/>
										</div>
										<div className="col-12 col-md-12 formHolder descHolder mb-4">
											{/* <label className="form-label">Description <span className="danger">*</span></label> */}
											<textarea className="form-control"
												placeholder="Enter the Description *"
												name="description" onChange={(event) => updateForm(event.target)}
												value={createFormData.description}
												maxLength={200}
											/>
											{getTotalLength(createFormData.description) > 199 && (<div className="danger">Description cannot exceed 200 characters.</div>)}
										</div>
									</div>

								</div>

								<div className="row">
									<div className="col-12 col-md-6 formHolder keyHolder mb-4">
										<label className="form-label">Email <span className="danger">*</span></label>
										<input
											type="text"
											placeholder="Email"
											name="email" className="form-control" onChange={(event) => updateForm(event.target)}
											value={createFormData.email}
										/>
									</div>


									<div className="col-12 col-md-6 formHolder keyHolder mb-4">
										<label className="form-label">POC <span className="danger">*</span></label>
										<input
											type="text"
											placeholder="POC"
											name="poc" className="form-control" onChange={(event) => updateForm(event.target)}
											value={createFormData.poc}
										/>
									</div>

									<div className="col-12 col-md-6 formHolder keyHolder mb-4">
										<label className="form-label">Created On <span className="danger">*</span></label>
										<Datetime
											input={true}
											timeFormat={false}
											dateFormat="DD-MM-YYYY"
											onChange={(date) => updateDateForm(date, "createdOn")}
											inputProps={{ name: "createdOn", className: "form-control", readOnly: true }}
											isValidDate={(current) => current.isSameOrAfter(moment(), 'day')}
											value={createFormData.createdOn}
										/>
									</div>

									<div className="col-12 col-md-6 formHolder keyHolder mb-4">
										<label className="form-label">Expiry Date <span className="danger">*</span></label>
										<Datetime
											input={true}
											timeFormat={false}
											dateFormat="DD-MM-YYYY"
											onChange={(date) => updateDateForm(date, "expiredOn")}
											inputProps={{ name: "expiredOn", className: "form-control", readOnly: true }}
											isValidDate={(current) => current.isAfter(moment(), 'day')}
											value={createFormData.expiredOn}
										/>
									</div>


									<div className="col-12 col-md-6 formHolder keyHolder mb-4">
										<label className="form-label">Package</label>
										<input
											type="text"
											placeholder="Package"
											name="package" className="form-control" onChange={(event) => updateForm(event.target)}
											value={createFormData.package}
										/>
									</div>


									<div className="col-12 col-md-6 formHolder keyHolder mb-4">
										<label className="form-label">Phone </label>
										<input
											type="text"
											placeholder="Phone"
											name="phone" className="form-control" onChange={(event) => updateForm(event.target)}
											value={createFormData.phone}
											maxLength={20}
										/>
									</div>



									<div className="mt-4 btnHolder d-flex gap-3">
										<button type="button" className="btn btn-secondary " onClick={() => closeEosPopup()}>Cancel</button>
										{!ISCreate.current ?
											<button className="btn btn-primary" type="button" onClick={() => createForm()}>Update</button>
											:
											<button className="btn btn-primary" type="button" onClick={() => createForm()}>Create</button>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default React.memo(ClientDetails);