import IAgentService from "../Services/AgentServices/IAgentService"
import IRoleManagement, { IPermissions } from "../Services/RoleManagement/IRoleManagementService"
import ITemplateService, { IDataBaseConnection } from "../Services/TemplateService/ITemplateService"
import ITHEME from "../Services/ThemeServices/IThemeServices"

export const MSAL_CLIENT_ID: string = process.env.REACT_APP_MSAL_CLIENT_ID ?? ''
export const MSAL_AUTHORITY = process.env.REACT_APP_MSAL_AUTHORITY
export const MSAL_REDIRECT_URL = process.env.REACT_APP_MSAL_REDIRECT_URL
export const DEFAULT_MSG_ASSISTANT = "Hi, I am your chat agent. How can I assist you today?"

export const THEME_INITIAL_OBJ: ITHEME[] = [
    {
        "chatInTextColor": "#00338d",
        "chatOutTextColor": "#666666",
        "chatInBG": "#ffffff",
        "chatOutBG": "#ffffff",
        "background": "#F5DFF3",
        "themeName": "Default",
        "isSelected": true,
        "isUpdated": false
    },
    {
        "chatInTextColor": "#0c614f",
        "chatOutTextColor": "#666666",
        "chatInBG": "#fbf3f6",
        "chatOutBG": "#1b0fd8",
        "background": "#c9bcbc",
        "themeName": "Default1",
        "isSelected": false,
        "isUpdated": false
    },
    {
        "chatInTextColor": "#070606",
        "chatOutTextColor": "#070606",
        "chatInBG": "#fff",
        "chatOutBG": "#fff",
        "background": "#DCCFCF",
        "themeName": "Default2",
        "isSelected": false,
        "isUpdated": false
    }
]

export const ASSISTANT_INITIAL_OBJ: IAgentService = {
    name: "",
    nickName: "",
    instruction: "",
    description: "",
    gptModel: "",
    vendorKey: "",
    templateId: '',
    templateName: '',
    icon: null,
    uploadIcon: null as File | null,
    group: "",
    files: [] as any[],
    website: [],
    qAndAList: [],
    prompts: [],
    databaseConnections: [],
    chatOption: {},
    apiKey: [],
    github: [],
    iFrameFormShow: true,
    uploadFileIframe: true,
    theme: THEME_INITIAL_OBJ
}
export const TEMPLATE_INITIAL_OBJ: ITemplateService = {
    name: "",
    group: "",
    instruction: "",
    gptModel: "",
    databaseConnections: [],
    prompts: [],
    files: [],
    website: [],
    qAndAList: [],
    github: []
}

export const PERMISSIONS_INITIAL_OBJ: IPermissions = {
    createApiKey: false,
    viewApiKeyList: false,
    deleteApiKey: false,
    createAgent: false,
    viewAllAgents: false,
    deleteAgent: false,
    viewAgents: false,
    modifyAllAgents: false,
    uploadAgentFiles: false,
    deleteAgentFiles: false,
    createGroup: false,
    viewGroup: false,
    deleteGroup: false,
    createPrompt: false,
    viewPrompt: false,
    deletePrompt: false,
    createTemplate: false,
    viewTemplate: false,
    deleteTemplate: false,
    createRole: false,
    viewRole: false,
    deleteRole: false,
    updateRole: false,
    viewUser: false,
    updateUser: false,
    viewNewUser: false,
    viewChatLog: false,
    viewDashboard: false,
    viewAuditLogs: false,
    editClientDetails: false,
    viewClientDetails: false,
    deleteChatHistory:false
}
export const ROLE_INITIAL_OBJ: IRoleManagement = {
    name: "",
    createdAt: "",
    updatedAt: "",
    permissions: PERMISSIONS_INITIAL_OBJ
}

export const DATABASE_CONNECTION_INITIAL_OBJ: IDataBaseConnection = {
    connectionName: "",
    databaseType: "",
    connectionString: "",
    server: "",
    databaseName: "",
    user: "",
    password: "",
    port: "",
}

