import IResponse from "../IResponse";
import { CREATE_USER, INTERNAL_LOGIN, DELETE_USER, LIST_USERS, UPDATE_USER, NEW_USERS, RESET_PASSWORD, FORGOT_PASSWORD, USER_SELF_INFO } from "../EndPoint";
import IUser from "./IUserManagementService";
import { toastError } from "../../Components/Toast/Toast";
import instance, { instanceNoToken } from "../AxiosInstance";


export default class UserManagementService {
    public static CreateUser(userObj: IUser): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            const URL = CREATE_USER
            fetch(URL, {
                method: 'POST',
                body: JSON.stringify(userObj),
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(async res => {
                if (res.ok) {
                    if (res.ok) {
                        await res
                        resolve(res.json())
                        return res.status;
                    }
                }

                if (res.status === 400) {
                    const data = await res.json()
                    toastError(data["message"]);
                }
            })

        })
    }
    public static LoginUserBkUp(userObj: IUser): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            const URL = INTERNAL_LOGIN
            fetch(URL, {
                method: 'POST',
                body: JSON.stringify(userObj),
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(async res => {
                if (res.status === 200) {
                    await res
                    resolve(res.json());
                    return res;
                }
                if (res.status === 401) {
                    toastError(res);
                }
                else if (res.status === 400) {
                    toastError("User not approved. Please contact Admin");
                }
            })

        })
    }
    public static LoginUser(userObj: IUser): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instanceNoToken.post(INTERNAL_LOGIN, JSON.stringify(userObj)).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err)
            })

        })
    }

    public static GetAllUsersList(active: string, inactive: string, page: number, rowPerPage: number): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(`${LIST_USERS}?filter=${active}&filter=${inactive}&page=${page}&limit=${rowPerPage}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                console.log("GET all users list", err)
                reject(err)
            })
        })
    }

    public static GetAllNewUsersList(newUser: string, page: number, rowPerPage: number,): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(`${NEW_USERS}?filter=${newUser}&page=${page}&limit=${rowPerPage}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                console.log("GET all users list", err)
                reject(err)
            })
        })
    }

    public static UpdateUser(userObj: IUser): Promise<IResponse> {
        let payload: IUser = {
            email: userObj.email,
            role: userObj.role,
            status: userObj.status,
            position: userObj.position
        }
        return new Promise((resolve, reject) => {
            instance.put(UPDATE_USER, JSON.stringify(payload)).then(res => {
                resolve(res.data)

            }).catch(err => {
                console.log("Update user", err)
                reject(err)
            })
        })
    }

    public static DeleteUser(userObj: IUser): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.delete(`${DELETE_USER}?email=${userObj.email}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                console.log("Update user", err)
                reject(err)
            })
        })
    }




    public static RequestUserPasswordReset(sendMailForm: any): Promise<IResponse> {
        console.log(sendMailForm)
        let payload = {
            email: sendMailForm.userName,
            host: sendMailForm.host,
        }
        return new Promise((resolve, reject) => {
            instance.post(FORGOT_PASSWORD, JSON.stringify(payload)).then(res => {
                resolve(res.data)
            }).catch(err => {
                console.log("forgot password", err)
                reject(err.response.data)
            })
        })
    }


    public static UserResetNewPassword(createUserForm: any): Promise<IResponse> {
        let payload = {
            new_password: createUserForm.Password,
            confirm_password: createUserForm.Password
        }
        return new Promise((resolve, reject) => {
            instance.post(`${RESET_PASSWORD}/${createUserForm.hashCode}`, JSON.stringify(payload)).then(res => {
                resolve(res.data)
            }).catch(err => {
                console.log("reset password", err)
                reject(err)
            })
        })
    }


    public static CheckLinkExpiry(token: any): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(`${RESET_PASSWORD}/${token}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                console.log("check link", err)
                reject(err)
            })
        })
    }
    public static UserSelfInfo(): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(`${USER_SELF_INFO}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                console.log("check link", err)
                reject(err)
            })
        })
    }

}
