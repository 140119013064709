import {
    CREATE_MODELS,
    DELETE_MODELS,
    LIST_MODELS,
    UPDATE_MODELS
} from "../EndPoint"
import TokenService from "../TokenService/TokenService";
import IResponse from "../IResponse";
import IModelService from "./IModelService";
import instance from "../AxiosInstance";


export default class ModelsServices {

    //// Get all vendor apis
    public static async GetAllModels(): Promise<IResponse> {
        return new Promise(async (resolve, reject) => {
            await instance.get(LIST_MODELS).then(res => {  // need to change
                if (res.status == 200) {
                    resolve(res.data)
                }
            }).catch(err => {
                console.log(err)
            })
        })
    }



    // Create Vendor  API
    public static async CreateModel(vendorObj: IModelService): Promise<IResponse> {
        return new Promise(async (resolve, reject) => {
            await instance.post(CREATE_MODELS, JSON.stringify(vendorObj)).then(res => {
                resolve(res.data)
            }).catch(error => {
                reject(error)
            })

        })
    }
    public static async UpdateModel(vendorObj: IModelService): Promise<IResponse> {
        return new Promise(async (resolve, reject) => {
            await instance.put(UPDATE_MODELS, JSON.stringify(vendorObj)).then(res => {
                resolve(res.data)
            }).catch(error => {
                reject(error)
            })

        })
    }

    // Delete Prompt API
    public static async DeleteModel(modelName: any): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.delete(`${DELETE_MODELS}?name=${modelName}`).then(res => {
                if (res.status == 200) {
                    resolve(res.data)
                }
            }).catch(error => {
                console.log("DeletePrompt", error)
                reject(error)
            })
        })

    }

}