import React, { ReactNode, SetStateAction } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { LuEye } from "react-icons/lu";
import { getFileSize, getTrainingStatus } from "../Utils/Utils";

interface AccordionItemProps {
    Data: any
    openTab: string | null;
    setOpenTab: React.Dispatch<SetStateAction<string | null>>
}

const AccordionGHItem: React.FC<AccordionItemProps> = ({ Data, openTab, setOpenTab, }) => {

    const columns: TableColumn<any>[] = [
        {
            name: "Repo Name",
            selector: (rows: any) => rows.repoName,
            width: "300px",
        },
        {
            name: "Repo Owner",
            selector: (rows: any) => rows.repoOwner,
            width: "300px",
        },
        {
            name: "Branch",
            selector: (rows: any) => rows.branch,
            width: "300px",
        },
        
        
    ];
    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={() => setOpenTab((preVal) => preVal === "GH" ? null : "GH")}>
                {`Github (${Data.length || 0})`}
                <span className={`arrow ${openTab === "GH" ? "rotate" : ""}`}>
                    <svg width="14.828" height="8.414" viewBox="0 0 14.828 8.414">
                        <path
                            id="Path_491"
                            data-name="Path 491"
                            d="M6,9l6,6,6-6"
                            transform="translate(-4.586 -7.586)"
                            fill="none"
                            stroke="#615070"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                        />
                    </svg>
                </span>
            </div>
            <div className={`accordion-content ${openTab === "GH" ? "show" : ""}`}>
                <div>
                    <DataTable
                        columns={columns}
                        data={Data || []}
                        fixedHeader
                    // pagination
                    // selectableRows
                    />
                </div>
            </div>
        </div>
    )
};
export default AccordionGHItem