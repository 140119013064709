import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.scss";
import { IoIosArrowRoundForward } from "react-icons/io";
// import { Doughnut } from 'react-chartjs-2';
// import 'chart.js/auto';
import DashboardService from "../../Services/DashboardServices/DashboardService";
import { ThreeDotLoader } from "../Loader/Loader";
import { toastError, toastSuccess } from "../Toast/Toast";
import { FaTimes } from "react-icons/fa";
import { LuPen, LuUpload } from "react-icons/lu";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { IChartData, IRCActivities } from "../../Services/DashboardServices/IDashboardService";
import UserContext from "../../Context/UserContext/UserContext";
import IUser from "../../Services/UserManagement/IUserManagementService";
import ICompanyInfo from "../../Services/DashboardServices/IDashboardService";
import { log } from "console";
import { name } from "@azure/msal-browser/dist/packageMetadata";
import { IPermissions } from "../../Services/RoleManagement/IRoleManagementService";
import PermissionContext from "../../Context/PermissionContext/PermissionContext";
import { useDispatch } from "react-redux";
import { saveCompanyName } from "../../Stores/CompanyName/ComapanyAction";
import { formateDate } from "../Utils/Utils";


const Dashboard = ({ Props }: any) => {
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
	const userStatus = ['Active', 'Inactive', 'New', 'Rejected']
	const dummyArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

	const user = useContext<IUser>(UserContext)
	const permission = useContext<IPermissions>(PermissionContext)
	const dispatch = useDispatch()

	const monthsSubStr = months.map(month => month.substring(0, 3))
	const [showLoader, setShowLoader] = useState(false);
	const [rcActivities, setRCActivities] = useState<IRCActivities[]>([])
	const [showForm, setshowForm] = useState(false);
	const navigate = useNavigate();
	const [clientLogo, setClientLogo] = useState<File>()
	const [clientLogoUrl, setClientLogoUrl] = useState("")
	const ISCreate = useRef<boolean>(false)

	const [dashboardData, setDashboardData] = useState<IChartData>({
		doughnutXDAta: userStatus,
		doughnutYDAta: [],
		graphXDAta: monthsSubStr,
		graphYDAta: dummyArr,

		templates: 0,
		groups: 0,
		assistant: 0,
		prompts: 0,
		users: 0,
		usage: []
	});


	const [createFormData, setCreateFormData] = useState<ICompanyInfo>({
		name: "",
		description: "",
		poc: "",
		package: "",
		email: "",
		phone: "",
		expiredOn: "",
		createdOn: "",
	});


	useEffect(() => {
		if (!permission.viewDashboard) return;
		getAllData();
		getRecentActivities()
	}, []);

	function extractChartData(data: any) {
		let monUsage = data.usage ? data.usage : {}
		let userDetails = data.userDetails ? data.userDetails : {}
		let graphYValues: number[] = months.map((month, index) => monUsage[month])
		let DonutYValues: number[] = userStatus.map((status: string, index) => userDetails[status])
		setDashboardData((preVal) => {
			return {
				...preVal,
				graphYDAta: graphYValues,
				doughnutYDAta: DonutYValues
			}
		})
	}
	const getAllData = () => {
		setShowLoader(true)
		DashboardService.GetChartData().then(res => {
			let data = res.response
			setDashboardData((preVal) => (permission.viewDashboard ? { ...preVal, ...data } : { ...preVal }))
			extractChartData(data)
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			setShowLoader(false)
		})
	};


	const getRecentActivities = () => {
		setShowLoader(true)
		DashboardService.GetRecentActivities().then(res => {
			setRCActivities(res.response)
		}).catch(err => {
			console.log(err)
		}).finally(() => {
			setShowLoader(false)
		})
	}

	const view = (page: string,) => {
		navigate(page);
	}


	// chart
	const donutChartOptions = (): ApexOptions => ({
		chart: {
			type: 'donut' as const,
		},
		labels: dashboardData.doughnutXDAta,
		dataLabels: {
			enabled: false
		},
		series: dashboardData.doughnutYDAta,
		// series: dashboardData.doughnutYDAta,
		colors: ['#74F89D', '#F19E38', '#E87572', '#48A3F6'],
		fill: {
			colors: ['#74F89D', '#F19E38', '#E87572', '#48A3F6']
		},
		legend: {
			position: 'right',
			offsetY: 0,
			height: 230,
		}
	});



	// // apex.js
	const options: ApexOptions = {
		chart: {
			type: 'area',
			stacked: false,
			toolbar: {
				show: false
			}
		},
		dataLabels: {
			enabled: false
		},
		markers: {
			size: 0,
		},
		grid: {
			show: false
		},

		xaxis: {
			categories: dashboardData.graphXDAta
		},
		series: [
			{
				name: "Usage",
				data: dashboardData.graphYDAta
			}],
		yaxis: {
			labels: {
				show: false
			}
		},
		colors: ['#74F89D']
	};

	// const series = [{
	// 	name: 'Usage',
	// 	data: dashboardData.graphYDAta,
	// }];

	// const series = [{
	// 	name: 'Usage',
	// 	data: Array.from([dashboardData.graphYDAta])
	// }];

	// function getMonthName(monthIndex: any) {
	// 	const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	// 	return monthNames[monthIndex - 1] || '';
	// }

	function capitalizeFirstltr(name: string) {
		const words = name.split(" ");

		return words.map((word) => {
			return word[0].toUpperCase() + word.substring(1);
		}).join(" ");
	}


	return (
		<>
			<div className="contentWrapper dashboard-container">
				<h1 className="secondary-color big-font"><span className="font-black">Welcome </span>{user.firstName && user.firstName.length > 0 && capitalizeFirstltr(user.firstName)}!</h1>
				<p className="mb-4"><span>Good Day</span></p>
				<div className="row">
					<div className="col-12 col-lg-7 col-md-12 left-deck">
						<div className="row">
							<div className="col-12 col-md-6 pie-holder">
								<h2 className="mb-3"><span>Users</span> Overview</h2>
								<div className="graph-holder">
									{/* <img src={require("../../common/images/dummy-graph2.png")} /> */}
									{/* <Doughnut data={doughtnutChart} /> */}
									<div className="chart-inner">
										<Chart options={donutChartOptions()} series={donutChartOptions().series} type={"donut" as any} />

									</div>
									{/* <h5>{dashboardData.usersCount} <span>Users</span> </h5> */}




								</div>
							</div>
							<div className="col-12  col-md-6 count-holder">
								<div className="row">
									<div className="col-6 col-md-6 box-holder pt-2 pb-2 pe-2">
										<a className="box" onClick={() => view("/Groups")}>
											<h1 className="big-font">{dashboardData.groups}</h1>
											<p>Groups</p>
										</a>
									</div>
									<div className="col-6 col-md-6 box-holder pt-2 pb-2 ps-2">
										<a className="box" onClick={() => view("/Apps")}>
											<h1 className="big-font">{dashboardData.assistant}</h1>
											<p>Agents</p>
										</a>
									</div>
									<div className="col-6 col-md-6 box-holder pt-2 pb-2 pe-2">
										<a className="box" onClick={() => view("/Templates")}>
											<h1 className="big-font">{dashboardData.templates}</h1>

											<p>Templates</p>
										</a>
									</div>
									<div className="col-6 col-md-6 box-holder pt-2 pb-2 ps-2">
										<a className="box" onClick={() => view('/users')}>
											<h1 className="big-font">{dashboardData.users}</h1>

											<p>Users</p>
										</a>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-12 area-chart-holder mt-3">
								<h2 className="mb-3"><span>Usage</span> Overview</h2>
								<div className="graph-holder">
									{/* <img src={require("../../common/images/dummygraph.png")} /> */}
									{/* <Line data={data} options={options} /> */}
									<Chart options={options} series={options.series} type="area" height={250} />
								</div>

							</div>
						</div>
					</div>
					<div className="col-12 col-lg-5 col-md-12 ps-lg-5 ps-sm-0 right-deck">
						<div className="inner-deck ps-lg-4 ps-sm-2">
							<h2 className="mb-3"><span>Recent</span> Activities</h2>
							<div className="row activity">
								{rcActivities?.map((activities: IRCActivities) => (

									<div className="col-12 col-md-6 repeat">
										<div className="header-sec">
											<h3>{activities.object} <span>{activities.action}</span> </h3>
											<span className="date">{activities.time && formateDate(activities.time)}</span>
										</div>
										<p>{activities.name}</p>
										<div className="d-flex align-items-center">
											<div className="user-info d-flex gap-1 align-items-center">
												<span>{activities.user}</span>
											</div>
											{activities.object == "Agent" && <button className="ms-auto plain-btn" onClick={() => { view("/Apps"); }}>view <IoIosArrowRoundForward /></button>}
											{activities.object == "Group" && <button className="ms-auto plain-btn" onClick={() => { view("/Groups"); }}>view <IoIosArrowRoundForward /></button>}
											{activities.object == "Template" && <button className="ms-auto plain-btn" onClick={() => { view("/Templates"); }}>view <IoIosArrowRoundForward /></button>}
											{activities.object == "User" && <button className="ms-auto plain-btn" onClick={() => { view("/Users"); }}>view <IoIosArrowRoundForward /></button>}
											{activities.object == "Role" && <button className="ms-auto plain-btn" onClick={() => { view("/Roles"); }}>view <IoIosArrowRoundForward /></button>}
										</div>
									</div>
								))}
							</div>
							{rcActivities.length == 0 &&
								<p>No recent activities</p>}



						</div>
					</div>
				</div>
			</div>


			<ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
		</>
	);
};


export default Dashboard;




/// chart.js  doughtnut
// const doughtnutChart = {
// 	// labels: ['Active', 'Inactive', 'Rejected', 'New'],
// 	labels: dashboardData.doughnutXDAta,
// 	datasets: [
// 		{
// 			data: dashboardData.doughnutYDAta,
// backgroundColor: ['#6FC2C5', '#F19E38', '#E87572', '#615070'],
// hoverBackgroundColor: ['#6FC2C5', '#F19E38', '#E87572', '#615070'],
// 		},
// 	],
// };


// // chart.js
// const options = {
// 	scales: {
// 		x: {
// 			grid: {
// 				display: false
// 			}
// 		},
// 		y: {
// 			display: false
// 		}
// 	}
// };

// const data: ChartData<"line", number[], string> = {
// 	// labels: dashboardData.graphXDAta,
// 	labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
// 	datasets: [
// 		{
// 			label: 'My Dataset',
// 			fill: {
// 				target: 'origin',
// 				above: '#FAF3FA', // Color for area above the line
// 			},
// 			borderColor: '#CFB7CD', // Line color
// 			data: [5, 10, 5, 20, 15, 5, 15], // Your data points
// 			// data: dashboardData.graphYDAta,
// 			cubicInterpolationMode: 'monotone' // Smooth interpolation
// 		},
// 	],
// };
