import { DELETE_TEMPLATE_DATA, SAVE_TEMPLATE_DATA } from "./ActionType"

export const saveTemplateData = (data: string) => {
    return {
        type: SAVE_TEMPLATE_DATA,
        data: data
    }
}
export const deleteTemplateData = () => {
    return {
        type: DELETE_TEMPLATE_DATA,
        data: ""
    }
}