import TokenService from "../TokenService/TokenService";

import {
    CREATE_PROMPTS, LIST_PROMPTS, DELETE_PROMPTS, UPDATE_PROMPTS
} from "../EndPoint"
import IResponse from "../IResponse";
import IPromptService from "./IPromptService";
import instance from "../AxiosInstance";


export default class PromptService {

    public static GetAllPrompts(pageNumber?: number, pageSize?: number): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(pageNumber && pageSize ? `${LIST_PROMPTS}?limit=${pageSize}&page=${pageNumber}` : LIST_PROMPTS).then(res => {
                resolve(res.data)
            }).catch((error) => {
                console.log("GetAllPrompts", error)
                reject(error)
            })
        })
    }



    // Create Prompt API
    public static CreatePrompt(promptObj: IPromptService): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.post(CREATE_PROMPTS, JSON.stringify(promptObj)).then(res => {
                resolve(res.data)
            }).catch((error) => {
                console.log("CreatePrompt", error)
                reject(error)
            })
        })
    }

    // Create Prompt API
    public static UpdatePrompt(promptObj: IPromptService): Promise<IResponse> {
        let payload: IPromptService = { description: promptObj.description, instruction: promptObj.instruction }
        return new Promise((resolve, reject) => {
            instance.put(`${UPDATE_PROMPTS}?name=${promptObj.name}`, JSON.stringify(payload)).then(res => {
                resolve(res.data)
            }).catch((error) => {
                console.log("UpdatePrompt", error)
                reject(error)
            })
        })
    }


    // Delete Prompt API
    public static async DeletePrompt(promptName: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.delete(`${DELETE_PROMPTS}?name=${promptName}`).then(res => {
                if (res.status == 200) {
                    resolve(res.data)
                }
            }).catch(error => {
                console.log("DeletePrompt", error)
                reject(error)
            })
        })

    }





}