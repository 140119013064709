import React from "react";

const Youtube = () => {
    return (<> <div className="commonDiv">
        <div className="titleBar d-flex align-items-center">
            <h3> Youtube</h3>
        </div>
        <div className="content">
            <h3>Coming soon!......</h3>
        </div>
    </div></>)
}

export default Youtube