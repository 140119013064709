import TokenService from "../TokenService/TokenService";
import IResponse from "../IResponse";
import {
    API_KEY
} from "../EndPoint"
import IApiKey from "./IApiKey";
import instance from "../AxiosInstance";


export default class ApiKeyService {


    public static CreateApiKey(apiKeyObj: IApiKey): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.post(API_KEY, JSON.stringify(apiKeyObj)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })

        })
    }

    public static UpdateApiKey(apiKeyObj: IApiKey): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.put(API_KEY, JSON.stringify(apiKeyObj)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })

        })
    }

    public static DeleteApiKey(apiKeyObj: IApiKey): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.delete(`${API_KEY}?assistantId=${apiKeyObj.assistant}&&name=${apiKeyObj.name}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static async GetAllApiKeysByAssistantId(assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(`${API_KEY}?assistantId=${assistantId}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    }

}