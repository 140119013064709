import IResponse from "../IResponse";
import {
    SENDQUESTION, GET_ASSISTANT_BY_ID,
    ASSISTANT_FILE_UPLOAD,
    CHAT_LOG,
    CONTACT_DETAILS
} from "../EndPoint"
import IChatBot from "../ChatBotServices/IChatBot";
import axios from "axios";
import { createChunkFileForms } from "../../Components/Utils/Utils";



export default class BotService {

    private instance
    private multiPartInstance
    private data = ""
    private apiKey = ""
    public constructor(apiKey: string) {
        this.instance = axios.create({
            timeout: 30000
        })
        this.multiPartInstance = axios.create({
            timeout: 30000
        })
        this.multiPartInstance.interceptors.request.use(
            config => {
                config.headers['Content-Type'] = 'multipart/form-data'
                config.headers['apikey'] = apiKey
                return config
            }
        )

        this.instance.interceptors.request.use(
            config => {
                config.headers["Content-Type"] = 'application/json'
                config.headers['apikey'] = apiKey
                return config
            }
        )
        this.apiKey = apiKey
    }






    public async AssistantFileUpload(assistantId: string, sessionid: string, files: File[]): Promise<IResponse | string> {
        return new Promise(async (resolve, reject) => {
            const fileForms = createChunkFileForms(assistantId, files)
            const promises: any = []
            fileForms.map(formData => {
                promises.push(
                    this.multiPartInstance.post(`${ASSISTANT_FILE_UPLOAD}/${assistantId}/session/${sessionid}/session-upload-chunk`, formData).then(res => {
                    })
                )
            })
            await Promise.allSettled(promises).then((res: any) => {
                let rejected = res.filter((item: any) => item.status === "rejected")
                if (rejected.length > 0) {
                    reject(rejected[0]["reason"] ? rejected[0]["reason"] : '')
                } else (resolve(""))
            }).catch(err => {
                reject(err)
            })
        })
    }
    public async AssistantFileUploadbackup(assistantId: string, sessionid: string, data: FormData): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            this.multiPartInstance.post(`${ASSISTANT_FILE_UPLOAD}/${assistantId}/session/${sessionid}/uploads`, data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public async SendQuestion(qstnObj: IChatBot): Promise<any> {
        return new Promise((resolve, reject) => {
            let payload: IChatBot = {
                assistantId: qstnObj.assistantId,
                query: qstnObj.query,
                sessionId: qstnObj.sessionId,
                stream: qstnObj.stream,
                prompt: qstnObj.prompt

            }
            if (qstnObj.chatOption) {
                payload.chatOption = qstnObj.chatOption
            }
            fetch(SENDQUESTION, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    apiKey: this.apiKey,
                },
                body: JSON.stringify(payload)
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public async GetAgentById(assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            this.instance.get(`${GET_ASSISTANT_BY_ID}/${assistantId}/`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    }
    public async GetAllChatSession(assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            this.instance.get(`${CHAT_LOG}/${assistantId}/session`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }


    public async CreateUserContactDetails(data: object): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            this.instance.post(`${CONTACT_DETAILS}`, JSON.stringify(data)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }






}


