import axios from "axios";
import TokenService from "./TokenService/TokenService";
import { config } from "process";

const timeout = 30000
const baseurl = ""
const defaultConfig = {
    timeout: timeout,
}
const instance = axios.create({
    ...defaultConfig
})

export const multiPartInstance = axios.create({
    ...defaultConfig
})

export const instanceNoToken = axios.create({
    ...defaultConfig
})

multiPartInstance.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'multipart/form-data'
        config.headers.Authorization = "Bearer " + TokenService.GetToken()
        return config
    }
)

instance.interceptors.request.use(
    config => {
        config.headers["Content-Type"] = 'application/json'
        config.headers.Authorization = "Bearer " + TokenService.GetToken()
        return config
    }
)

instanceNoToken.interceptors.request.use(
    config => {
        config.headers["Content-Type"] = 'application/json'
        return config
    }
)



// handle access token expire
instance.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error?.response?.status && 401 === error.response.status) {
        TokenService.RemoveToken()
    } else {
        return Promise.reject(error)
    }
})


multiPartInstance.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error?.response?.status && 401 === error.response.status) {
        TokenService.RemoveToken()
    } else {
        return Promise.reject(error)
    }
})

export default instance