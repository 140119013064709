import React, { useState, useEffect, useContext } from "react";
import { ThreeDotLoader } from "../Loader/Loader";
import { LuCheck, LuDelete, LuEye, LuPen, LuTrash2 } from "react-icons/lu";
import { confirmAlert } from "react-confirm-alert";
import { LuFileText } from "react-icons/lu";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import IModelService from "../../Services/VendorAPIService/IModelService";
import ModelsServices from "../../Services/VendorAPIService/ModelService";
import { toastError, toastSuccess } from "../Toast/Toast";
import IUser from "../../Services/UserManagement/IUserManagementService";
import UserContext from "../../Context/UserContext/UserContext";
import { IPermissions } from "../../Services/RoleManagement/IRoleManagementService";
import PermissionContext from "../../Context/PermissionContext/PermissionContext";
import ModelsForm from "./ModelForm";



const Models = () => {
	const navigate = useNavigate();
	const [showLoader, setShowLoader] = useState<boolean>(false);
	const [vendorsList, setVendorsList] = useState<IModelService[]>([]);

	const user = useContext<IUser>(UserContext)
	const permission = useContext<IPermissions>(PermissionContext)
	const [showModelForm, setShowModelForm] = useState<boolean>(false)
	const [selectedModel, setSelectedModel] = useState<IModelService | null>(null)
	useEffect(() => {
		if (!permission.viewApiKeyList) return;
		getAllModels();
	}, []);


	const getAllModels = () => {  //need to uncommnet
		setShowLoader(true)
		ModelsServices.GetAllModels().then(res => {
			setVendorsList(res.response)
			setShowLoader(false)
		}).catch(error => {
			console.log(error)
			setShowLoader(false)
		})
	}

	const deleteModel = (modelName: string) => {
		setShowLoader(true)
		ModelsServices.DeleteModel(modelName).then((res) => {
			setShowLoader(false)
			toastSuccess("Vendor Key Deleted Successfully")
			getAllModels()
		}).catch(error => {
			console.log(error)
			toastError("Vendor Key Deletion Failed")
			setShowLoader(false)
		})
	}

	const deleteModelconfirm = (modelName: any) => {
		if (!permission.deleteApiKey) return;
		confirmAlert({
			title: "Confirm Delete",
			message: "Do you want to delete the item?",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						deleteModel(modelName)
					}
				},
				{
					label: "No",
					onClick: () => { }
				}
			]
		})
	};


	const createModel = () => {
		if (!permission.createApiKey) return;
		setShowModelForm(true)
	};
	const editModel = (model: IModelService) => {
		setShowModelForm(true)
		setSelectedModel(model)
	};
	const hideShowForm = (action: boolean) => {
		setShowModelForm(action)
		setSelectedModel(null)
		getAllModels()
	}

	return (
		<>
			<div className="contentWrapper ">
				<div className="promtSelection">

					<div style={{ textAlign: 'right' }}>
						<button
							className="btn btn btn-secondary ms-auto mb-3"
							type="button"
							onClick={createModel}
						>
							Add Model Key
						</button>
					</div>

					<div className="row">
						{vendorsList && vendorsList.map((item) => (
							<div className="col-12 col-md-6 col-lg-4 mb-4 boxHolder" key={item.id}>
								<div className="box justify-content-between">
									<div className="d-flex gap-3 title-bar align-items-center pe-3">
										<div className="iconHolder"><LuFileText /></div>
										<div className="d-flex justify-content-between align-items-center flex-fill">
											<label className="form-check-label" htmlFor={item.name}>
												{item.name}
											</label>
											<button className="btn" title="View" onClick={() => editModel(item)}>
												<LuPen />
											</button>
										</div>

										<div className="icons">
											<button
												className="close" title="Delete"
												onClick={() => deleteModelconfirm(item.name ? item.name : '')}
											>
												<LuTrash2 />
											</button>


										</div>
										{/* <div className="bottom-holder d-flex gap-3">
											<div className="ms-auto">
												<div className="d-flex gap-3">
													<button className="btn icon-btn" title="View" onClick={() => editModel(item)}>
														<LuPen />
													</button>
												</div>
											</div>
										</div> */}
									</div>
									<p className="description">{item.description}</p>

								</div>

							</div>
						))}

						{vendorsList?.length == 0 && <>
							<div className="msg right-msg">
								<div className="leftItems">
									<div className="chatDesc">
										<center>There are no records to display</center>
									</div>
								</div>
							</div>

						</>}
					</div>

				</div>
			</div>
			{showModelForm && <ModelsForm HideShowForm={hideShowForm} ModelData={selectedModel} />}
			<ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
		</>
	);
};

export default Models;
