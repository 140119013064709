import React, { SetStateAction, useState } from "react";
import { toastError, toastSuccess, toastWarning } from "../Toast/Toast";
import { useNavigate } from "react-router-dom";
import { ThreeDotLoader } from "../Loader/Loader";
import GroupService from "../../Services/GroupService/GroupService";
import { FaTimes } from "react-icons/fa";
import { LuUpload } from "react-icons/lu";
import IGroupService from "../../Services/GroupService/IGroupService";
import IAgentService from "../../Services/AgentServices/IAgentService";
interface Props {
	SetShowGroupFormPopup: React.Dispatch<React.SetStateAction<boolean>>
	SetCreateItemForm?: React.Dispatch<React.SetStateAction<IAgentService>>
}


const CreateGroup: React.FC<Props> = ({ SetShowGroupFormPopup, SetCreateItemForm }) => {

	const [showLoader, setShowLoader] = useState(false);
	const disableSubmitButton = React.useRef<boolean>(false)


	const [createGroupForm, setCreateGroupForm] = useState<IGroupService>({
		name: "",
		description: "",
		uploadIcon: null,
		icon: null
		// Image: null as File | null
	});


	const onChnageForm = (target: any) => {
		let name = target.name;
		let val = target.value;

		if (name === "Image") {
			const selectedImage = target.files[0];
			const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];

			if (selectedImage && allowedImageTypes.includes(selectedImage.type)) {
				setCreateGroupForm((prevState) => ({
					...prevState,
					uploadIcon: selectedImage,
				}));
			} else {
				toastError("Please select a valid image file (jpeg, jpg, png, etc.)");
			}
		} else {
			setCreateGroupForm({
				...createGroupForm,
				[name]: val,
			});
		}
	};


	const validateAgentData = () => {
		let flag = true
		if (!createGroupForm.name.trim()) {
			toastError("Please Enter Group Name")
			flag = false
		}
		if (!createGroupForm.description.trim()) {
			toastError("Please Enter Description")
			flag = false
		}
		return flag
	}


	const canceleForm = () => {
		SetShowGroupFormPopup(false)
	};

	const submitForm = async (event: React.FormEvent) => {
		event.preventDefault();
		if (!validateAgentData()) return;
		setShowLoader(true);
		disableSubmitButton.current = true;
		GroupService.CreateGroup(createGroupForm).then(res => {
			if (res.statusCode == 200) {
				toastError("Group already exists");
				disableSubmitButton.current = false;
			} else if (res.statusCode == 201) {
				toastSuccess("Group Created Successfully");
				if (SetCreateItemForm) {
					SetCreateItemForm((preVal) => ({
						...preVal, group: createGroupForm.name
					}))
				}
				// navigate("/Groups")
				canceleForm()
			}
		}).catch(rror => {
			console.log(rror)
		}).finally(() => {
			setShowLoader(false)
		})

	};


	function getTotalLength(inputVal: any) {
		return inputVal.length + (inputVal.match(/\n/g) || []).length;
	}




	return (
		<div className="popup-container medium-popup">
			<div className="shadeClose" onClick={() => SetShowGroupFormPopup(false)}></div>
			<div className="popup-inner">
				<div className="inner-design">
					<div className="popup-header">
						<h3>Create Group</h3>
						<button
							type="button"
							onClick={() => { canceleForm(); }}
							className="close"
						><FaTimes /></button>
					</div>

					<div className="popup-content">
						<div className="row">
							<div className="col col-lg-3 col-md-3 col-sm-3 formHolder nameHolder mb-4">
								<label className="form-label">Group Icon<span className="danger">*</span></label>
								<div className="uploadButton">
									{!createGroupForm.uploadIcon && (
										<div className="uploadTrigger">
											<div className="icon"><LuUpload /></div>
											{/* <label className="form-label">
													Upload Display Icon</label> */}
										</div>
									)}
									{createGroupForm.uploadIcon && (
										<div className="iconHolder">
											<img src={URL.createObjectURL(createGroupForm.uploadIcon)} alt="Preview" />
										</div>
									)}
									<input
										type="file"
										name="Image"
										onChange={(event) => onChnageForm(event.target)}
										className="form-control"
										title={'imageName'}
										accept=".jpeg, .jpg, .png,.ico"

									/>
									<div className="edit-icon">
										<svg width="13" height="13" viewBox="0 0 13 13">
											<path id="Icon_awesome-pencil-alt" data-name="Icon awesome-pencil-alt" d="M12.644,3.612,11.473,4.782a.3.3,0,0,1-.432,0L8.223,1.964a.3.3,0,0,1,0-.432L9.393.361a1.221,1.221,0,0,1,1.724,0l1.526,1.526A1.217,1.217,0,0,1,12.644,3.612ZM7.217,2.537.55,9.205.011,12.29A.61.61,0,0,0,.717,13L3.8,12.456,10.47,5.788a.3.3,0,0,0,0-.432L7.652,2.537a.308.308,0,0,0-.434,0Zm-4.065,6.1a.354.354,0,0,1,0-.5l3.91-3.91a.356.356,0,0,1,.5.5l-3.91,3.91a.354.354,0,0,1-.5,0Zm-.917,2.135H3.454v.922l-1.638.287-.79-.79.287-1.638h.922Z" transform="translate(-0.002 -0.005)" fill="#48a3f6" />
										</svg>
									</div>
								</div>
							</div>
							<div className="col-lg-9 col-md-9 col-sm-9 formHolder nameHolder mb-4">
								<label className="form-label">Group Name<span className="danger">*</span></label>
								<input
									type="text"
									placeholder="Enter the name"
									className="form-control"
									name="name"
									onChange={(event) => onChnageForm(event.target)}
									value={createGroupForm.name}
								/>
							</div>

							<div className="col-12 col-md-12 formHolder descHolder mb-4">
								<label className="form-label">Description <span className="danger">*</span></label>
								<textarea className="form-control"
									placeholder="Enter the Description"
									onChange={(event) => onChnageForm(event.target)}
									name="description"
									value={createGroupForm.description}
									maxLength={500}
								/>
								{getTotalLength(createGroupForm.description) > 499 && (<div className="danger">Description cannot exceed 500 characters.</div>)}

							</div>

							<div className="col-12 col-md-12 mt-2 btnHolder d-flex gap-3">
								<button type="button" className="btn btn-secondary ms-auto" onClick={() => canceleForm()}>Cancel</button>
								<button className="btn btn-primary" type="button" onClick={(e) => submitForm(e)}>Create</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
		</div>

	);
};

export default CreateGroup;
