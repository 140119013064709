import React, { useState } from "react";
import { toastError, toastSuccess, toastWarning } from "../../Toast/Toast";
import { useNavigate } from "react-router-dom";
import { ThreeDotLoader } from "../../Loader/Loader";
import { useSearchParams } from 'react-router-dom';
import { LuUser } from "react-icons/lu";
import PromptService from "../../../Services/PromptService/PromptService";
import "./Prompt.scss";
import IPromptService from "../../../Services/PromptService/IPromptService";
// import { permissionState } from "../../../Constants/constants";


const CreatePrompt = () => {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const disableSubmitButton = React.useRef<boolean>(false)
    const [searchParams] = useSearchParams();
    const assistantId = React.useRef(searchParams.get('assistantId'));
    const isEditable = React.useRef<boolean>(false)


    const [createItemForm, setCreateItemForm] = useState<IPromptService>({
        name: "",
        description: "",
        instruction: "",
    });


    const updateForm = (target: any) => {
        let name = target.name;
        let val = target.value;
        setCreateItemForm({
            ...createItemForm,
            [name]: val,
        });

    };


    const validateAgentData = () => {
        let flag = true
        if (!createItemForm.name?.trim()) {
            toastError("Please Enter Prompt Name")
            flag = false
        }
        if (!createItemForm.description?.trim()) {
            toastError("Please Enter Description")
            flag = false
        }
        if (!createItemForm.instruction) {
            toastError("Please Enter Instruction")
            flag = false
        }
        return flag
    }


    const createForm = (event: React.FormEvent) => {
        event.preventDefault();
        if (validateAgentData()) {
            setShowLoader(true)
            disableSubmitButton.current = true
            PromptService.CreatePrompt(createItemForm).then(async (res) => {
                if (res.statusCode == 200) {
                    disableSubmitButton.current = false
                    toastError(res.response)

                } else if (res.statusCode == 201) {
                    isEditable.current = true
                    toastSuccess("Prompt Created Successfully")
                    checkRedirectPermission()
                }
                setShowLoader(false)

            }).catch(rror => {
                setShowLoader(false)

            })
        }
    };



    const cancelForm = (event: React.FormEvent) => {
        // navigate('/prompts')
        checkRedirectPermission()
    };


    function getTotalLength(inputVal: any) {
        if (inputVal === null) {
            return 0;
        }
        return inputVal.length + (inputVal.match(/\n/g) || []).length;
    }

    const checkRedirectPermission = () => {
        navigate("/prompts");
        // if (permissionState.isViewPrompt) {
        // 	navigate("/prompts");
        // } 				
        // else if (permissionState.isViewDashboard) {
        // 	navigate("/Dashboard");
        // }
        // else if (permissionState.isViewGroup) {
        // 	navigate("/Groups");
        // }
        // else if (permissionState.isViewBot) {
        // 	navigate("/Apps");
        // }		
        // else if (permissionState.isViewTemplate) {
        // 	navigate("/Templates");
        // }
        // else {
        // 	navigate("/");
        // }
    }



    return (
        <div className="contentWrapper create-container">
            <div className="stepOne">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-6 leftContainer">
                        <form onSubmit={createForm} encType="multipart/form-data" className="form-container">

                            <div className="row">
                                <div className="col-12 col-md-12 formHolder nameHolder mb-4">
                                    <label className="form-label">Prompt Name <span className="danger">*</span></label>
                                    <input
                                        type="text"
                                        placeholder="Enter the name"
                                        className="form-control" onChange={(event) => updateForm(event.target)}
                                        name="name"
                                        value={createItemForm.name}
                                        disabled={isEditable.current}
                                    />
                                </div>
                                <div className="col-12 col-md-12 formHolder descHolder mb-4">
                                    <label className="form-label">Description <span className="danger">*</span></label>
                                    <textarea className="form-control height-80"
                                        placeholder="Enter the Description"
                                        onChange={(event) => updateForm(event.target)}
                                        name="description"
                                        value={createItemForm.description}
                                        maxLength={100}
                                        disabled={isEditable.current}
                                    />
                                    {getTotalLength(createItemForm.description) > 99 && (<div className="danger">Description cannot exceed 100 characters.</div>)}
                                </div>

                                <div className="col-12 col-md-12 formHolder descHolder mb-4">
                                    <label className="form-label">Instruction <span className="danger">*</span></label>
                                    <textarea className="form-control"
                                        placeholder="Enter the Instruction"
                                        onChange={(event) => updateForm(event.target)}
                                        name="instruction"
                                        value={createItemForm.instruction}
                                        maxLength={500}
                                    />
                                    {getTotalLength(createItemForm.instruction) > 499 && (<div className="danger">Instructions cannot exceed 500 characters.</div>)}
                                </div>

                                <div className="col-12 col-md-12 mt-4 btnHolder d-flex gap-3">
                                    <button type="submit" className="btn btn-secondary ms-auto" onClick={cancelForm}>Cancel</button>
                                    {isEditable.current && (<button className="btn btn-primary" type="submit">Done</button>)}
                                    {!isEditable.current && (<button className="btn btn-primary" type="submit">Create</button>)}

                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6 right-container">
                        <div className="inner-deck ps-4">
                            <div className="chatRe d-flex align-items-center gap-3 mb-4">
                                <div className="user-dp">
                                    <LuUser />
                                </div>
                                <div className="answer-heading">
                                    <h3>Prompt Creation Guide</h3> </div>

                            </div>
                            <div className="info-deck d-flex">
                                <div className="info-content">
                                    <h4 className="mb-1">
                                        Name
                                    </h4>
                                    <ul className="mb-3">
                                        <li>
                                            Enter a meaningful name that reflects the purpose or topic of the prompt.
                                        </li>
                                    </ul>

                                    <h4 className="mb-1">
                                        Instruction
                                    </h4>
                                    <ul className="mb-3">
                                        <li className="mb-1">
                                            Specify how the prompt should function by Providing clear and concise instructions.
                                        </li>
                                        <li>
                                            Ensure your instructions are within 500 words.
                                        </li>
                                    </ul>



                                    <h4 className="mb-1">
                                        Description
                                    </h4>
                                    <ul className="mb-3">
                                        <li className="mb-1">
                                            Provide a brief description of the prompt
                                        </li>
                                        <li>Limit your description to 100 words</li>
                                    </ul>

                                </div>
                                <div className="robo">
                                    <img src={require("../../../common/images/rob.png")} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>

        </div >
    );
};

export default CreatePrompt;
