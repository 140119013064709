import React, { useState, FC, SetStateAction, useRef } from 'react';
import { LuCheck } from 'react-icons/lu';
import "./Accordion.scss";
import { IWebsite } from '../../../Services/AgentServices/IwebsiteService';
import { useSearchParams } from 'react-router-dom';
import AgentService from '../../../Services/AgentServices/AgentService';
import { toastSuccess } from '../../Toast/Toast';


export interface AccordionProps {
  UrlData: IWebsite[];
  SetUrlData: React.Dispatch<SetStateAction<IWebsite[]>>
  SubmitUrlData?: any
  CloseEosPopup?: any
}


export interface AccordionItemProps extends AccordionProps {
  item: IWebsite;
  index: number;
}

const AccordionItem: FC<AccordionItemProps> = ({ item, index, UrlData, SetUrlData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const handleCheckboxChange = (action: any) => {
    SetUrlData((prevVal) => {
      prevVal[index].selection = action.checked
      return [
        ...prevVal,
      ]
    });

  };

  return (
    <div className={`mlAccordion ${isOpen ? 'open' : 'close'}`}>
      <div className='items'>
        <div className="chck-holder">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            onClick={(e) => handleCheckboxChange(e.target)}
            checked={item.selection}
            id={String(index)}
          />
          <div className="checkDesignHolder" >
            <span><LuCheck /></span>
          </div>
        </div>
        <label onClick={toggleOpen}>{item.title}</label>
      </div>
      {isOpen && (
        <div className='sub' style={{ paddingLeft: "20px" }}>
          {/* <div className='noItems' dangerouslySetInnerHTML={{ __html: item.content }} /> */}
          {item.subItems && item.subItems.map((subItem, index) => (
            <AccordionItem index={index} item={subItem} UrlData={UrlData} SetUrlData={SetUrlData} />
          ))}
        </div>
      )}

    </div>
  );
};


const Accordion: FC<AccordionProps> = ({ UrlData, SetUrlData, SubmitUrlData, CloseEosPopup}) => {
  const [searchParams] = useSearchParams();
  const assId = searchParams.get('assistantId')
  const selectedAssistantId = useRef(assId ? assId : '');
  const [showLoader, setshowLoader] = useState(false);

  

  return (
    <div>
      {UrlData.map((item, index) => (
        <AccordionItem index={index} item={item} UrlData={UrlData} SetUrlData={SetUrlData} />
      ))}
      <div className="col-12 col-md-12 mt-2 btnHolder d-flex gap-3">
        <button type="button" className="btn btn-secondary ms-auto" onClick={CloseEosPopup}>Cancel</button>
        <button className="btn btn-primary" type="button" onClick={SubmitUrlData}>Submit</button>
      </div>
    </div>
  );
};

export default Accordion;
