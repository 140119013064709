
import { SENDQUESTION, ASSISTANT_FILE_UPLOAD, } from "../EndPoint"
import IResponse from "../IResponse"
import TokenService from "../TokenService/TokenService"
import IChatBot from "./IChatBot"
import { multiPartInstance } from "../AxiosInstance";
import { createChunkFileForms } from "../../Components/Utils/Utils";
import { redirect, useNavigate } from "react-router-dom";

export default class ChatBotService {

    public static SendQuestion(qstnObj: IChatBot): Promise<any> {
        // using fetch instead axios, chat api working stream based 
        return new Promise((resolve, reject) => {
            let payload: IChatBot = {
                assistantId: qstnObj.assistantId,
                query: qstnObj.query,
                sessionId: qstnObj.sessionId,
                stream: qstnObj.stream,
                prompt: qstnObj.prompt
            }
            fetch(SENDQUESTION, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + TokenService.GetToken(),
                },
                body: JSON.stringify(payload)
            }).then(res => {
                if (res.status === 401) {
                    TokenService.RemoveToken()
                    resolve(res)
                }  
            }).catch(err => {
                if (err.status === 401) {
                    redirect('/')
                } else {
                    reject(err)
                }
            })
        })
    }

    public static AssistantSessionFileUpload(assistantId: string, sessionid: string, files: File[]): Promise<IResponse | string> {
        return new Promise(async (resolve, reject) => {
            const fileForms = createChunkFileForms(assistantId, files)
            const promises: any = []
            fileForms.map(formData => {
                promises.push(
                    multiPartInstance.post(`${ASSISTANT_FILE_UPLOAD}/${assistantId}/session/${sessionid}/session-upload-chunk`, formData).then(res => {
                    })
                )
            })
            await Promise.allSettled(promises).then((res: any) => {
                let rejected = res.filter((item: any) => item.status === "rejected")
                if (rejected.length > 0) {
                    reject(rejected[0]["reason"] ? rejected[0]["reason"] : '')
                } else (resolve(""))
            }).catch(err => {
                reject(err)
            })
        })
    }


    public static AssistantSessionFileUploadbackup(assistantId: string, sessionid: string, data: FormData): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            multiPartInstance.post(`${ASSISTANT_FILE_UPLOAD}/${assistantId}/session/${sessionid}/uploads`, data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

}