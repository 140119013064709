import React, { useContext, useRef, useState } from "react";
import { toastError, toastSuccess, toastWarning } from "../Toast/Toast";
import { FaTrash, FaPaperclip, FaFolder, FaDownload, FaPlus, FaArrowLeft } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { FaCircleCheck, FaCheck } from "react-icons/fa6";
import { LuUpload, LuPlus, LuPen, LuCheck, LuTrash, LuRotateCw, LuChevronDown, LuRefreshCcw } from "react-icons/lu";
import { PiThumbsUpLight } from "react-icons/pi";
import AgentService from "../../Services/AgentServices/AgentService";
import IModelsService from "../../Services/AgentServices/IModelsSerive";
import { useNavigate } from "react-router-dom";
import { FaTimes } from 'react-icons/fa';
import { confirmAlert } from "react-confirm-alert";
import { ThreeDotLoader } from "../Loader/Loader";
import { usePapaParse } from "react-papaparse";
import { v4 as uuidv4 } from 'uuid';
import UserContext from "../../Context/UserContext/UserContext";
import IUser from "../../Services/TokenService/IUser";


const CreateAgent = ({ Props }: any) => {

	const navigate = useNavigate();
	const assistantId = uuidv4()
	const jsonFolderName = useRef<string>("apps")
	const userData = useContext<IUser>(UserContext)


	const [showLoader, setshowLoader] = useState(false);
	const fileInputRef = React.useRef<HTMLInputElement | null>(null);
	const [modelList, setModelList] = useState([]);
	const [showAddButtonQA, setShowAddbuttonQA] = useState(false);
	const [showAddButtonDS, setShowAddbuttonDS] = useState(false);
	const [isItemDisabledQA, setIsItemDisabledQA] = useState<boolean[]>([]);
	const [isItemDisabledDS, setIsItemDisabledDS] = useState<boolean[]>([]);
	const [showForm1, setShowForm1] = useState(true);
	const [selectedTab, setSelectedTab] = useState("files");
	const [selectedURL, setSelectedURL] = useState("");
	const [selectedQuestion, setSelectedQuestion] = useState("");
	const [selectedAnswer, setSelectedAnswer] = useState("");
	const [editedQuestion, setEditedQuestion] = useState("");
	const [editedAnswer, setEditedAnswer] = useState("");
	const [editingIndexQA, setEditingIndexQA] = useState<number | null>(null);
	const [editingIndexDS, setEditingIndexDS] = useState<number | null>(null);
	const [filesCount, setFilesCount] = useState(0);
	const [charCount, setCharCount] = useState(0);
	const [qaCount, setQACount] = useState(0);
	const [dsCount, setDSCount] = useState(0);
	const [websiteCount, setWebsiteCount] = useState(0);
	const moveNextStep = React.useRef<boolean>(false)
	const [showUplodPopup, setShowUplodPopup] = useState(false)
	const [QAFile, setAQFile] = useState<File>(new File([''], ''))
	const [IsQAFileButton, setIsQAFileButton] = React.useState<boolean>(true)
	const [openSection, setOpenSection] = useState<number | null>(0);
	const disableSubmitButton = React.useRef<boolean>(false)
	const [unsavedChanges, setUnsavedChanges] = useState(false);



	const [currentDS, setCurrentDS] = useState<{ Name?: string; ConnectionType?: string, ConnectionString?: string, Id?: number }>({
		Id: 0,
		Name: '',
		ConnectionType: '',
		ConnectionString: ''
	})

	const { readString } = usePapaParse();

	const [createItemForm, setCreateItemForm] = useState({
		AssistantName: "",
		DisplayName: "",
		Description: "",
		Files: [] as any[],
		Instructions: "",
		ModelId: "",
		apiKey: "",
		Image: null as File | null,
		Websites: [] as any[] | [{ Url: string; Id: number }],
		QandAList: [] as any[] | [{ Question: string; Answer: string }],
		DataSources: "",
		// DataSources: [] as any[] | [{ Name: string; ConnectionType: string, ConnectionString: string }],
		// Prompts: [] as any[] | [{ Rephrase: true, Shorten: true, Expand: true, MoreCasual: true, MoreFormal: true }],
		Prompts: ["Rephrase", "Expand", "MoreCasual", "MoreFormal", "Shorten"],
	});


	/////////////////////////////////////// DataSource Tab /////////////////////////////////////// 

	const changeDataSource = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		let name = e.target.name
		let value = e.target.value
		setCreateItemForm((prevState) => ({
			...prevState,
			DataSources: value,
		}));
		// setCurrentDS((preVal) => ({ ...preVal, [name]: value }))
		// console.log(currentDS)

	}



	/////////////////////////////////////// Files Tab /////////////////////////////////////// 

	const getFileName = (name: any) => {
		var extn = name.split(".")[1];
		var filename;
		if (name.split(".")[0].trim("").length > 10) {
			filename = name.substring(0, 10) + "... ." + extn;
		} else {
			filename = name;
		}
		return filename;
	};

	const handleDrop = (event: any) => {
		event.preventDefault();
		const selectedFiles = Array.from(event.dataTransfer.files);
		setCreateItemForm((prevState) => ({
			...prevState,
			Files: [...prevState.Files, ...selectedFiles],
		}));
	};

	const deleteFile = (index: number) => {
		const updatedFiles = [...createItemForm.Files];
		updatedFiles.splice(index, 1);
		setCreateItemForm((prevState) => ({
			...prevState,
			Files: updatedFiles,
		}));
	};


	/////////////////////////////////////// Website Tab /////////////////////////////////////// 

	const updateWebsiteList = (target: any) => {
		let val = target.value;
		setSelectedURL(val);
	}

	const onWebsitesCheckClick = () => {
		if (selectedURL) {
			const urlRegex = /^(https?:\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
			if (urlRegex.test(selectedURL.trim())) {
				setCreateItemForm((prevState) => {
					const updatedWebsites = [...prevState.Websites, { Url: selectedURL.trim(), Id: prevState.Websites.length }];
					// const sortedWebsites = updatedWebsites.sort((a, b) => a.Id - b.Id);
					const sortedWebsites = updatedWebsites.sort((a: any, b: any) => (a.Id > b.Id ? -1 : Number(a.Id < b.Id)))

					return { ...prevState, Websites: sortedWebsites };
				});
				setSelectedURL("");
			} else {
				toastError("Please enter a valid URL");
			}
		} else {
			toastError("Please enter a valid URL");
		}
	}

	const deleteURL = (index: number) => {
		setCreateItemForm((prevState) => {
			const updatedURl = [...prevState.Websites];
			updatedURl.splice(index, 1);
			// const sortedWebsites = updatedURl.sort((a, b) => a.Id - b.Id);
			const sortedWebsites = updatedURl.sort((a: any, b: any) => (a.Id > b.Id ? -1 : Number(a.Id < b.Id)))
			return { ...prevState, Websites: sortedWebsites };
		});
	};



	///////////////////////////////////////  Q and A Tab  /////////////////////////////////////// 

	React.useEffect(() => {
		// getAllModels()
		setFilesCount(createItemForm.Files.length)
		setCharCount(createItemForm.Instructions.length)
		setQACount(createItemForm.QandAList.length)
		setDSCount(createItemForm.DataSources.length)
		setWebsiteCount(createItemForm.Websites.length)
	}, [createItemForm.QandAList, createItemForm.Files, createItemForm.Instructions, createItemForm.Websites]);


	const handleQandACreate = (target: any) => {
		let name = target.name;
		let val = target.value;
		if (name === "Question") {
			setSelectedQuestion(val)
		}
		if (name === "Answer") {
			setSelectedAnswer(val)
		}
		setUnsavedChanges(val.length > 0);
	}

	const createQandA = () => {
		if (selectedQuestion && selectedAnswer) {
			setCreateItemForm((prevState) => {
				const updatedQandAList = [...prevState.QandAList, { Question: selectedQuestion, Answer: selectedAnswer, Id: prevState.QandAList.length }];
				updatedQandAList?.sort((a: any, b: any) => (a.Id > b.Id ? -1 : Number(a.Id < b.Id)))
				const newState = { ...prevState, QandAList: updatedQandAList };
				// console.log("Create-QandA", newState.QandAList);
				return newState;
			});
			setSelectedQuestion("");
			setSelectedAnswer("");
			setShowAddbuttonQA(true);
			setIsItemDisabledQA((prev) => [...prev, false]);
			setUnsavedChanges(false);
		} else {
			toastError("Please enter both Question and Answer");
		}
	};


	const handleQandAUpdate = (e: any, index: number) => {
		let name = e.target.name;
		let val = e.target.value;

		if (name === "Question") {
			setEditedQuestion(val);
		} else if (name === "Answer") {
			setEditedAnswer(val);
		}
		setUnsavedChanges(val.length > 0);
	};

	const updateQandAChange = (e: any, index: number) => {
		setCreateItemForm((prevState) => {
			const updatedQandAList = [...prevState.QandAList];
			if (editedQuestion) {
				updatedQandAList[index].Question = editedQuestion;
			}
			if (editedAnswer) {
				updatedQandAList[index].Answer = editedAnswer;
			}
			const newState = { ...prevState, QandAList: updatedQandAList };
			return newState;
		});

		setIsItemDisabledQA((prev: any) =>
			prev.map((value: any, i: any) => (i === index ? !value : value))
		);

		setEditingIndexQA(null);
		setEditedQuestion("");
		setEditedAnswer("");
		setUnsavedChanges(false);
	};

	const enableSelectedQandA = (index: number) => {
		setEditingIndexQA(index);
		setEditedQuestion(createItemForm.QandAList[index].Question);
		setIsItemDisabledQA((prev: any) =>
			prev.map((value: any, i: any) => (i === index ? !value : value))
		);
	};



	const addNewQandA = () => {
		setShowAddbuttonQA(false)
	}



	const deleteQandA = (index: number) => {
		confirmAlert({
			title: "Confirm Delete",
			message: "Do you want to delete the item ?",
			buttons: [
				{
					label: "Yes",
					onClick: () => deleteQandAConfirm(index)

				},
				{
					label: "No",
					onClick: () => { }
				}
			]
		})
	};


	const deleteQandAConfirm = (index: number) => {
		setIsItemDisabledQA((prev: any) =>
			prev.map((value: any, i: any) => (i === index ? false : value))
		);
		setCreateItemForm((prevState) => {
			const updatedQandA = [...prevState.QandAList];
			updatedQandA.splice(index, 1);
			return {
				...prevState,
				QandAList: updatedQandA,
			};
		});
		setQACount((prevCount) => prevCount - 1);
	};



	function getTotalLength(inputVal: any) {
		return inputVal.length + (inputVal.match(/\n/g) || []).length;
	}


	////   form   ////
	// const getAllModels = () => {
	// 	AgentService.GetModelList().then((res) => {
	// 		if (res) {
	// 			if (Array.isArray(res.Response)) {
	// 				setModelList(res.Response);
	// 			} else {
	// 				console.error("Invalid response format from GetAgentList API");
	// 			}
	// 		}
	// 	});
	// };

	const updateForm = (target: any) => {
		let name = target.name;
		let val = target.value;
		if (name === "Files") {
			const selectedFiles: File[] = Array.from(target.files || []) as File[];
			const allowedFileExtensions = [".txt", ".doc", ".docx", ".xls", ".xlsx", ".csv", ".pdf", ".jpg", ".png"]
			const filteredFiles = selectedFiles.filter(file => {
				const fileExtension = file.name.split('.').pop()?.toLowerCase();
				return fileExtension && allowedFileExtensions.includes(fileExtension);
			});

			if (filteredFiles.length === selectedFiles.length) {
				setCreateItemForm((prevState) => {
					const updatedFiles = [...(prevState.Files || []), ...filteredFiles];
					return {
						...prevState,
						Files: updatedFiles.reverse(),
					};
				});
			} else {
				toastError("Invalid file type. Please select files with allowed types");
			}
		}
		else if (name === "Image") {
			const selectedImage = target.files[0];
			const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];

			if (selectedImage && allowedImageTypes.includes(selectedImage.type)) {
				setCreateItemForm((prevState) => ({
					...prevState,
					Image: selectedImage,
				}));
			} else {
				toastError("Please select a valid image file (jpeg, jpg, png, etc.)");
			}
		}
		else {
			setCreateItemForm({
				...createItemForm,
				[name]: val,
			});
		}
	};


	const validateAgentData = () => {
		let flag = true
		if (!createItemForm.AssistantName.trim()) {
			toastError("Please Enter Agent Name")
			flag = false
		}
		if (!createItemForm.DisplayName.trim()) {
			toastError("Please Enter Display Name")
			flag = false
		}
		if (!createItemForm.Description.trim()) {
			toastError("Please Enter Description")
			flag = false
		}
		if (!createItemForm.ModelId) {
			toastError("Please Select Model")
			flag = false
		}
		return flag
	}


	const validateAgentData2 = () => {
		let flag = true
		if (!createItemForm.AssistantName.trim()) {
			toastError("Please Enter Agent Name")
			flag = false
		}
		if (!createItemForm.DisplayName.trim()) {
			toastError("Please Enter Display Name")
			flag = false
		}
		if (!createItemForm.Description.trim()) {
			toastError("Please Enter Description")
			flag = false
		}
		if (!createItemForm.ModelId) {
			toastError("Please Select Model")
			flag = false
		}

		// if (!createItemForm.Instructions.trim()) {
		// 	toastError("Please Enter Instructions")
		// 	flag = false
		// }
		if (createItemForm.Files && (createItemForm.Files.length > 20)) {
			toastError("Please select files up to 20.");
			flag = false;
		}
		if (unsavedChanges) {
			toastError("Please save or discard the changes in the FAQ section before updating.");
			flag = false;
		}
		return flag
	}


	const createForm1 = (event: React.FormEvent) => {
		event.preventDefault();
		if (validateAgentData()) {
			// toastWarning("Please do..")
			setShowForm1(false);
			moveNextStep.current = true

		}
	};

	const canceleForm1 = (event: React.FormEvent) => {
		setShowForm1(false);
		moveNextStep.current = false
		navigate('/Apps')

	};

	const createForm2 = (event: React.FormEvent) => {
		// event.preventDefault();
		// if (validateAgentData2()) {
		// 	setshowLoader(true)
		// 	disableSubmitButton.current = true
		// 	AgentService.CreateAgentAPI(createItemForm, jsonFolderName.current, assistantId).then(async (res) => {
		// 		console.log("res", res)
		// 		if (res) {
		// 			if (res.success === true) {
		// 				if (res.response === "Agent Name Already Exist") {
		// 					disableSubmitButton.current = false
		// 					toastError(res.response)
		// 				} else {
		// 					// toastError(res.response)
		// 					toastSuccess("Assistant Created Successfully")
		// 					navigate("/Apps")
		// 				}
		// 				setshowLoader(false)
		// 			} else {
		// 				disableSubmitButton.current = false
		// 				console.log('UpdateAgentAPI', res.response)
		// 				toastWarning("The server had an error processing your request. Sorry about that! You can retry your request")
		// 				setshowLoader(false)
		// 			}
		// 		} else {
		// 			// navigate("/Apps")
		// 			disableSubmitButton.current = false
		// 			toastWarning("Response Error")
		// 			setshowLoader(false)
		// 		}
		// 	})
		// }
	};

	const goBackToForm1 = () => {
		moveNextStep.current = false
		setShowForm1(true);
	};
	const viewUpload = () => {
		setShowUplodPopup(true);
	}
	const hideUplodPopup = () => {
		setShowUplodPopup(false);
		setIsQAFileButton(true)


	}

	const onChangeQAFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file: File = e.target.files ? e.target.files[0] : new File([''], '')
		if (file.type === 'text/csv') {
			setAQFile(file)
			setIsQAFileButton(false)
		} else {
			setIsQAFileButton(true)
			toastError("File Format Error")
		}

	}
	const uploadQAFile = (e: React.FormEvent) => {
		e.preventDefault()
		const reader = new FileReader()
		let qaArray: any = []
		let fError = false
		let numOfQAExits = createItemForm.QandAList.length
		reader.onload = function (e: any) {
			const text = e.target.result;
			readString(text, {
				worker: true,
				skipEmptyLines: true,
				complete: (results: any) => {
					for (var i = 0; i < results.data.length; i++) {
						let data: any = results.data[i]
						if (data?.length && data?.length == 2) {
							qaArray.push({ Question: data[0], Answer: data[1], Id: numOfQAExits + i })
						} else {
							toastError("File Format Error")
							fError = true
							break
						}
					}
					// results.data?.map((qaArr, index) => {
					// 	let data: any = qaArr
					// 	if (data?.length && data?.length == 2) {
					// 		qaArray.push({ Question: data[0], Answer: data[1], Id: numOfQAExits + index })
					// 	} else {
					// 		toastError("File Format Error")
					// 		break
					// 	}

					// })
					if (!fError) {
						setCreateItemForm((prevState) => {
							const updatedQandAList = [...prevState.QandAList, ...qaArray];
							const newState = { ...prevState, QandAList: updatedQandAList };
							// console.log("Create-QandA", newState.QandAList);
							setIsItemDisabledQA(Array(updatedQandAList.length).fill(false));
							setShowAddbuttonQA(true);
							return newState;
						});
						toastSuccess("File Uploaded successfully ")
						setShowUplodPopup(false);
						setIsQAFileButton(true)
					}

				},
			});

		}

		reader.readAsText(QAFile);
	}

	// accordion start
	const handleSectionClick = (sectionIndex: number) => {
		setOpenSection((prevOpenSection) => (prevOpenSection === sectionIndex ? null : sectionIndex));
	};
	// accordion end

	return (
		<div className="contentWrapper create-container">
			{showForm1 ? (
				<div className="stepOne">
					<h1 className="mb-5">Create Agent</h1>
					<div className="row">
						<div className="col-12 col-md-12 col-lg-8 col-xl-7 leftContainer">
							<form onSubmit={createForm1} encType="multipart/form-data" className="form-container">
								<div className="row">
									<div className="col-12 col-md-8 formHolder nameHolder mb-4">
										<label className="form-label">Agent Name <span className="danger">*</span></label>
										<input
											type="text"
											placeholder="Enter the name"
											name="AssistantName" className="form-control" onChange={(event) => updateForm(event.target)}
											value={createItemForm.AssistantName}
										/>
									</div>
									<div className="col-12 col-md-4 formHolder dNameHolder mb-4">
										<label className="form-label">Display Name <span className="danger">*</span></label>
										<input
											type="text"
											placeholder="Display Name"
											name="DisplayName" className="form-control" onChange={(event) => updateForm(event.target)}
											value={createItemForm.DisplayName}
										/>
									</div>

									<div className="col-12 col-md-12 formHolder descHolder mb-4">
										<label className="form-label">Description <span className="danger">*</span></label>
										<textarea className="form-control"
											placeholder="Enter the Description"
											name="Description" onChange={(event) => updateForm(event.target)}
											value={createItemForm.Description}
											maxLength={500}
										/>
										{/* <p>Characters remaining: {500 - createItemForm.Description.length}/{500}</p> */}
										{getTotalLength(createItemForm.Description) > 499 && (<div className="danger">Description cannot exceed 500 characters.</div>)}


									</div>

									<div className="col-12 col-md-6 formHolder selectHolder mb-4">
										<label className="form-label">Model <span className="danger">*</span></label>
										<select
											value={createItemForm.ModelId}
											name="ModelId"
											onChange={(event) => updateForm(event.target)} className="form-select"
										>
											<option value="">Select a Model</option>
											{/* {modelList && modelList.map((model) => (
												// <option key={model['ModelId']} value={model['ModelId']}>{model['ModelId']}</option>
												<option key={model['ModelId']} value={model['ModelId']} style={{ color: (model['ModelId'] === "claude-3-opus-20240229-1106" || model['ModelId'] === "gpt-4-1106-preview") ? "#C00077" : "#2F1E3D" }}>{model['ModelId']}</option>

											))} */}
										</select>
									</div>

									<div className="col-12 col-md-6 formHolder keyHolder mb-4">
										<label className="form-label">GPT-API Key <span className="danger">*</span></label>
										<input
											type="text"
											placeholder="API Key"
											name="apiKey" className="form-control" onChange={(event) => updateForm(event.target)}
											value={createItemForm.apiKey}
										/>
									</div>


									<div className="col-12 col-md-12 mt-4 btnHolder d-flex gap-3">
										<button type="submit" className="btn btn-secondary ms-auto" onClick={canceleForm1}>Cancel</button>
										<button className="btn btn-primary" type="submit">Create</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			) : ""}

			{moveNextStep.current ? (

				<div className="stepTwo">
					<nav aria-label="breadcrumb ">
						<ol className="breadcrumb mb-1">
							<li className="breadcrumb-item active" onClick={goBackToForm1} aria-current="page">
								<button ><FaArrowLeft /></button>
								Basic Details
							</li>

						</ol>
					</nav>
					<h2 className="mb-4">Create Agent</h2>


					<form onSubmit={createForm2} className="form-container">
						<div className="row">
							<div className="col-12 col-md-2 leftImage">
								<div className="uploadButton">
									{/* before upload */}
									{createItemForm.Image === null &&
										<div className="uploadTrigger">
											<div className="icon"><LuUpload /></div>
											<label className="form-label">
												Upload Display Icon</label>
										</div>
									}

									{/* preview */}
									{createItemForm.Image !== null && (
										<div className="dpDisplay">
											<img src={URL.createObjectURL(createItemForm.Image)} alt="Preview" />
										</div>
									)}
									{/* upload button */}
									<input
										type="file"
										name="Image"
										onChange={(event) => updateForm(event.target)}
										className="form-control"
									/>
								</div>
							</div>

							<div className="col-12 col-md-10 rightDetails">
								<div className="row">
									<div className="col-12 col-md-9 topInfo mb-5">
										<h1 className="mb-3">{createItemForm.AssistantName}</h1>
										<div className="description mb-2">
											{createItemForm.Description}
										</div>
										<h6><span>Model:</span> {createItemForm.AssistantName}</h6>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-9 tabHOlder">
										<h2 className="mb-4">Train your agent with following options</h2>
										<div className="tabHeader">
											<div
												className={`tabButton ${selectedTab === "files" ? "active" : ""}`}
												onClick={() => setSelectedTab("files")}
											>
												Files
											</div>
											<div
												className={`tabButton ${selectedTab === "text" ? "active" : ""}`}
												onClick={() => setSelectedTab("text")}
											>
												Text Instructions
											</div>
											<div
												className={`tabButton ${selectedTab === "website" ? "active" : ""}`}
												onClick={() => setSelectedTab("website")}
											>
												Website
											</div>
											{/* <div
												className={`tabButton ${selectedTab === "qna" ? "active" : ""}`}
												onClick={() => setSelectedTab("qna")}
											>
												FAQ
											</div> */}
											<div
												className={`tabButton ${selectedTab === "ds" ? "active" : ""}`}
												onClick={() => setSelectedTab("ds")}
											>
												Data Source
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-9 tabContentHolder">
										<div className="tabContent">
											<div className="topContent">

												{selectedTab === "files" && (
													<div className="commonDiv">
														<div className="titleBar">
															<h3>Upload Files</h3>
														</div>
														<div className="content">
															<div className="drag_drop_container" onDrop={handleDrop} onDragOver={(event) => event.preventDefault()}>
																<div className="leftContainer" onClick={() => fileInputRef?.current?.click()}>
																	<input
																		ref={fileInputRef}
																		type="file"
																		name="Files"
																		multiple
																		accept=".txt, .doc, .docx, .xls, .xlsx, .csv, .pdf, .jpg, .png"
																		onChange={(event) => updateForm(event.target)}
																		style={{ display: "none" }}
																	/>
																	{/* <p>Drag & drop files here, or click to select files. Supported File Types: .pdf, .doc, .docx, .txt</p> */}																	
																	<p>Drag & drop files here, or click to select files. Supported File Types: .txt, .doc, .docx, .xls, .xlsx, .csv, .pdf, .jpg, .png</p>

																</div>
																<div className="rightContainer">
																	{createItemForm.Files.length > 0 &&
																		createItemForm.Files.map((file, index) => (
																			<div className="repeat" key={index}>
																				<p>{getFileName(file.name)}</p>
																				<button type="button" title="Delete" onClick={() => deleteFile(index)}>
																					<LuTrash />
																				</button>
																			</div>
																		))}
																</div>
															</div>
														</div>
													</div>
												)}
												{selectedTab === "text" && (
													<div className="commonDiv">
														<div className="titleBar">
															{/* <h3>Text Instructions  <span className="danger">*</span> </h3> */}
															<h3>Text Instructions</h3>
														</div>
														<div className="content instructions">
															<textarea
																placeholder="Please Enter the Instructions"
																name="Instructions" className="form-control" onChange={(event) => updateForm(event.target)}
																value={createItemForm.Instructions}
																maxLength={20000}
															/>
														</div>
														<div className="d-flex p-4 pt-0">
															{/* <p>Characters remaining: {20000 - getTotalLength(updateAssistant.Instructions,"text")}/{20000}</p> */}
															{getTotalLength(createItemForm.Instructions) > 19999 && (<div className="danger">Instructions cannot exceed 20000 characters.</div>)}
														</div>
													</div>
												)}
												{selectedTab === "website" && (
													<div className="commonDiv">
														<div className="titleBar">
															<h3>Train with websites</h3>
														</div>
														<div className="content">
															<div className="drag_drop_container">
																<div className="leftContainer">
																	<div className="formContainer">
																		<label>Website URL</label>
																		<div className="inputHolder">
																			<input
																				placeholder="https://www.example.com"
																				className="form-control"
																				name="Websites"
																				type="text"
																				onChange={(event) => updateWebsiteList(event.target)}
																				value={selectedURL}
																			/>
																			<button className="icon" type="button" onClick={() => onWebsitesCheckClick()} > <FaCircleCheck /> </button>
																		</div>
																	</div>
																</div>
																<div className="rightContainer">
																	{createItemForm.Websites.length > 0 &&
																		createItemForm.Websites.map((website, index) => (
																			<div className="repeat" key={website.Id}>
																				<p>{website.Url}</p>
																				<button type="button" title="Delete" onClick={() => deleteURL(index)}>
																					<LuTrash />
																				</button>
																			</div>
																		))}
																</div>
															</div>
														</div>
													</div>

												)}
												{selectedTab === "qna" && (
													<div className="commonDiv">
														<div className="titleBar d-flex align-items-center">
															<h3>FAQ</h3>
															<div className="ms-auto">
																<div className="d-flex gap-2">
																	<button
																		className="btn plainBtn"
																		type="button"
																		title="Upload FAQ"
																		onClick={() => viewUpload()}
																	>
																		<LuUpload />
																	</button>
																	<button
																		className="btn plainBtn"
																		type="button"
																		title="Add New FAQ"
																		onClick={() => addNewQandA()}
																	>
																		<LuPlus />
																	</button>
																</div>

															</div>

														</div>
														{showUplodPopup && (
															<div className="popup-container small-popup">
																<div className="shadeClose" onClick={() => {
																	hideUplodPopup();
																}}></div>
																<div className="popup-inner">
																	<div className="inner-design">
																		<div className="popup-header">
																			<h3>Upload FAQ File</h3>
																			<button
																				type="button"
																				onClick={() => {
																					hideUplodPopup();
																				}}
																				className="close"
																			><FaTimes /></button>
																		</div>

																		<div className="popup-content">
																			<form >
																				<div className="col-12 col-md-12 formHolder nameHolder mb-4">
																					<label className="form-label">
																						Name <span className="danger">*</span>
																					</label>
																					<input
																						type="file"
																						placeholder="Enter the name"
																						name="Name"
																						className="form-control"


																						accept={".csv"}
																						onChange={(e) => onChangeQAFile(e)}

																					/>
																				</div>
																				<div className="d-flex gap-2 mt-2">
																					<button type="submit" className="btn btn-primary small" onClick={(e) => {
																						uploadQAFile(e);
																					}} disabled={IsQAFileButton}>
																						Upload
																					</button>
																					<button
																						type="submit"
																						className="btn btn-secondary small"
																						onClick={() => {
																							hideUplodPopup();
																						}}
																					>
																						Cancel
																					</button>

																				</div>
																			</form>
																		</div>
																	</div>
																</div>
															</div>
														)}
														<div className="content qaContent">
															<div className="formContainer">
																{(showAddButtonQA === false || createItemForm.QandAList.length === 0) && (
																	<div className="repeatDiv mb-3">
																		<div className="qstnHolder mb-2">
																			<input
																				type="text"
																				placeholder="Please enter the Question"
																				name="Question"
																				className="form-control"
																				onChange={(event) => handleQandACreate(event.target)}
																			/>
																			<div className="btnHolder">
																				{selectedQuestion !== "" && selectedAnswer !== "" && (
																					<button
																						type="button"
																						className="btn plainBtn sbmtBtn"
																						title="Create Save"
																						onClick={() => createQandA()}
																					>
																						<LuCheck />
																					</button>
																				)}
																			</div>
																		</div>

																		<div className="aswrHolder">
																			<textarea
																				className="form-control"
																				name="Answer"
																				placeholder="Enter the Answer"
																				onChange={(event) => handleQandACreate(event.target)}
																			/>
																		</div>
																	</div>
																)}
																<div className="accordionContainer">
																	{createItemForm.QandAList && createItemForm.QandAList.map((item: any, index: any) => (
																		<div key={item.Question} className="accordion-section repeatDiv mb-3">
																			<div className={`qstnHolder mb-2 accordion-header ${index === openSection ? 'open' : ''}`}>
																				<input
																					type="text"
																					placeholder="Please enter the Question"
																					name="Question"
																					className="form-control"
																					defaultValue={item.Question}
																					disabled={!isItemDisabledQA[index]}
																					onChange={(e) => handleQandAUpdate(e, index)}
																				/>
																				<div className="btnHolder">
																					{(!isItemDisabledQA[index]) && (
																						<button
																							type="button"
																							className="btn plainBtn editBtn"
																							title="Edit"
																							onClick={() => enableSelectedQandA(index)}
																							disabled={editingIndexQA !== null && editingIndexQA !== index}
																						>
																							<LuPen />
																						</button>
																					)}

																					{isItemDisabledQA[index] && (
																						<button
																							type="button"
																							className="btn plainBtn sbmtBtn"
																							title="Edit Save"
																							onClick={() => updateQandAChange({ target: { value: editedQuestion } } as any, index)}
																						>
																							<LuCheck />
																						</button>
																					)}

																					<button
																						type="button"
																						className="btn plainBtn deleteBtn"
																						title="Delete"
																						onClick={() => deleteQandA(index)}
																					>
																						<LuTrash />
																					</button>

																				</div>
																				<div className="accordionTrgr" onClick={() => handleSectionClick(index)}>
																					<LuChevronDown />
																				</div>
																			</div>
																			{index === openSection && (
																				<div className="accordion-content aswrHolder">
																					<textarea
																						className="form-control"
																						name="Answer"
																						placeholder="Enter the Answer"
																						disabled={!isItemDisabledQA[index]}
																						defaultValue={item.Answer}
																						onChange={(e) => handleQandAUpdate(e, index)}
																					/>
																				</div>
																			)}
																		</div>
																	))}
																</div>

																{/* {createItemForm.QandAList.length > 0 && createItemForm.QandAList.map((item, index) => (
																	<div className="repeatDiv mb-3" key={item.Question}>
																		<div className="qstnHolder mb-2">
																			<input
																				type="text"
																				placeholder="Please enter the Question"
																				name="Question"
																				className="form-control"
																				defaultValue={item.Question}
																				disabled={!isItemDisabledQA[index]}
																				onChange={(e) => handleQandAUpdate(e, index)}
																			/>
																			<div className="btnHolder">
																				{(!isItemDisabledQA[index]) && (
																					<button
																						type="button"
																						className="btn plainBtn editBtn"
																						title="Edit"
																						onClick={() => enableSelectedQandA(index)}
																						disabled={editingIndexQA !== null && editingIndexQA !== index}
																					>
																						<LuPen />
																					</button>
																				)}

																				{isItemDisabledQA[index] && (
																					<button
																						type="button"
																						className="btn plainBtn sbmtBtn"
																						title="Edit Save"
																						onClick={() => updateQandAChange({ target: { value: editedQuestion } } as any, index)}
																					>
																						<LuCheck />
																					</button>
																				)}

																				<button
																					type="button"
																					className="btn plainBtn deleteBtn"
																					title="Delete"
																					onClick={() => deleteQandA(index)}
																				>
																					<LuTrash />
																				</button>

																			</div>
																		</div>
																		<div className="aswrHolder">
																			<textarea
																				className="form-control"
																				name="Answer"
																				disabled={!isItemDisabledQA[index]}
																				placeholder="Enter the Answer"
																				defaultValue={item.Answer}
																				onChange={(e) => handleQandAUpdate(e, index)}
																			/>
																		</div>
																	</div>
																))} */}


															</div>
														</div>
													</div>
												)}
												{/* {selectedTab === "ds" && (
													<div className="commonDiv">
														<div className="titleBar d-flex align-items-center">
															<h3>Data Source</h3>
														</div>

														<div className="content" style={{ maxHeight: "400px", overflowY: "auto" }}>
															<p>Coming Soon</p>
														</div>
													</div>)} */}
												{selectedTab === "ds" && (
													<div className="commonDiv">
														<div className="titleBar d-flex align-items-center">
															<div className="content">
																<div className="row">
																	<div className="">
																		<input
																			type="text"
																			placeholder="Connection string"
																			name="ConnectionString" className="form-control"
																			onChange={(e) => changeDataSource(e)}
																		//  value={updateAssistant.dataSources}
																		/>

																	</div>
																</div>
															</div>
														</div>
													</div>
												)}


											</div>
										</div>
									</div>
									<div className="col-12 col-md-3 statusHolder">
										<div className="topDiv d-flex">
											<div className="icon"><PiThumbsUpLight /></div>
											<div className="statusInfo">
												<h4>Yes!</h4>
												<p className="mb-3">You are set to create your agent.</p>
												<ul className="mb-3">
													<li>
														<span className="iconSpan"><LuCheck /></span>  <span><strong>{filesCount}</strong> Files</span>
													</li>
													<li>
														<span className="iconSpan"><LuCheck /></span> <span><strong>{charCount}</strong> Text input chars</span>
													</li>
													<li>
														<span className="iconSpan"><LuCheck /></span> <span> <strong>{websiteCount}</strong> Websites</span>
													</li>
													<li>
														<span className="iconSpan"><LuCheck /></span> <span> <strong>{qaCount}</strong> FAQ</span>
													</li>
													<li>
														<span className="iconSpan"><LuCheck /></span> <span> <strong>{dsCount}</strong> Data Source</span>
													</li>
												</ul>
												<button type="submit" className="btn btn-primary small" disabled={disableSubmitButton.current}>Create Agent</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div >
			) : ''}
			<ThreeDotLoader visibility={showLoader}></ThreeDotLoader>

		</div >
	);
};

export default CreateAgent;
