import React, { ReactNode, SetStateAction } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { LuEye } from "react-icons/lu";
import { getFileSize, getTrainingStatus } from "../Utils/Utils";
import './Summary.scss'
import StatusLabel from "../StatusLabel/StatusLabel";
interface AccordionItemProps {
    Data: any
    openTab: string | null;
    setOpenTab: React.Dispatch<SetStateAction<string | null>>
}

const AccordionFSItem: React.FC<AccordionItemProps> = ({ Data, openTab, setOpenTab, }) => {

    const columns: TableColumn<any>[] = [
        {
            name: "File Name",
            selector: (rows: any) => rows.name,
            width: "300px",
        },
        {
            name: "Size",
            // selector: (rows: any) => getFileSize(rows),
            cell: (row: any, rowIndex: any) => {
                let size = getFileSize(row)
                return (
                    size
                )
            }
        },
        {
            name: "Upload Status",
            //   selector: (rows: any) => 
            cell: (row: any, rowIndex: any) => {
                let status = getTrainingStatus(row) == 0 ? "Not Uploaded" : "Done"
                return (
                    status
                )
            }
        },
        {
            name: "Trained Status",
            // selector: (rows: any) => rows.trainingStatus,
            cell: (row: any, rowIndex: any) => {
                let status = getTrainingStatus(row)
                return (
                    <>
                    <StatusLabel labelId={status} label={ row.trainingStatus}/>  
                    {/* { row.trainingStatus} */}
                    </>

                   
                )
            }
        },
        {
            name: "",
            right: true,
            cell: (row: any, rowIndex: any) => (

                <div>
                    <button className="btn icon-btn" title="View">
                        <LuEye />
                    </button>
                </div>

            )
        }
    ];
    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={() => setOpenTab((preVal) => preVal === "Files" ? null : "Files")}>
                {`Files (${Data.length || 0})`}
                <span className={`arrow ${openTab === "Files" ? "rotate" : ""}`}>
                    <svg width="14.828" height="8.414" viewBox="0 0 14.828 8.414">
                        <path
                            id="Path_491"
                            data-name="Path 491"
                            d="M6,9l6,6,6-6"
                            transform="translate(-4.586 -7.586)"
                            fill="none"
                            stroke="#615070"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                        />
                    </svg>
                </span>
            </div>
            <div className={`accordion-content ${openTab === "Files" ? "show" : ""}`}>
                <div>
                    <DataTable
                        columns={columns}
                        data={Data || []}
                        fixedHeader
                    // pagination
                    // selectableRows
                    />
                </div>
            </div>
        </div>
    )
};
export default AccordionFSItem