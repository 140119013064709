import React, { useState, useEffect, useContext } from "react";
import { ThreeDotLoader } from "../../Loader/Loader";
import { LuCheck, LuDelete, LuPen, LuTrash2 } from "react-icons/lu";
import PromptService from "../../../Services/PromptService/PromptService";
import { toastError, toastSuccess, toastWarning } from "../../Toast/Toast";
import { confirmAlert } from "react-confirm-alert";
import { LuFileText } from "react-icons/lu";
import "./Prompt.scss";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import IPromptService from "../../../Services/PromptService/IPromptService";
import UserContext from "../../../Context/UserContext/UserContext";
import IUser from "../../../Services/UserManagement/IUserManagementService";
import { IPermissions } from "../../../Services/RoleManagement/IRoleManagementService";
import PermissionContext from "../../../Context/PermissionContext/PermissionContext";
import ReactPaginate from "react-paginate";
// import { permissionState } from "../../../Constants/constants";

interface Props {
  agentId: any;
}

const PromptList: React.FC<Props> = ({ agentId }) => {
  const navigate = useNavigate();

  const user = useContext<IUser>(UserContext);
  const permission = useContext<IPermissions>(PermissionContext);

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [promptsList, setPromptsList] = useState<IPromptService[]>([]);
  const [createNewKey, setCreateNewKey] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [showKeysFormPopup, setshowKeysFormPopup] = useState(false);
  const [pageSize, setPageSize] = useState(12)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [createItemForm, setCreateItemForm] = useState<IPromptService>({
    name: "",
    description: "",
    instruction: "",
  });

  useEffect(() => {
    // if(!permission.viewPrompt) return;
    getAllPrompts();
  }, [pageNumber]);

  const getAllPrompts = () => {
    setShowLoader(true);
    PromptService.GetAllPrompts(pageNumber, pageSize)
      .then((res) => {
        setPromptsList(res.response);
        setTotalCount(res.count ? res.count : 0)
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
  };

  const updateFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!validatePromptForm()) return;
    setShowLoader(true);
    setButtonDisable(true);
    PromptService.UpdatePrompt(createItemForm)
      .then((res) => {
        if (res.statusCode == 200) {
          toastSuccess("Prompt Updated Successfully");
          setShowLoader(false);
          setButtonDisable(false);
          setshowKeysFormPopup(false);
          getAllPrompts();
        }
      })
      .catch((error) => {
        toastError("Prompt Updated Error");
        console.log(error);
        setShowLoader(false);
        setButtonDisable(false);
      });
  };

  const handleDeletePrompt = (promptName: string) => {
    if (!permission.deletePrompt) return;
    confirmAlert({
      title: "Confirm Delete",
      message: "Do you want to delete the Prompt ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setShowLoader(true);
            PromptService.DeletePrompt(promptName)
              .then((res) => {
                if (res.statusCode == 200) {
                  setShowLoader(false);
                  toastSuccess("Prompt Deleted Successfully");
                  getAllPrompts();
                }
              })
              .catch((error) => {
                console.log(error);
                setShowLoader(false);
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const handlePageClick = (event: any) => {
    setPageNumber(event.selected + 1)
  };

  const showKeysEditFormPopup = (prompt: any) => {
    setshowKeysFormPopup(true);
    setButtonDisable(true);
    setCreateItemForm({
      name: prompt.name,
      description: prompt.description,
      instruction: prompt.instruction,
    });
  };

  const hideKeysFormPopup = () => {
    setshowKeysFormPopup(false);
    setCreateNewKey(false);
  };

  const updateForm = (target: any) => {
    let name = target.name;
    let val = target.value;
    setButtonDisable(false);
    setCreateItemForm({
      ...createItemForm,
      [name]: val,
    });
  };

  const validatePromptForm = () => {
    let flag = true;
    if (!createItemForm.name?.trim()) {
      toastError("Please Enter Prompt Name");
      flag = false;
    }
    if (!createItemForm.description?.trim()) {
      toastError("Please Enter Description");
      flag = false;
    }
    if (!createItemForm.instruction?.trim()) {
      toastError("Please Enter Instruction");
      flag = false;
    }
    return flag;
  };

  function getTotalLength(inputVal: any) {
    if (inputVal === null) {
      return 0;
    }
    return inputVal.length + (inputVal.match(/\n/g) || []).length;
  }

  const handleNavigate = () => {
    if (!permission.createPrompt) return;
    navigate("/Prompt/Create");
  };

  const handleChangePage = (event: any, page: number) => { };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => { };

  return (
    <>
      <div className="contentWrapper ">
        <div className="promtSelection">
          <div style={{ textAlign: "right" }}>
            <button
              className="btn btn btn-secondary ms-auto mb-3"
              type="button"
              onClick={handleNavigate}
            >
              Add Prompt
            </button>
          </div>

          <div className="row">
            {promptsList &&
              promptsList.map((prompt) => (
                <div
                  className="col-12 col-md-6 col-lg-4 mb-4 boxHolder"
                  key={prompt.id}
                >
                  <div className="box justify-content-between">
                    <div className="d-flex gap-3 title-bar align-items-center pe-3">
                      <div className="iconHolder">
                        <LuFileText />
                      </div>
                      <label className="form-check-label" htmlFor={prompt.name}>
                        {prompt.name}
                      </label>

                      <div className="icons">
                        <button
                          className="close primary-color"
                          title="Edit"
                          onClick={() => showKeysEditFormPopup(prompt)}
                        >
                          <LuPen />
                        </button>

                        <button
                          className="close"
                          title="Delete"
                          onClick={() =>
                            handleDeletePrompt(prompt.name ? prompt.name : "")
                          }
                        >
                          <LuTrash2 />
                        </button>
                      </div>
                    </div>
                    <p className="description">{prompt.description}</p>
                  </div>
                </div>
              ))}

            {promptsList?.length == 0 && (
              <>
                <div className="msg right-msg">
                  <div className="leftItems">
                    <div className="chatDesc">
                      <center>There are no records to display</center>
                    </div>
                  </div>
                </div>
              </>
            )}
            {totalCount > pageSize &&
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageCount={Math.ceil(totalCount / pageSize)}
                previousLabel="<"
                renderOnZeroPageCount={null}
              />
            }
          </div>
        </div>
      </div>

      {showKeysFormPopup && (
        <div className="popup-container">
          <div
            className="shadeClose"
            onClick={() => {
              hideKeysFormPopup();
            }}
          ></div>
          <div className="popup-inner">
            <div className="inner-design">
              <div className="popup-header">
                <h3>{createNewKey ? "Create New Prompt" : "Prompt Details"}</h3>
                <button
                  type="button"
                  onClick={() => {
                    hideKeysFormPopup();
                  }}
                  className="close"
                >
                  <FaTimes />
                </button>
              </div>

              <div className="popup-content">
                {/* <form onSubmit={fromUploadPage ? editForm : createForm}> */}
                <form onSubmit={updateFormSubmit}>
                  <div className="col-12 col-md-12 formHolder nameHolder mb-4">
                    <label className="form-label">
                      Prompt Name <span className="danger">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter the name"
                      name="name"
                      className="form-control"
                      onChange={(event) => updateForm(event.target)}
                      disabled={!createNewKey}
                      value={createItemForm.name}
                    />
                  </div>

                  <div className="col-12 col-md-12 formHolder keyHolder mb-4">
                    <label className="form-label">
                      Description <span className="danger">*</span>
                    </label>
                    <textarea
                      className="form-control height-80"
                      placeholder="Enter the Description"
                      name="description"
                      onChange={(event) => updateForm(event.target)}
                      value={createItemForm.description}
                      maxLength={100}
                    />
                    {getTotalLength(createItemForm.description) > 99 && (
                      <div className="danger">
                        Description cannot exceed 100 characters.
                      </div>
                    )}
                  </div>

                  {!createNewKey && (
                    <div>
                      <div className="col-12 col-md-12 formHolder keyHolder mb-4">
                        <label className="form-label">
                          Instruction<span className="danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Enter the Instruction"
                          name="instruction"
                          onChange={(event) => updateForm(event.target)}
                          value={createItemForm.instruction}
                          maxLength={500}
                        />
                        {getTotalLength(createItemForm.instruction) > 499 && (
                          <div className="danger">
                            Instructions cannot exceed 500 characters.
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="d-flex gap-2 mt-2">
                    <button
                      type="submit"
                      className="btn btn-secondary small"
                      onClick={() => {
                        hideKeysFormPopup();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary small"
                      disabled={buttonDisable}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
    </>
  );
};

export default PromptList;
