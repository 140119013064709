import React, { PropsWithChildren } from 'react'
import './StatusLabel.scss';
import { LuCheckCircle2, LuLoader, LuMinusCircle, LuPlus, LuXCircle } from 'react-icons/lu';




type IconType = () => JSX.Element | JSX.Element[]

export interface LabelProps {
  labelId: number;
  label: string;
}


const StatusLabel: React.FC<LabelProps> = ({labelId, label }) => {
  return (<>
   {/* // New 0, Not started 1, Training 2, complete 3, Failed 4  css make for four status */}
    {labelId == 0 ? (<>
      {<div className='started'>{label}<LuMinusCircle /></div>}
    </>) : labelId == 1 ? (<>
      {<div className='started'>{label}<LuMinusCircle /></div>}</>) : labelId == 2 ? (<>
        {<div className='training'>{label}<LuLoader /></div>}</>) : labelId == 3 ? (<>
          {<div className='complete'>{label}<LuCheckCircle2 /></div>}</>) : (<>
            {<div className='failed'>{label}<LuXCircle /></div>}</>)}

  </>)
}

export default StatusLabel