import React, { ReactEventHandler, useState } from "react";
import { LuMail, LuPhone, LuUser } from "react-icons/lu";
import BotService from "../../Services/BotServices/BotService";
import { toastError, toastSuccess } from "../Toast/Toast";
import { MdSend } from "react-icons/md";
import "./IframeUserDataForm.scss";
import { FaTimes } from "react-icons/fa";

interface Props {
  SetshowAgentUserForm: React.Dispatch<React.SetStateAction<boolean>>;
  APIKey?: string;
  AssistantId?: string;
  SessionId?: string;
}

const IFrameUserDataForm: React.FC<Props> = ({
  SetshowAgentUserForm,
  APIKey,
  AssistantId,
  SessionId,
}) => {
  let botService: BotService;
  const USERDATA_INITIAL_OBJ = {
    name: "",
    email: "",
    phone: "",
    assistant: AssistantId ? AssistantId : "",
    session: SessionId ? SessionId : "",
  };
  const [userData, setUserData] = useState(USERDATA_INITIAL_OBJ);

  const handlchangeFormVal = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    let name = event.target.name;
    if (name === "name") {
			value = value.replace(/[^A-Za-z" "]/ig, '')
		}
		if (name === "phone") {
			value = value.replace(/\D/g, '')
		}
    setUserData((preVal) => ({ ...preVal, [name]: value }));
  };

  const validateUserData = () => {
    let flag = true;
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(userData.email.trim())) {
      toastError("Please enter Valid Email");
      flag = false;
    }
    if (!userData.name.trim()) {
      toastError("Please enter Name");
      flag = false;
    }
    if (!userData.phone.trim()) {
      toastError("Please enter phone number");
      flag = false;
    }
    return flag;
  };

  const submitUserDataForm = (e: any) => {
    e.preventDefault();
    if(!validateUserData()) return
    botService = new BotService(APIKey ? APIKey : "");
    botService
      .CreateUserContactDetails(userData)
      .then((Res) => {
        toastSuccess("Information submitted Successfully");
        setUserData(USERDATA_INITIAL_OBJ);
        SetshowAgentUserForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="formHolder mb-4">
        <div className="form-header">
          <h5>Let us know how to contact you</h5>
          <button type="button" className="close" onClick={() => SetshowAgentUserForm(false)}>
            <FaTimes />
          </button>
        </div>
        <div className="formHolder-row">
          <div className="input-group mb-4">
            <label htmlFor="name" className="form-label">
              <span>
                {" "}
                <LuUser />{" "}
              </span>
              Full Name
            </label>
            <div className="inputHolder">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Enter your full name"
                aria-label="username"
                value={userData.name}
                maxLength={20}
                onChange={(e) => handlchangeFormVal(e)}
              />
            </div>
          </div>
        </div>
        <div className="formHolder-row">
          <div className="input-group mb-4">
            <label htmlFor="Username" className="form-label">
              <span>
                <LuMail />
                {""}
              </span>
              Email
            </label>
            <div className="inputHolder">
              <input
                type="email"
                className="form-control"
                placeholder="Enter your email id"
                aria-label="email"
                name="email"
                value={userData.email}
                onChange={(e) => handlchangeFormVal(e)}
              />
            </div>
          </div>
        </div>
        <div className="formHolder-row">
          <div className="input-group mb-4">
            <label htmlFor="phone" className="form-label">
              <span>
                <LuPhone />
                {""}
              </span>
              Phone
            </label>
            <div className="inputHolder">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your email id"
                aria-label="phone"
                name="phone"
                onChange={(e) => handlchangeFormVal(e)}
                value={userData.phone}
                maxLength={20}
              />
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-secondary"
          onClick={(e) => submitUserDataForm(e)}
        >
          <MdSend />
        </button>
      </div>
    </>
  );
};
export default IFrameUserDataForm;
