import React, { SetStateAction, useEffect, useRef, useState } from "react";
import IAgentService from "../../Services/AgentServices/IAgentService";
import { FaCircleCheck } from "react-icons/fa6";
import { LuCheckCircle2, LuEye, LuLoader, LuMinusCircle, LuTrash, LuXCircle } from "react-icons/lu";
import { toastError, toastSuccess } from "../Toast/Toast";
import { confirmAlert } from "react-confirm-alert";
import { FaTimes } from "react-icons/fa";
import Accordion from "./Accordion/Accordion";
import AgentService from "../../Services/AgentServices/AgentService";
import { ThreeDotLoader } from "../Loader/Loader";
import { IWebsite, IWebsiteInAssistant } from "../../Services/AgentServices/IwebsiteService";
import { useSearchParams } from "react-router-dom";
import { extractWebSiteName, getTrainingStatus } from "../Utils/Utils";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import { useSelector } from "react-redux";
import TemplateService from "../../Services/TemplateService/TemplateService";

interface Props {
    UpdateAssistant: IAgentService
    SetUpdateAssistant: React.Dispatch<SetStateAction<IAgentService>>
    DisableSubmitButton: any
    TemplateId?: string
}

const Website: React.FC<Props> = ({ UpdateAssistant, SetUpdateAssistant, DisableSubmitButton, TemplateId }) => {

    const [selectedURL, setSelectedURL] = useState("");
    const [showLoader, setshowLoader] = useState(false);
    const [showForm, setshowForm] = useState(false);
    const [searchParams] = useSearchParams();
    const assId = searchParams.get('assistantId')
    const selectedAssistantId = useRef(assId ? assId : "");

    const [linksList, seLinksList] = useState<IWebsite[]>([])

    // useEffect(() => {
    //     if (selectedAssistantId.current) {
    //         getAssistantById()
    //     }
    // }, [])

    const getAssistantById = async () => {
        setshowLoader(true);
        AgentService.UpdateAssistant(selectedAssistantId.current, UpdateAssistant).then(res => {
            AgentService.GetAssistantById(selectedAssistantId.current)
                .then((res) => {
                    const data: IAgentService = res.response;
                    SetUpdateAssistant((preVal) => ({
                        ...data,
                    }));
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setshowLoader(false);

                });

        }).finally(() => {
            setshowLoader(false);

        });

    };
    const getTemplateById = async () => {
        setshowLoader(true);
        TemplateService.UpdateTemplate(UpdateAssistant, TemplateId ? TemplateId : '').then(res => {
            TemplateService.GetTemplateById(TemplateId ? TemplateId : '')
                .then((res) => {
                    const data: IAgentService = res.response;
                    SetUpdateAssistant((preVal) => ({
                        ...data,
                    }));
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setshowLoader(false);

                });
        }).finally(() => {
            setshowLoader(false);

        });

    };
    const updateWebsiteList = (target: any) => {
        let val = target.value;
        setSelectedURL(val);
    };

    const onWebsitesCheckClick = () => {
        if (selectedURL) {
            const urlRegex =
                /^(https?:\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
            const wwwPattern = /www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?/;

            const existingWebsits = UpdateAssistant.website?.map(item => extractWebSiteName(item.url)) || []
            if (existingWebsits.includes(extractWebSiteName(selectedURL))) {
                toastError("Duplicate websites are not allowed");
                return
            }
            if (urlRegex.test(selectedURL.trim())) {
                setshowLoader(true)
                AgentService.GetWebSiteLinks(selectedURL).then(res => {
                    seLinksList(res.response.urls)
                    setshowForm(true);

                }).catch(err => {
                    toastError(err?.response?.data?.response)
                }).finally(() => {
                    setshowLoader(false)
                })
                // SetUpdateAssistant((prevState) => {
                //     const updatedWebsites = [...(prevState.website || []), selectedURL];
                //     // const sortedWebsites = updatedWebsites.sort((a: any, b: any) => (a.Id > b.Id ? -1 : string(a.Id < b.Id)))
                //     return { ...prevState, website: updatedWebsites };
                // });
                DisableSubmitButton.current = false;

            } else {
                toastError("Please enter a valid URL");
            }
        } else {
            toastError("Please enter a valid URL");
        }
    };
    const websiteDeleteconfirm = (website: string) => {
        confirmAlert({
            title: "Confirm Delete",
            message: "Do you want to delete the website?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteURL(website),
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    };

    const validateUrlData = () => {
        var flag = true
        const selection = linksList.map(items => items.selection)
        if (!selection.includes(true)) {
            toastError("Select at least one link")
            flag = false
        }
        return flag
    }

    const deleteURL = (website: string) => {
        setshowLoader(true)
        if (selectedAssistantId.current) {
            AgentService.DeleteWebSite(website, selectedAssistantId.current ? selectedAssistantId.current : "").then(res => {
                toastSuccess(res.response)
                getAssistantById()
            }).then((err: any) => {
                toastError(err?.response?.data?.response)
            }).finally(() => {
                setshowLoader(false)

            })
        } else if (TemplateId) {
            if (selectedAssistantId.current) {
                TemplateService.DeleteWebSite(website, selectedAssistantId.current ? selectedAssistantId.current : "").then(res => {
                    toastSuccess(res)
                    getTemplateById()
                }).then((err: any) => {
                    toastError(err?.response?.data?.response)
                }).finally(() => {
                    setshowLoader(false)

                })
            }
        }

    }


    const showSelectionForm = (url: string) => {
        setshowLoader(true)
        setSelectedURL(url);
        if (selectedAssistantId.current) {
            AgentService.GetAssistantWebSite(url, selectedAssistantId.current ? selectedAssistantId.current : "").then(res => {
                seLinksList(res.response.urls)
                setshowForm(true);

            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setshowLoader(false)
            })
        } else if (TemplateId) {
            TemplateService.GetTemplateWebSite(url, selectedAssistantId.current ? selectedAssistantId.current : "").then(res => {
                seLinksList(res.response.urls)
                setshowForm(true);

            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setshowLoader(false)
            })
        }

    }
    const submitUrlData = () => {
        if (!validateUrlData()) return;
        if (selectedAssistantId.current) {
            setshowLoader(true)
            AgentService.UpdateAssistantWebSiteUpdate(selectedURL, linksList, selectedAssistantId.current).then(res => {
                toastSuccess("Website Added Successfully")
                getAssistantById()
                closeEosPopup()

            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setshowLoader(false)

            })
        } else if (TemplateId) {
            TemplateService.UpdateTemplateWebSiteUpdate(selectedURL, linksList, TemplateId).then(res => {
                toastSuccess("Website Added Successfully")
                getTemplateById()
                closeEosPopup()

            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setshowLoader(false)

            })
        }

    }

    const closeEosPopup = () => {
        setshowForm(false);
        setSelectedURL("")
        // getComapanyInfo()

    };

    const accordionItems = [
        {
            title: 'Home',
            content: '',
        },
        {
            title: 'Services',
            content: '',
            subItems: [
                {
                    title: 'Software Engineering',
                    content: '',
                    subItems: [
                        {
                            title: 'Digital Transformation',
                            content: 'Digital Transformation sub',
                        },
                        {
                            title: 'Application Development',
                            content: 'Application Development sub',
                        },
                        {
                            title: 'Data Solution',
                            content: 'Data Solution sub',
                        }
                    ]
                },
                {
                    title: 'Engineering Services',
                    content: 'Content for subsection 1.2'
                }
            ]
        },

    ];



    return (<>
        <div className="commonDiv">
            <div className="titleBar">
                <h3>Train with websites  <span>({UpdateAssistant.website?.length})</span></h3>
            </div>
            <div className="content">
                <div className="drag_drop_container">
                    <div className="leftContainer">
                        <div className="formContainer">
                            <label>Website URL</label>
                            <div className="inputHolder">
                                <input
                                    placeholder="https://www.example.com"
                                    className="form-control"
                                    name="website"
                                    type="text"
                                    onChange={(event) => updateWebsiteList(event.target)}
                                    value={selectedURL}
                                />
                                <button className="icon" type="button" onClick={() => onWebsitesCheckClick()} > <FaCircleCheck /> </button>
                            </div>
                        </div>
                    </div>
                    <div className="rightContainer">
                        {
                            UpdateAssistant.website?.map((website: IWebsiteInAssistant, index: any) => {
                                const trainedStatus = getTrainingStatus(website)

                                return (

                                    
                                        <div className={trainedStatus == 0 || trainedStatus == 1 ? "repeat new" :
                                            trainedStatus == 2 ? "repeat inprogress" :
                                                trainedStatus == 3 ? "repeat completed" : "repeat failed"
                                        } key={index}>
                                            {/*For only development(inprogress,completed,failed) */}
                                            <div className="file-name website">
                                                <div className="status">
                                                    {trainedStatus == 0 || trainedStatus == 1 ? <LuLoader /> :
                                                        trainedStatus == 2 ? <IoCheckmarkCircle /> :
                                                            trainedStatus == 3 ? <IoCheckmarkCircle /> :
                                                                <IoCloseCircle />}
                                                </div>
                                                <p title={website.url}>{website.url}</p>
                                            </div>

                                            <div className="file-action">
                                                <div className="trained-label">
                                                    {<><label>{trainedStatus == 0 ? "New" : website.trainingStatus}</label>
                                                        {trainedStatus == 0 ? <LuMinusCircle /> :
                                                            trainedStatus == 1 ? <LuCheckCircle2 /> :
                                                                trainedStatus == 2 ? <LuCheckCircle2 /> :
                                                                    trainedStatus == 3 ? <LuCheckCircle2 /> : <LuXCircle />
                                                        }
                                                    </>}
                                                </div>
                                                <button type="button" title="View" onClick={() => { showSelectionForm(website.url) }}>
                                                    <LuEye />
                                                </button>
                                                <button type="button" title="Delete" onClick={() => websiteDeleteconfirm(website.url)}>
                                                    <LuTrash />
                                                </button>
                                            </div>
                                        </div>
                                    
                                )
                            })}

                        {showForm && (
                            <div className="popup-container medium-template-popup dashboard-popup">
                                <div className="shadeClose" onClick={() => { closeEosPopup(); }}></div>
                                <div className="popup-inner">
                                    <div className="inner-design">
                                        <div className="popup-header">
                                            <h3>{selectedURL}</h3>
                                            <button type="button" onClick={() => { closeEosPopup(); }} className="close"><FaTimes /></button>
                                        </div>

                                        <div className="popup-content">
                                            <Accordion UrlData={linksList} SetUrlData={seLinksList} SubmitUrlData={submitUrlData} CloseEosPopup={closeEosPopup} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>

    </>)
}


export default Website