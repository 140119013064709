// Base url Python

export const AUTH_BASEURL: string = process.env.REACT_APP_AUTH_BASE_URL ?? ''
export const META_BASEURL: string = process.env.REACT_APP_META_BASE_URL ?? ''
export const CHAT_BASEURL: string = process.env.REACT_APP_CHAT_BASE_URL ?? ''
export const SCRAP_BASEURL: string = process.env.REACT_APP_SCRAP_BASE_URL ?? ''

// Users python

export const CREATE_USER: string = AUTH_BASEURL + process.env.REACT_APP_CREATE_USER ?? ''
export const INTERNAL_LOGIN: string = AUTH_BASEURL + process.env.REACT_APP_INTERNAL_LOGIN ?? ''
export const FORGOT_PASSWORD: string = AUTH_BASEURL + process.env.REACT_APP_FORGOT_PASSWORD ?? ''
export const RESET_PASSWORD: string = AUTH_BASEURL + process.env.REACT_APP_RESET_PASSWORD ?? ''
export const LIST_USERS: string = AUTH_BASEURL + process.env.REACT_APP_USERS ?? ''
export const UPDATE_USER: string = AUTH_BASEURL + process.env.REACT_APP_USERS ?? ''
export const DELETE_USER: string = AUTH_BASEURL + process.env.REACT_APP_USERS ?? ''
export const NEW_USERS: string = AUTH_BASEURL + process.env.REACT_APP_NEW_USERS ?? ''
export const USER_SELF_INFO: string = AUTH_BASEURL + process.env.REACT_APP_USER_SELF_INFO ?? ''

// Assistant Database
export const GET_DATABASE_TABLES: string = CHAT_BASEURL + process.env.REACT_APP_GET_DATABASE_TABLES ?? ''


// Assistant Python

export const CREATEASSISTANT: string = META_BASEURL + process.env.REACT_APP_ASSISTANT ?? ''
export const GET_ASSISTANT_BY_ID: string = META_BASEURL + process.env.REACT_APP_ASSISTANT ?? ''
export const GET_ASSISTANT_LIST: string = META_BASEURL + process.env.REACT_APP_ASSISTANT ?? ''
export const ASSISTANT_WEBSITE: string = META_BASEURL + process.env.REACT_APP_ASSISTANT ?? ''
export const DELETEASSISTANT: string = META_BASEURL + process.env.REACT_APP_ASSISTANT ?? ''
export const UPDATEASSISTANT: string = META_BASEURL + process.env.REACT_APP_ASSISTANT ?? ''
export const ASSISTANT_FILE_UPLOAD: string = META_BASEURL + process.env.REACT_APP_ASSISTANT ?? ''
export const ASSISTANT_FILE_DOWNLOAD: string = META_BASEURL + process.env.REACT_APP_ASSISTANT ?? ''
export const ASSISTANT_ICON_UPLOAD: string = META_BASEURL + process.env.REACT_APP_ASSISTANT ?? ''
export const GET_MODELS: string = META_BASEURL + process.env.REACT_APP_GET_MODELS ?? ''


export const GETASSISTANTLIST: string = META_BASEURL ?? ''
export const CONTACT_DETAILS: string = META_BASEURL + process.env.REACT_APP_CONTACT_DETAILS ?? ''
export const GET_ASSISTANT_FILES: string = CHAT_BASEURL + process.env.REACT_APP_ASSISTANT_FILE_LIST ?? ''
export const DELETE_ASSISTANT_FILES: string = CHAT_BASEURL + process.env.REACT_APP_ASSISTANT_FILE_DELETE ?? ''


// Chatbot python 

export const SENDQUESTION: string = CHAT_BASEURL + process.env.REACT_APP_SEND_QUESTION ?? ''
export const SCRAP_WEBSITE: string = SCRAP_BASEURL + process.env.REACT_APP_SCRAP_WEBSITE ?? ''



// Groups python 

export const CREATE_GROUPS: string = META_BASEURL + process.env.REACT_APP_GROUPS ?? ''
export const LIST_GROUPS: string = META_BASEURL + process.env.REACT_APP_GROUPS ?? ''
export const DELETE_GROUPS: string = META_BASEURL + process.env.REACT_APP_GROUPS ?? ''
export const UPLOAD_GROUPS_ICON: string = META_BASEURL + process.env.REACT_APP_GROUPS ?? ''

// Prompts python
export const CREATE_PROMPTS: string = META_BASEURL + process.env.REACT_APP_PROMPTS ?? ''
export const LIST_PROMPTS: string = META_BASEURL + process.env.REACT_APP_PROMPTS ?? ''
export const UPDATE_PROMPTS: string = META_BASEURL + process.env.REACT_APP_PROMPTS ?? ''
export const DELETE_PROMPTS: string = META_BASEURL + process.env.REACT_APP_PROMPTS ?? ''

// Template python
export const CREATE_TEMPLATE: string = META_BASEURL + process.env.REACT_APP_TEMPLATE ?? ''
export const UPDATE_TEMPLATE: string = META_BASEURL + process.env.REACT_APP_TEMPLATE ?? ''
export const LIST_TEMPLATE: string = META_BASEURL + process.env.REACT_APP_TEMPLATE ?? ''
export const TEMPLATE_WEBSITE: string = META_BASEURL + process.env.REACT_APP_TEMPLATE ?? ''
export const DELETE_TEMPLATE: string = META_BASEURL + process.env.REACT_APP_TEMPLATE ?? ''
export const TEMPLATE_FILE_UPLOAD: string = META_BASEURL + process.env.REACT_APP_TEMPLATE ?? ''
export const GET_TEMPLATE_BY_ID: string = META_BASEURL + process.env.REACT_APP_TEMPLATE ?? ''
export const ASSISTANT_AS_TEMPLATE: string = META_BASEURL + process.env.REACT_APP_ASSISTANT_AS_TEMPLATE ?? ''


// Chat log 

export const CHAT_LOG: string = META_BASEURL + process.env.REACT_APP_ASSISTANT ?? ''
export const DELETE_SESSION: string = META_BASEURL + process.env.REACT_APP_ASSISTANT ?? ''

// DasBoard

export const CHART_DATA: string = META_BASEURL + process.env.REACT_APP_DASHHBOARD ?? ''
export const RC_ACTIVIRIES: string = META_BASEURL + process.env.REACT_APP_RC_ACTIVITIES ?? ''

// Api Key

export const API_KEY: string = META_BASEURL + process.env.REACT_APP_API_KEY ?? ''

// Client

export const CLIENT: string = META_BASEURL + process.env.REACT_APP_CLIENT ?? ''
export const CLIENT_LOGO: string = META_BASEURL + process.env.REACT_APP_CLIENT_LOGO ?? ''

//  Roles
export const ROLES: string = META_BASEURL + process.env.REACT_APP_ROLES ?? ''
export const ROLE: string = META_BASEURL + process.env.REACT_APP_ROLE ?? ''


// settings

export const CREATE_MODELS: string = META_BASEURL + process.env.REACT_APP_MODELS ?? ''
export const UPDATE_MODELS: string = META_BASEURL + process.env.REACT_APP_MODELS ?? ''
export const DELETE_MODELS: string = META_BASEURL + process.env.REACT_APP_MODELS ?? ''
export const LIST_MODELS: string = META_BASEURL + process.env.REACT_APP_MODELS ?? ''

// Audit log

export const LIST_AUDITLOGS: string = META_BASEURL + process.env.REACT_APP_AUDIT_LOG?? ''

// Website

export const WEBSITE_LINKS: string = META_BASEURL + process.env.REACT_APP_WEBSITE_LINKS?? ''

// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const REDIRECT_URL: string = process.env.REACT_APP_MSAL_REDIRECT_URL ?? ''










