import { SAVE_COMPANY_NAME, DELETE_COMPANY_NAME } from "./ActionType"

const CompanyReducer = (data = "", action: any) => {
    switch (action.type) {
        case SAVE_COMPANY_NAME:
            return action.data
        case DELETE_COMPANY_NAME:
            return action.data
        default:
            return ""
    }

}
export default CompanyReducer