import TokenService from "../TokenService/TokenService";
import IResponse from "../IResponse";

import IRoleManagementService from "./IRoleManagementService";
import instance from "../AxiosInstance";
import { ROLE, ROLES } from "../EndPoint";


export default class RoleManagementService {

    public static GetAllRoles(): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(ROLES).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static GetRoleByName(roleName:string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(`${ROLE}?name=${roleName}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static CreateRole(roleObj:IRoleManagementService): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.post(ROLES,  JSON.stringify(roleObj)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static UpdateRole(roleObj:IRoleManagementService): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.put(ROLES,  JSON.stringify(roleObj)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static DeleteRole(roleObj:IRoleManagementService): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.delete(`${ROLES}?name=${roleObj.name}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
}