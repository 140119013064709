import TokenService from "../TokenService/TokenService";
import IResponse from "../IResponse";
import { CHART_DATA, RC_ACTIVIRIES, CLIENT, CLIENT_LOGO } from "../EndPoint"
import moment from 'moment';
import instance, { multiPartInstance } from "../AxiosInstance";
import ICompanyInfo from "./IDashboardService";


export default class DashboardService {
    public static GetChartData(): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(CHART_DATA).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static GetRecentActivities(): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(RC_ACTIVIRIES).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static CreateCompanyInfo(companyInfoObj: ICompanyInfo): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            let formData = new FormData()

            let result: ICompanyInfo = {}
            Object.keys(companyInfoObj).map(item => {
                if (item !== "logo") {
                    result[item as keyof ICompanyInfo] = companyInfoObj[item as keyof ICompanyInfo]
                }
            })
            instance.post(CLIENT, JSON.stringify(result)).then(res => {
                if (companyInfoObj.logo) {
                    formData.append('files', companyInfoObj.logo)
                    this.UploadCompanyLogo(formData).then(res => {
                        resolve(res)
                    })
                } else {
                    resolve(res.data)
                }
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static GetCompanyInfo(): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(CLIENT).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static GetCompanyLogo(): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(CLIENT_LOGO).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static UploadCompanyLogo(formData: FormData): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            multiPartInstance.post(CLIENT_LOGO, formData).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
}