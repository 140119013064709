import { DELETE_TEMPLATE_DATA, SAVE_TEMPLATE_DATA } from "./ActionType"

const templateReducer = (data = "", action: any) => {
    switch (action.type) {
        case SAVE_TEMPLATE_DATA:
            return action.data
        case DELETE_TEMPLATE_DATA:
            return action.data
        default:
            return data
    }

}
export default templateReducer