import { default as Cookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { isExpired,  } from "react-jwt";
import { PublicClientApplication, AuthenticationResult } from "@azure/msal-browser";
import { MSAL_AUTHORITY, MSAL_CLIENT_ID } from "../../Constants/constants";


// Initialize MSAL instance
const msalInstance = new PublicClientApplication({
	auth: {
		clientId: MSAL_CLIENT_ID,
		authority: MSAL_AUTHORITY,
	},
});

export default class TokenService {

	private static msalInstance: PublicClientApplication = msalInstance;

	public static initializeMsal(instance: PublicClientApplication) {
		TokenService.msalInstance = instance;
	}


	public static SetToken(token: string) {
		if (token && token.length > 0)
			localStorage.setItem("accessToken", token);
	}


	public static RemoveToken() {
		TokenService.RemoveUserData();
		return localStorage.removeItem("accessToken");
	}

	public static SetUserData(user: object) {
		if (user)
			localStorage.setItem("userData", JSON.stringify(user))

	}
	public static GetUserData(): any {
		return localStorage.getItem("userData");

	}
	public static GetIsExpired() {
		return localStorage.getItem("IsExpired");

	}
	public static setIsExpired(IsExpired: string) {
		localStorage.set("isExpired", IsExpired);
	}

	public static RemoveUserData() {
		return localStorage.removeItem("userData");
	}


	public static GetToken() {
		var token = localStorage.getItem("accessToken");
		if (token) {
			TokenService.ValidateToken(token);
		}
		return localStorage.getItem("accessToken");
	}


	public static async ValidateToken(token: string) {
		// console.log("current-token", token)
		if (!TokenService.msalInstance) {
			console.error("MSAL instance not initialized. Call initializeMsal before using ValidateToken.");
			return;
		}

		const REFRESH_THRESHOLD = 300;
		try {
			const decodeToken = jwtDecode(token);
			const currentTime = Math.floor(Date.now() / 1000);
			const timeUntilExpiry = (decodeToken.exp ? decodeToken.exp : currentTime) - currentTime;

			if (timeUntilExpiry <= REFRESH_THRESHOLD) {
				if (decodeToken.iss !== "local_authority") {

					const account = msalInstance.getAllAccounts()[0];
					const silentRequest = {
						scopes: ["7f971c6d-abbe-43b3-9e49-45f1f9ac85a0/.default"],
						account: account
					};

					msalInstance.initialize();

					const response = await msalInstance.acquireTokenSilent(silentRequest);

					const newToken = response.accessToken;
                    
                    console.log("newToken", newToken);
                    const user: any = jwtDecode(token);
                    TokenService.SetUserData(user);
					TokenService.SetToken(newToken);
				}
			}
		} catch (error) {
			console.error("Error decoding or refreshing token:", error);
		}
	}
}


