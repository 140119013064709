import React, { useEffect, useState } from "react";
import IAgentService from "../../../../Services/AgentServices/IAgentService";
import "./IframeFileUploadOption.scss"


interface Props {
    SetUpdateAssistant: React.Dispatch<React.SetStateAction<IAgentService>>
    UpdateAssistant: IAgentService
    SetDisableButton: React.Dispatch<React.SetStateAction<boolean>>
}
const IframeFileUploadOption: React.FC<Props> = ({ SetUpdateAssistant, UpdateAssistant, SetDisableButton }) => {
    const [uploadFileIframe, setUploadFileIframe] = useState<boolean>()

    const queryTypeChanage = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let value = Number(e.target.value)
        let name = e.target.name
        setUploadFileIframe(Boolean(value))
        SetDisableButton(false)
        SetUpdateAssistant((preVal) => ({
            ...preVal,
            [name]: Boolean(value)
        }))

    }
    useEffect(() => {
        setUploadFileIframe(UpdateAssistant.uploadFileIframe)
    }, [UpdateAssistant])

    return (<>
        <div className="contact-form-holder"></div>
        <h2 className="mb-3">Allow Iframe FileUpload</h2>
        <div className="contact-form-listing">
            <div className="contact-form">
                <select className="form-select form-select-lg mb-3" onChange={(e) => queryTypeChanage(e)} value={Number(uploadFileIframe)} name="uploadFileIframe">
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                </select>
            </div>
        </div>
    </>)
}
export default IframeFileUploadOption