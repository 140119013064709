import React, { useEffect, useState } from "react";
import { ASSISTANT_INITIAL_OBJ } from "../../../Constants/constants";
import IAgentService from "../../../Services/AgentServices/IAgentService";
import AgentService from "../../../Services/AgentServices/AgentService";
import { useSearchParams } from "react-router-dom";
import { ThreeDotLoader } from "../../Loader/Loader";
import { toastSuccess } from "../../Toast/Toast";
import ChatOption from "./ChatOption/ChatOption";
import ContactFormOption from "./UserDataFormConfig/ContactFormOption";
import IframeFileUploadOption from "./IframeFileUploadOption/IframeFileUploadOption";


interface Props {
    SetSelectedMainTab: React.Dispatch<React.SetStateAction<string>>
}

const Preferences: React.FC<Props> = ({ SetSelectedMainTab }) => {
    const [updateAssistant, setUpdateAssistant] = useState<IAgentService>({
        ...ASSISTANT_INITIAL_OBJ
    });
    const [showLoader, setShowLoader] = useState(false);
    const [disableButton, setDisableButton] = useState(true)

    const [searchParams] = useSearchParams();
    const assId = searchParams.get('assistantId')
    const assistantId = React.useRef(assId ? assId : '');

    useEffect(() => {
        getAssistantById()
    }, [])


    const getAssistantById = () => {
        setShowLoader(true)
        AgentService.GetAssistantById(assistantId.current).then((res) => {
            let data = res.response
            setUpdateAssistant((preVal) => ({
                ...data
            }))
            // setChatOptions(data.chatOption?.option)
            // setChatType(data.chatOption?.RagType)
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setShowLoader(false)
        })
    }

    const handleApply = () => {
        setShowLoader(true)
        AgentService.UpdateAssistant(assistantId.current, updateAssistant).then(res => {
            // SetSelectedMainTab("chatOption")
            toastSuccess("Assistant updated Successfully")
            setDisableButton(true)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setShowLoader(false)
        })


    };
    const nextPrevious = (tab: any) => {
        if (tab === "Previous") {
            SetSelectedMainTab("chatbot")
        } else {
            SetSelectedMainTab("chatOption")
        }
    }

    return (<>
        <div className="Chat-otion">
        <ChatOption SetUpdateAssistant={setUpdateAssistant} UpdateAssistant={updateAssistant} SetDisableButton={setDisableButton}/>

        </div>
        <div className="contact-form-otion">
        <ContactFormOption SetUpdateAssistant={setUpdateAssistant} UpdateAssistant={updateAssistant} SetDisableButton={setDisableButton}/>

        </div>
        <div className="contact-form-otion">
        <IframeFileUploadOption SetUpdateAssistant={setUpdateAssistant} UpdateAssistant={updateAssistant} SetDisableButton={setDisableButton}/>

        </div>
        <div className="mt-2 btnHolder d-flex gap-3">
            <button type="button" className="btn btn-secondary ms-auto" onClick={() => { nextPrevious("Previous"); }} >Previous</button>
            <button className="btn btn-primary" type="submit" onClick={handleApply} disabled={disableButton}>Save</button>
        </div>
        <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
    </>)
}
export default Preferences