import React, { useState, useEffect, useContext } from "react";
import { ThreeDotLoader } from "../../Loader/Loader";
import { toastError, toastSuccess, toastWarning } from "../../Toast/Toast";
import { LuCheck } from "react-icons/lu";
import { confirmAlert } from "react-confirm-alert";
import { LuFileText } from "react-icons/lu";
import { PiThumbsUpLight } from "react-icons/pi";
import { FaTimes } from "react-icons/fa";
import IAgentService from "../../../Services/AgentServices/IAgentService";
import TemplateService from "../../../Services/TemplateService/TemplateService";
import AgentService from "../../../Services/AgentServices/AgentService";
import PromptService from "../../../Services/PromptService/PromptService";
import "./Prompt.scss";
import IPromptService from "../../../Services/PromptService/IPromptService";
import ITemplateService from "../../../Services/TemplateService/ITemplateService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ASSISTANT_INITIAL_OBJ } from "../../../Constants/constants";


interface Props {
	AgentInfo: any;
	TemplateInfo: number[];
	SetSelectedMainTab: React.Dispatch<React.SetStateAction<string>>;
	SelectedMainTab: string;
}

const Prompt: React.FC<Props> = ({ AgentInfo, TemplateInfo, SetSelectedMainTab, SelectedMainTab }) => {

	const [searchParams] = useSearchParams();
	const assId = searchParams.get('assistantId')
	const assistantId = React.useRef(assId ? assId : '');
	const [assistant, setAssistant] = useState<IAgentService>()
	const disableSubmitButton = React.useRef<boolean>(true);


	const [showLoader, setShowLoader] = useState<boolean>(false);
	const [promptsList, setPromptsList] = useState<IPromptService[]>([]);
	const [selectedPrompts, setSelectedPrompts] = useState<any[]>([]);
	const [showSaveAsTemplate, setshowSaveAsTemplate] = useState(false);
	const [saveAsTemplateChecked, setSaveAsTemplateChecked] = useState(false);
	const [disableButton, setDisableButton] = useState(true);

	const isChecked = React.useRef(false);
	const [updateAssistant, setUpdateAssistant] = useState<IAgentService>(ASSISTANT_INITIAL_OBJ);

	useEffect(() => {
		getAllPrompts()
		getAssistantById()
	}, []);

	const getAllPrompts = () => {
		setShowLoader(true)
		PromptService.GetAllPrompts().then(res => {
			setPromptsList(res.response)
			setShowLoader(false)
		}).catch(error => {
			console.log(error)
			setShowLoader(false)
		})
	}

	const getAssistantById = () => {
		setShowLoader(true)
		AgentService.GetAssistantById(assistantId.current ? assistantId.current : '').then(res => {
			let data: IAgentService = res.response
			setAssistant(data)
			setSelectedPrompts((preVal) => {
				let prompt = [...(data.prompts || [])]
				setUpdateAssistant((preVal) => ({
					...data
				}))
				return prompt
			})

			console.log(selectedPrompts)
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			setShowLoader(false)
		})
	}



	const updateForm = (prompt: any, checked: boolean) => {
		disableSubmitButton.current = false
		if (checked) {
			setSelectedPrompts(prevSelectedPrompts => {
				let data = [...(prevSelectedPrompts || []), prompt]
				setUpdateAssistant((preVal) => ({
					...preVal,
					prompts: data
				}))
				return data
			});
		} else {
			setSelectedPrompts(prevSelectedPrompts => {
				let data = prevSelectedPrompts.filter(item => item.name !== prompt.name)
				setUpdateAssistant((preVal) => ({
					...preVal,
					prompts: data
				}))
				return data
			});
		}

	};


	const handleApply = () => {
		setShowLoader(true)
		AgentService.UpdateAssistant(assistantId.current, updateAssistant).then(res => {
			toastSuccess("Assistant updated Successfully")
			SetSelectedMainTab("chatbot")
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			setShowLoader(false)
		})


	};



	const nextPrevious = (tab: any) => {
		if (tab === "Previous") {
			SetSelectedMainTab("sources")
		} else {
			SetSelectedMainTab("chatbot")
		}
	}


	/// save as template
	const [createTemplateData, setCreateTemplateData] = useState<ITemplateService>({
		name: "",
		assistantId: assistantId.current
	});



	const onCheckSaveAsTemplate = () => {
		setSaveAsTemplateChecked(!saveAsTemplateChecked)
	};





	const validateTemplateData = () => {
		let flag = true
		if (!createTemplateData.name) {
			toastError("Please Enter Template Name")
			flag = false
		}
		return flag
	}

	const createTemplateFromBot = () => {
		if (!validateTemplateData) return
		setShowLoader(true)
		TemplateService.CreateAssistantASTemplpates(createTemplateData).then(res => {
			toastSuccess("Template Created Successfully")
			setshowSaveAsTemplate(false);
			setSaveAsTemplateChecked(false)
		}).catch(err => {
			console.log(err)
			toastError(err?.response?.data?.response)
		}).finally(() => {
			setShowLoader(false)
		})
	}
	const closeEosPopup = () => {
		setshowSaveAsTemplate(false);
		setSaveAsTemplateChecked(false)
		setCreateTemplateData({
			name: "",
			assistantId: updateAssistant.assistantId
		});

	};


	const updateTemplateForm = (target: any) => {
		let name = target.name;
		let val = target.value;
		setCreateTemplateData((preVal) => ({
			...preVal,
			[name]: val,
		}));
	};




	return (
		<>
			<div className="promtSelection prompt-holder">
				<h2 className="mb-3">Prompt</h2>
				<div className="prompt-listing">
					<div className="row">
						{promptsList && promptsList.map((prompt: IPromptService) => (
							<div className="col-12 col-md-4 col-lg-6 mb-4 boxHolder" key={prompt.id}>
								<div className="box justify-content-between">
									<div className="d-flex gap-3 title-bar align-items-center">
										<div className="iconHolder">
											<LuFileText />
										</div>
										<label className="form-check-label" htmlFor={prompt.name}>
											{prompt.name}
										</label>
									</div>
									<p className="description">{prompt.description}</p>
									<div className="chck-holder">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id={prompt.name}
											// checked={selectedPrompts.includes(prompt.id) || prompt.isActive}
											checked={selectedPrompts?.map((prompt: any) => (prompt.name))?.includes(prompt.name)}
											onChange={(e) => updateForm(prompt, e.target.checked)}
										/>
										<div className="checkDesignHolder" >      <span><LuCheck /></span></div>
									</div>
								</div>
							</div>
						))}

						{promptsList?.length == 0 && <>
							<div className="msg right-msg">
								<div className="leftItems">
									<div className="chatDesc">
										<center>There are no records to display</center>
									</div>
								</div>
							</div>
						</>}


					</div>
				</div>
				<div className="mt-2 btnHolder d-flex gap-3">

					<div className="d-flex gap-2 align-items-center">
						<div className="chck-holder">
							<input
								className="form-check-input"
								type="checkbox"
								onChange={() => onCheckSaveAsTemplate()}
								checked={saveAsTemplateChecked}
							/>
							<div className="checkDesignHolder" >
								<span><LuCheck /> </span>
							</div>
						</div>
						<label>Save as template</label>
					</div>



					<button type="button" className="btn btn-secondary ms-auto" onClick={() => { nextPrevious("Previous"); }} >Previous</button>
					<button className="btn btn-primary" type="submit" onClick={handleApply} disabled={disableSubmitButton.current}>Save & Continue</button>
				</div>


			</div>


			{saveAsTemplateChecked && (
				<div className="popup-container create-template-popup">
					<div className="shadeClose" onClick={() => { closeEosPopup(); }}></div>
					<div className="popup-inner">
						<div className="inner-design">
							<div className="popup-header">
								<h3>Save As Template</h3>
								<button type="button" onClick={() => { closeEosPopup(); }} className="close"><FaTimes /></button>
							</div>

							<div className="popup-content">
								<div className="row">
									<div className="col-12 col-md-6 formHolder nameHolder mb-4">
										<label className="form-label">Template Name <span className="danger">*</span></label>
										<input type="text" placeholder="Enter the name" name="name"
											className="form-control"
											onChange={(event) => updateTemplateForm(event.target)}
											value={createTemplateData.name}
										/>
										<div className="mt-4 btnHolder d-flex gap-3">
											<button type="button" className="btn btn-secondary " onClick={() => closeEosPopup()}>Cancel</button>
											<button className="btn btn-primary" type="button" disabled={!createTemplateData.name} onClick={() => createTemplateFromBot()}>Create</button>
										</div>
									</div>
									<div className="col-12 col-md-5 ms-auto statusHolder">
										<div className="topDiv d-flex">
											<div className="icon"><PiThumbsUpLight /></div>
											<div className="statusInfo">
												<h4 className="mb-3">You are set to create your template.</h4>
												<ul className="mb-3">
													{updateAssistant?.files && updateAssistant?.files.length > 0 && (
														<li>
															<span className="iconSpan"><LuCheck /></span>  <span><strong>{updateAssistant.files.length}</strong> Files</span>
														</li>
													)}

													<li>
														<span className="iconSpan"><LuCheck /></span> <span> <strong>{updateAssistant.website && updateAssistant.website.length}</strong> Websites</span>
													</li>
													<li>
														<span className="iconSpan"><LuCheck /></span> <span> <strong>{updateAssistant.github && updateAssistant.github.length}</strong> Git Hub</span>
													</li>
													<li>
														<span className="iconSpan"><LuCheck /></span> <span> <strong>{updateAssistant.databaseConnections && updateAssistant.databaseConnections.length}</strong> Database</span>
													</li>
													<li>
														<span className="iconSpan"><LuCheck /></span> <span> <strong></strong>Basic Instructions</span>
													</li>
													<li>
														<span className="iconSpan"><LuCheck /></span> <span> <strong></strong>Selected Prompts</span>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}


			<ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
		</>
	);
};

export default Prompt;
