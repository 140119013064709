import {
    CREATE_TEMPLATE, GET_TEMPLATE_BY_ID, DELETE_TEMPLATE,
    LIST_TEMPLATE,
    TEMPLATE_FILE_UPLOAD,
    ASSISTANT_AS_TEMPLATE,
    UPDATE_TEMPLATE,
    TEMPLATE_WEBSITE
} from "../EndPoint"
import IResponse from "../IResponse";
import ITemplateService from "./ITemplateService";
import instance, { multiPartInstance } from "../AxiosInstance";
import { createChunkFileForms, createFileChuncks, ISAgentFile } from "../../Components/Utils/Utils";
import { IWebsite } from "../AgentServices/IwebsiteService";


export default class TemplateService {

    private static async CreactTemplateBasicDetails(templateObj: ITemplateService): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            let payload: ITemplateService = {
                name: templateObj.name,
                group: templateObj.group,
                instruction: templateObj.instruction,
                website: templateObj.website,
                gptModel:templateObj.gptModel,
                qAndAList: templateObj.qAndAList,
                databaseConnections: templateObj.databaseConnections,
                prompts: templateObj.prompts,
                github: templateObj.github
            }
            instance.post(CREATE_TEMPLATE, JSON.stringify(payload)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    private static async UpdateTemplateBasicDetails(templateObj: ITemplateService, templateId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            let payload: ITemplateService = {
                name: templateObj.name,
                group: templateObj.group,
                instruction: templateObj.instruction,
                website: templateObj.website,
                gptModel:templateObj.gptModel,
                qAndAList: templateObj.qAndAList,
                databaseConnections: templateObj.databaseConnections,
                prompts: templateObj.prompts,
                github: templateObj.github
            }
            instance.put(`${UPDATE_TEMPLATE}/${templateId}`, JSON.stringify(payload)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static async CreateTemplate(templateObj: ITemplateService): Promise<IResponse | any> {
        return new Promise(async (resolve, reject) => {
            let payload: ITemplateService = {
                name: templateObj.name,
                group: templateObj.group,
                instruction: templateObj.instruction,
                gptModel:templateObj.gptModel
            }
            instance.post(CREATE_TEMPLATE, JSON.stringify(payload)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })

        })
    }
    public static async CreateTemplatebackup(templateObj: ITemplateService): Promise<IResponse | any> {
        return new Promise(async (resolve, reject) => {
            const promises: any = []
            const res = await this.CreactTemplateBasicDetails(templateObj)
            if (templateObj.files && templateObj.files.length > 0) {
                if (Array.isArray(templateObj.files)) {
                    const files: any = templateObj.files
                    const formDatas = createChunkFileForms(res.response, files)
                    formDatas.map(formData => {
                        multiPartInstance.post(`${TEMPLATE_FILE_UPLOAD}/${res.response}/upload-chunk`, formData).then(rresF => {
                        })
                    })

                }
            }
            await Promise.allSettled(promises).then((resP: any) => {
                let rejected = resP.filter((item: any) => item.status === "rejected")
                // console.log(chuncP)
                if (rejected.length > 0) {
                    reject(rejected[0]["reason"] ? rejected[0]["reason"] : '')
                } else {
                    resolve(res.response)
                }
            }).catch(err => {
                reject(err)
            })

        })

    }

    public static async UpdateTemplate(templateObj: ITemplateService, templateId: string): Promise<IResponse> {
        return new Promise(async (resolve, reject) => {
            const promises: any = []
            const newFiles: File[] = []
            const res = await this.UpdateTemplateBasicDetails(templateObj, templateId)
            if (templateObj.files && templateObj.files.length > 0) {
                templateObj.files.map(file => {
                    if (!ISAgentFile(file)) {
                        newFiles.push(file)
                    }
                })

                const formDatas = createChunkFileForms(templateId, newFiles)
                formDatas.map(formData => {
                    promises.push(multiPartInstance.post(`${TEMPLATE_FILE_UPLOAD}/${templateId}/upload-chunk`, formData).then(rresF => {
                    }))
                })


            }
            await Promise.allSettled(promises).then((resP: any) => {
                let rejected = resP.filter((item: any) => item.status === "rejected")
                // console.log(chuncP)
                if (rejected.length > 0) {
                    reject(rejected[0]["reason"] ? rejected[0]["reason"] : '')
                } else {
                    resolve(res)
                }
            }).catch(err => {
                reject(err)
            })

        })

    }
    public static async GetTemplateWebSite(url: string, assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(`${TEMPLATE_WEBSITE}/${assistantId}/websites?website=${url}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static async UpdateTemplateWebSiteUpdate(url: string, wsData: IWebsite[], assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            let data: any = { urls: wsData, url: url }
            instance.put(`${TEMPLATE_WEBSITE}/${assistantId}/websites`, JSON.stringify(data)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static async CreateTemplatebkup(templateObj: ITemplateService, templateId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            let payload: ITemplateService = {
                name: templateObj.name,
                group: templateObj.group,
                instruction: templateObj.instruction,
                website: templateObj.website,
                qAndAList: templateObj.qAndAList,
                databaseConnections: templateObj.databaseConnections,
                prompts: templateObj.prompts,
                github: templateObj.github
            }
            instance.put(`CREATE_TEMPLATE`, JSON.stringify(payload)).then(res => {
                const formData = new FormData();
                if (templateObj.files && templateObj.files.length > 0) {
                    if (Array.isArray(templateObj.files)) {
                        const fileObjects = templateObj.files.filter((item: any) => item instanceof File);
                        fileObjects.forEach((file: any) => {
                            formData.append('files', file);
                        });
                        multiPartInstance.post(`${TEMPLATE_FILE_UPLOAD}/${res.data.response}/uploads`, formData).then(rresF => {
                            resolve(res.data)
                        }).catch(er => {
                            reject(er.response.data)
                        })
                    }
                } else {
                    resolve(res.data)
                }

            }).catch(err => {
                reject(err.response.data)
            })
        })

    }
    public static async DeleteWebSite(website: string, assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.delete(`${TEMPLATE_WEBSITE}/${assistantId}/websites?website=${website}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static async GetAllTemplpates(groupName?: string, pageNumber?: number, pageSize?: number): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(groupName ? `${LIST_TEMPLATE}?group=${groupName}` : `${LIST_TEMPLATE}?limit=${pageSize}&page=${pageNumber}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static async DeleteTemplate(templateId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.delete(`${DELETE_TEMPLATE}?templateId=${templateId}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    }


    public static async GetTemplateById(templateId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(`${GET_TEMPLATE_BY_ID}/${templateId}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static async CreateAssistantASTemplpates(data?: object): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.post(`${ASSISTANT_AS_TEMPLATE}`, JSON.stringify(data)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

}