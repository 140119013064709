import { SAVE_COMPANY_NAME, DELETE_COMPANY_NAME } from "./ActionType"

export const saveCompanyName = (data: string) => {
    return {
        type: SAVE_COMPANY_NAME,
        data: data
    }
}
export const deleteComapanyName = () => {
    return {
        type: DELETE_COMPANY_NAME,
        data: ""
    }
}