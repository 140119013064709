import React, {  } from "react";
import './Integrations.scss'
import IAgentService from "../../../Services/AgentServices/IAgentService";



interface Props {
    AgentInfo: IAgentService
}

const Integrations = ({  }) => {







    return (<>
        <div className="integrationsHolder ">
           
        <h3 className="mb-4">Integrations</h3>
        <ul className="row">
            <li className="col-12 col-md-3">
                <a> <span>
                <img src={require("../../../common/images/whatsapp.png")} alt="" />
                </span>
                    <p>WhatsApp</p>
                </a>
            </li>
            <li className="col-12 col-md-3">

                <a> <span>
                <img src={require("../../../common/images/slack.png")} alt="" />
                </span>
                    <p>Slack</p>
                </a>
            </li>
        </ul>
        </div>

        {/* <ThreeDotLoader visibility={showLoader}></ThreeDotLoader> */}

    </>)
}
export default Integrations