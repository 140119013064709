import { title } from "process";
import React, { useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useLocation } from "react-router-dom";

const Breadcrumb = () => {
    const location = useLocation();
    const [pageTitle, setPageTitle] = useState("")
    const [breadcrumbMenu, setBreadcrumbMenu] = useState([{}])
    const [backArrowAction, setBackArrowAction] = useState({url:'', title:''});


    React.useEffect(() => {
        // Define your logic to set the page title and back arrow visibility based on the current URL
        switch (location.pathname) {
            case '/Apps':
                setPageTitle('Agents');
                setBreadcrumbMenu([{ title: "Groups", url: "/Groups" }, { title: "Agents", url: "/Apps" }])
                setBackArrowAction({title:"Group", url:"/Groups"})
                break;
            case '/AgentDetails':
                if (location.search) break
                setPageTitle('Agent Details');
                setBreadcrumbMenu([{ title: "Groups", url: "/Groups" }, { title: "Agents", url: "/Apps" }, { title: "New Agent", url: "" }])
                setBackArrowAction({title:"Group", url:"/Apps"})
                break;
            case '/Groups':
                setPageTitle('Groups');
                setBreadcrumbMenu([{ title: "Groups", url: "/Groups" },])
                setBackArrowAction({title:"Group", url:"/Dashboard"})
                break;
            case '/Templates':
                setPageTitle('Templates');
                setBreadcrumbMenu([{ title: "Templates", url: "/Templates" },])
                setBackArrowAction({title:"Group", url:"/Dashboard"})
                break;
            case '/Template/Create':
                setPageTitle('Create Template');
                setBreadcrumbMenu([{ title: "Templates", url: "/Templates" }, { title: "New Template", url: "" }])
                setBackArrowAction({title:"Group", url:"/Templates"})
                break;
            case '/Prompts':
                setPageTitle('Prompts');
                setBreadcrumbMenu([{ title: "Prompts", url: "/Prompts" },])
                setBackArrowAction({title:"Group", url:"/Dashboard"})
                break;
            case '/Prompt/Create':
                setPageTitle('Create Prompt');
                setBreadcrumbMenu([{ title: "Prompts", url: "/Prompts" }, { title: "New Prompt", url: "" }])
                setBackArrowAction({title:"Group", url:"/Prompts"})
                break;
            case '/Users':
                setPageTitle('User Management');
                setBreadcrumbMenu([{ title: "Settings", url: "" }, { title: "User Management", url: "" }])
                setBackArrowAction({title:"Dashboard", url:"/Dashboard"})
                break;
            case '/Roles':
                setPageTitle('Role Management');
                setBreadcrumbMenu([{ title: "Settings", url: "" }, { title: "Role Management", url: "" }])
                setBackArrowAction({title:"Dashboard", url:"/Dashboard"})
                break;
            case '/Models':
                setPageTitle('Model API Key');
                setBreadcrumbMenu([{ title: "Settings", url: "" }, { title: "Model APIKey", url: "" }])
                setBackArrowAction({title:"Dashboard", url:"/Dashboard"})
                break;
            case '/Model/Create':
                setPageTitle('Create Model API Key');
                setBreadcrumbMenu([{ title: "Settings", url: "" }, { title: "Model APIKey", url: "" }, { title: "New APIKey", url: "" }])
                setBackArrowAction({title:"Dashboard", url:"/Dashboard"})
                break;
            case '/AuditLog':
                setPageTitle('Audit Log');
                setBreadcrumbMenu([{ title: "Settings", url: "" }, { title: "Audit Log", url: "" }])
                setBackArrowAction({title:"Dashboard", url:"/Dashboard"})
                break;
            default:
                setBreadcrumbMenu([{}])
                setPageTitle("")
                setBackArrowAction({url:'', title:''})
                break;
        }
    }, [location.pathname]);
    return (<>
        {location.pathname !== "/Dashboard" &&
            <nav aria-label="breadcrumb ">
                <ol className="breadcrumb mb-1">
                    <li className="breadcrumb-item base-item" >
                        <a href={backArrowAction.url}>
                            <span className='icon'><IoIosArrowRoundBack /></span>
                            <span className='text'>Dashboard</span>
                        </a>
                    </li>
                    {breadcrumbMenu.length > 0 && breadcrumbMenu.map((item: any, index) => (
                        <li className={breadcrumbMenu.length === index + 1 ? "breadcrumb-item active" : "breadcrumb-item"} >
                            <a href={item.url}>
                                {item.title}
                            </a>
                        </li>

                    ))}





                </ol>
            </nav>}
        <h1>{pageTitle}</h1>

    </>)
}

export default Breadcrumb