
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const options = {
  autoClose: 4000,
  className: '',
  position: toast.POSITION.TOP_CENTER,
};

export const toastError = message => {
  toast.error(message, options);
};

export const toastSuccess = message => {
  toast.success(message, options);
}

export const toastWarning = message => {
  toast.warn(message, options);
}