import instance from "../AxiosInstance";
import { LIST_AUDITLOGS } from "../EndPoint";
import IResponse from "../IResponse";

export default class AuditLogService {

    //get audit logs
    public static async GetAllLog(pageNum: number, pageSize: number, email?: string,): Promise<IResponse> {
        return new Promise(async (resolve, reject) => {
            await instance.get(email ? `${LIST_AUDITLOGS}?email=${email}&page=${pageNum}&limit=${pageSize}`:`${LIST_AUDITLOGS}?page=${pageNum}&limit=${pageSize}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }


}