import React, { useState, useEffect, useRef, useContext } from "react";
import DataTable, { TableColumn } from 'react-data-table-component';
import { toastError, toastSuccess, toastWarning } from "../Toast/Toast";
import IApiKey from "../../Services/APIKeyServices/IApiKey";
import ApiKeyService from "../../Services/APIKeyServices/ApiKeyService";
import { FaTimes, FaTrash, FaPen, FaPlus, FaEye } from 'react-icons/fa';
import { ThreeDotLoader } from "../Loader/Loader";
import { LuTrash2, LuPen, LuEye, LuCode2 } from "react-icons/lu";

import './RoleManagement.scss';
import { confirmAlert } from "react-confirm-alert";
// import RoleManagementService from "../../Services/RoleManagement/RoleManagementService";
import IRoleManagement, { IPermissions } from "../../Services/RoleManagement/IRoleManagementService";
import RoleManagementService from "../../Services/RoleManagement/RoleManagementService";
import { PERMISSIONS_INITIAL_OBJ, ROLE_INITIAL_OBJ } from "../../Constants/constants";
import IUser from "../../Services/UserManagement/IUserManagementService";
import UserContext from "../../Context/UserContext/UserContext";
import PermissionContext from "../../Context/PermissionContext/PermissionContext";
// import { DEFAULT_PERMISSIONS, permissionState } from "../../Constants/constants";

interface Props {
	agentId: string;
}

const RoleManagement = ({ Props }: any) => {
	const user = useContext<IUser>(UserContext)
	const permission = useContext<IPermissions>(PermissionContext)


	const [showLoader, setShowLoader] = useState(false);
	const [showKeysFormPopup, setShowKeysFormPopup] = useState(false);
	const clickedPartIndexRef = useRef(0);
	const [tableData, setTableData] = useState<IRoleManagement[]>([]);
	const [buttonDisable, setButtonDisable] = useState(true);
	const [permissions, setPermissions] = useState<string[]>(Object.keys(PERMISSIONS_INITIAL_OBJ));
	const [isEdit, setIsEdit] = useState(false);
	const [selectAll, setSelectAll] = useState(false);


	const [createRoleForm, setCreateRoleForm] = useState({
		...ROLE_INITIAL_OBJ
	});


	useEffect(() => {
		// if(!permission.viewRole) return;
		getAllRoles();
	}, []);

	const getAllRoles = () => {
		setShowLoader(true);
		RoleManagementService.GetAllRoles().then(res => {
			setTableData(res.response || []);
		}).catch(error => {
			console.error('Error fetching roles:', error);
		}).finally(() => {
			setShowLoader(false);
		})
	};

	const handleCreateNewRole = () => {
		if (!permission.createRole) return;
		setIsEdit(false);
		setShowKeysFormPopup(true);
		setCreateRoleForm(structuredClone(ROLE_INITIAL_OBJ));
	};

	const showKeysEditFormPopup = (row: IRoleManagement) => {
		if (!permission.updateRole) return;
		setShowKeysFormPopup(true);
		setCreateRoleForm({ ...row })
		setIsEdit(true)
	};

	const handleCheckboxChange = (permissionName: string, action: any) => {
		setButtonDisable(false)
		setCreateRoleForm((prevVal: IRoleManagement) => {
			let permission = prevVal.permissions
			permission[permissionName as keyof IPermissions] = action.checked
			return {
				...prevVal,
				permissions: permission
			}
		});

	};

	const updateForm = (target: any) => {
		let name = target.name;
		let val = target.value;
		setButtonDisable(false)
		setCreateRoleForm({
			...createRoleForm,
			[name]: val,
		});
	};





	const validateRoleData = () => {
		if (!createRoleForm.name?.trim()) {
			toastError("Please Enter Role Name");
			return false;
		}

		if (!Object.values(createRoleForm.permissions).some(val => val !== false)) {
			toastError("Please Select Permissions");
			return false;
		}

		return true;
	};
	const deleteRole = (role: IRoleManagement) => {
		setShowLoader(true);
		RoleManagementService.DeleteRole(role)
			.then((res: any) => {
				toastSuccess("Role Deleted Successfully");
				getAllRoles()
			}).catch((err) => {
				console.log(err)
				toastError(err?.response?.data?.response)
			}).finally(() => {
				setShowLoader(false);
			});
	}
	const deleteRoleConfirm = (row: IRoleManagement) => {
		if(!permission.deleteRole) return toastError("No permission to delete")
		confirmAlert({
			title: "Confirm Delete",
			message: "Are you sure you want to delete this role?",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						deleteRole(row)
					}
				},
				{
					label: "No",
					onClick: () => { }
				}
			]
		});
	};

	const hideKeysFormPopup = () => {
		setShowKeysFormPopup(false);
		setButtonDisable(true)
	};

	const createRole = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateRoleData()) return;
		setShowLoader(true);
		setButtonDisable(true);
		RoleManagementService.CreateRole(createRoleForm).then((res: any) => {
			toastSuccess("Role Created Successfully");
			setShowKeysFormPopup(false);
			getAllRoles();

		}).catch((err) => {
			toastError(err.response.data.response);
			console.error(err);
		}).finally(() => {
			setShowLoader(false);
			setButtonDisable(true);
		});
	};

	const updateRoles = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateRoleData()) return;
		setShowLoader(true);
		setButtonDisable(true);
		RoleManagementService.UpdateRole(createRoleForm)
			.then((res: any) => {
				toastSuccess("Role Updated Successfully");
				setShowKeysFormPopup(false);
				getAllRoles();
			}).catch((err) => {
				toastError(err.response.data.response);
				console.error(err);
			}).finally(() => {
				setShowLoader(false);
				setButtonDisable(true);
			});

	};


	const tableColumns: TableColumn<IRoleManagement>[] = [
		{
			name: "Role Name",
			selector: (row: any) => row.name,
			sortable: true,
			width: "350px",
		},
		{
			name: "Created Date",
			selector: (row: IRoleManagement) => row.createdAt ? new Date(row.createdAt).toLocaleDateString() : '',
			sortable: true,
			width: "300px",
		},
		{
			name: "Updated Date",
			selector: (row: IRoleManagement) => row.updatedAt ? new Date(row.updatedAt).toLocaleDateString() : '',
			sortable: true,
			width: "300px",
		},
		{
			name: " ",
			sortable: false,
			button: true,
			right: true,
			cell: (row, rowIndex) => (
				<div className="gridIcons" data-tag="allowRowEvents">
					<>
						<>
							{(
								<button
									type="button"
									className="btn plainBtn editBtn"
									title="Edit Item"
									disabled={!permission.updateRole}
									onClick={() => {
										showKeysEditFormPopup(row);
										clickedPartIndexRef.current = rowIndex;
									}}
								>
									<LuPen />
								</button>
							)}
							{(
								<button
									type="button"
									className="btn plainBtn deleteBtn"
									title="Delete"
									disabled={!permission.deleteRole}
									onClick={() => deleteRoleConfirm(row)}
								>
									<LuTrash2 />
								</button>

							)}
						</>
					</>
				</div>
			),
		}
	];


	const permissionColumns: TableColumn<any>[] = [
		{
			name: "Type",
			selector: (row: string) => row,
			sortable: true
		},
		{
			name: "Permissions",
			cell: (row: string) => (
				<div>
					<input
						type="checkbox"
						checked={createRoleForm.permissions && createRoleForm.permissions[row as keyof IPermissions]}
						onClick={(event) => handleCheckboxChange(row, event.target)}
					/>
				</div>
			)
		}
	];



	return (
		<>
			<div className="d-flex justify-content-between align-items-center mb-4">
				<h3 className="mb-0">Roles</h3>
				{(
					<button
						className="btn btn-secondary ms-auto"
						type="button"
						onClick={handleCreateNewRole}
					>New Role
					</button>
				)}
			</div>

			<div className="dataTable">
				{tableData && (
					<DataTable
						noHeader={true}
						columns={tableColumns}
						data={tableData}
						// pagination
						// paginationServer
						pagination={true}
						paginationPerPage={10}
						paginationRowsPerPageOptions={[10, 15, 20]}
					/>
				)}
			</div>


			{showKeysFormPopup && (
				<div className="popup-container">
					<div className="shadeClose" onClick={() => {
						hideKeysFormPopup();
					}}></div>
					<div className="popup-inner">
						<div className="inner-design">
							<div className="popup-header">
								<h3>{isEdit ? "Role Details" : "Create New Role"}</h3>
								<button
									type="button"
									onClick={() => {
										hideKeysFormPopup();
									}}
									className="close"
								><FaTimes /></button>
							</div>

							<div className="popup-content">
								<form onSubmit={isEdit ? updateRoles : createRole}>
									<div className="row">
										<div className="col-12 col-md-12 formHolder nameHolder mb-4">
											<label className="form-label">
												Name <span className="danger">*</span>
											</label>
											<input
												type="text"
												placeholder="Enter the role"
												name="name"
												className="form-control"
												onChange={(event) => updateForm(event.target)}
												disabled={isEdit}
												value={createRoleForm.name}
											/>
										</div>
									</div>

									{/* <div>
										<input
											type="checkbox"
											placeholder="Select All"
											onChange={handleSelectAll}
											checked={selectAll}
										/>
									</div> */}
									<div className="permissionTable">
										<h3>Permissions</h3>
										<DataTable
											columns={permissionColumns}
											data={permissions}
										// onRowClicked={(row) => handleCheckboxChange(row)}
										/>
									</div>


									<div className="d-flex gap-2 mt-2">
										<button
											type="submit" className="btn btn-secondary small" onClick={() => { hideKeysFormPopup(); }}> Cancel
										</button>
										<button type="submit" className="btn btn-primary small" disabled={buttonDisable}>
											{isEdit ? "Update" : "Create"}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>)}
			<ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
		</>
	);
};

export default RoleManagement;
