import React, { useRef, useState, useContext } from "react";
import './UserManagement.scss'
import { LuPen, LuTrash2, LuCheck, LuCheckCircle2, LuXCircle } from "react-icons/lu";
import DataTable, { TableColumn } from 'react-data-table-component';
import IUser from "../../Services/UserManagement/IUserManagementService";
import { ThreeDotLoader } from "../Loader/Loader";
import UserManagementService from "../../Services/UserManagement/UserManagementService";
import { toastError, toastSuccess } from "../Toast/Toast";
import { confirmAlert } from "react-confirm-alert";
// import RoleManagementService from "../../Services/RoleManagement/RoleManagementService";
import { useNavigate } from "react-router-dom";
import { PageItem, Row } from "react-bootstrap";
import RoleManagementService from "../../Services/RoleManagement/RoleManagementService";
import IRoleManagement, { IPermissions } from "../../Services/RoleManagement/IRoleManagementService";
import UserContext from "../../Context/UserContext/UserContext";
import PermissionContext from "../../Context/PermissionContext/PermissionContext";
// import { permissionState } from "../../Constants/constants";

const UserManagement = ({ Props }: any) => {
    const defaultTabName = "allusers"
    const user = useContext<IUser>(UserContext)
    const permission = useContext<IPermissions>(PermissionContext)

    const [selectedTab, setSelectedTab] = useState<string>(defaultTabName);
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [tableData, setTableData] = useState<IUser[]>([]);
    const [roleList, setRolesList] = useState([])
    const [pageNumber, setPageNumber] = useState(1)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [totalNumberOfItem, settotalNumberOfItem] = useState(0)
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [showActiveColumn, setShowActiveColumn] = useState<boolean>(false)

    const [updateItemForm, setUpdateItemForm] = useState<IUser>({
        Id: 0,
        email: "",
        firstName: "",
        lastName: "",
        role: "",
        status: false,
    });


    React.useEffect(() => {
        // if (!permission.viewUser) return
        setTableData([])
        if (selectedTab === "allusers") {
            setShowActiveColumn(false)
        } else {
            setShowActiveColumn(true)
        }
        getUserData(selectedTab)
        getAllRoleData()
    }, [pageNumber, rowPerPage]);




    const getAllUsersData = (active: string, inactive: string) => {
        setShowLoader(true)
        setTableData([])
        UserManagementService.GetAllUsersList(active, inactive, pageNumber, rowPerPage).then(res => {
            setTableData(res.response || []);
            settotalNumberOfItem(res.count?res.count:0)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setShowLoader(false)
        })
    }
    const getAllNewUsersData = (newUser: string) => {
        setShowLoader(true)
        setTableData([])
        UserManagementService.GetAllNewUsersList(newUser, pageNumber, rowPerPage).then(res => {
            setTableData(res.response || []);
            settotalNumberOfItem(res.count?res.count:0)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setShowLoader(false)
        })
    }
    const getAllRoleData = () => {
        setShowLoader(true)
        RoleManagementService.GetAllRoles().then(res => {
            setRolesList(res.response?.map((item: IRoleManagement) => item.name));
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setShowLoader(false)
        })
    }
    const deleteUser = (user: IUser) => {
        setShowLoader(true)
        UserManagementService.DeleteUser(user).then(res => {
            toastSuccess("User Deleted Successfully")
            getUserData(selectedTab)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setShowLoader(false)
        })
    }
    const deleteUserConfirm = (row: IUser) => {
        confirmAlert({
            title: "Confirm Delete",
            message: "Do you want to delete the user ?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        deleteUser(row)
                    }
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        })
    }

    const approveUser = (user: IUser) => {
        setShowLoader(true)
        user.status = true
        user.position = "Active"
        UserManagementService.UpdateUser(user).then(res => {
            toastSuccess("User Approved Successfully")
            getUserData(selectedTab)
        }).catch((err) => {
            toastError(err.response)
            console.log(err)
        }).finally(() => {
            setShowLoader(false)
        })

    }

    const getUserData = (tabName: string) => {
        setTableData([])
        if (tabName == defaultTabName) {
            if (!permission.viewUser) return;
            getAllUsersData("Active", "Inactive")
            setShowActiveColumn(false)
        } else {
            if (!permission.viewNewUser) return;
            getAllNewUsersData("New")
            setShowActiveColumn(true)
        }

    }

    const approveUserConfirm = (row: IUser) => {
        if (row.role) {
            confirmAlert({
                title: "Confirm to Approve",
                message: "Do you want to approve the user ?",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            approveUser(row)
                        }
                    },
                    {
                        label: "No",
                        onClick: () => { }
                    }
                ]
            })
        }
        else {
            toastError("Please Select Role")
        }

    }

    const onSave = (row: IUser) => {
        if (!permission.updateUser) return;
        row.position = row.status == true ? 'Active' : 'Inactive'
        UserManagementService.UpdateUser(row).then(res => {
            if (res.statusCode == 200) {
                toastSuccess("User Details Modified Successfully")
                setSelectedTab(defaultTabName)
                getUserData(defaultTabName)
                setShowLoader(false)
                setEditingIndex(null)
            }
        }).catch((err) => {
            setShowLoader(false)
            toastError(err.response)
            console.log(err)
        })


    }


    const handleRowPerPageChange = (newPerPage: number, page: number) => {
        setPageNumber(page)
        setRowPerPage(newPerPage)
    }

    const handlePageChange = (page: number) => {
        setPageNumber(page)
    }

    const onchnageTab = (tab: string) => {
        setSelectedTab(tab)
        getUserData(tab)
    }

    const updateForm = (row: IUser, target: any, index: number) => {
        const { name, checked, value } = target
        if (target.type !== 'checkbox') {
            const updatedTableData = tableData.map((row, rowIndex) => ({
                ...row,
                [name]: rowIndex === index ? value : row[name as keyof IUser]
            }));
            setTableData(updatedTableData);
        } else {
            const updatedTableData = tableData.map((row, rowIndex) => ({
                ...row,
                [name]: rowIndex === index ? checked : row[name as keyof IUser]
            }));
            setTableData(updatedTableData);
        }
    };




    const rejectUserConfirm = (row: IUser) => {
        confirmAlert({
            title: "Confirm to reject",
            message: "Do you want to reject the user ?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        rejectUser(row)
                    }
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        })

    }

    const rejectUser = (user: IUser) => {
        setShowLoader(true)
        user.status = false
        user.position = "Rejected"
        UserManagementService.UpdateUser(user).then(res => {
            toastSuccess("User Rejected Successfully")
            getUserData(selectedTab)
        }).catch((err) => {
            toastError(err.response)
            console.log(err)
        }).finally(() => {
            setShowLoader(false)
        })
    }


    const tableColumns: TableColumn<IUser>[] = [
        {
            name: "Full Name",
            selector: (row: IUser) => row.firstName ? row.firstName : '',
            sortable: true,
            width: "250px",
            cell: (row: IUser) => (
                <div title={row.firstName}>
                    {row.firstName}
                </div>
            ),
        },
        {
            name: "Email",
            selector: (row: IUser) => row.email ? row.email : '',
            sortable: true,
            width: "300px",
            cell: (row: IUser) => (
                <div
                    title={row.email}
                    style={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {row.email && row.email.length > 50 ? `${row.email.substring(0, 50)}...` : row.email}
                </div>
            ),
        },
        {
            name: "Role",
            sortable: true,
            width: "250px",
            cell: (row, index) => (
                <>
                    <select
                        className="form-select"
                        onChange={(event) => updateForm(row, event.target, index)}
                        value={row.role}
                        disabled={(editingIndex !== index || editingIndex == null) && (selectedTab == defaultTabName)}
                        name="role"
                    >
                        <option value="">Select a Role</option>
                        {roleList?.map((role) => (
                            <option key={role} value={role}>{role}</option>
                        ))}
                    </select>


                </>
            )
        },
        {
            name: "Create Date",
            // selector: (row: IUser) => selectedTab == defaultTabName ?  row.createdAt :  '',  
            sortable: true,
            width: "150px",
            cell: (row, index) => (
                <>
                    {row.createdAt}
                </>
            )
        },
        {
            name: "Update Date",
            // selector: (row: IUser) => selectedTab == defaultTabName ?  row.createdAt :  '',  
            sortable: true,
            width: "150px",
            cell: (row, index) => (
                <>
                    {row.updatedAt}
                </>
            )
        },
        {
            name: selectedTab == defaultTabName ? "Status" :'',
            // selector: (row: IUser) => selectedTab == defaultTabName ?  row.createdAt :  '',  
            sortable: true,
            width: "150px",
            cell: (row, index) => selectedTab == defaultTabName ? (
                <>
                    <div className="toggleSwitch">
                        <input
                            className="toggle-input"
                            type="checkbox"
                            checked={row.status}
                            name="status"
                            disabled={(editingIndex !== index)}
                            onChange={(event) => updateForm(row, event.target, index)}
                        />
                        <span></span>
                    </div>
                </>
            ) : (<></>)
        },
        {
            name: " ",
            width: "100px",
            sortable: false,
            button: true,
            right: true,
            cell: (row, index) => (<>
                {selectedTab == "allusers" && (
                    <div className="gridIcons" data-tag="allowRowEvents">
                        <div>
                            {(editingIndex !== index) && (
                                <button
                                    type="button"
                                    className="btn plainBtn editBtn"
                                    title="Edit"
                                    onClick={() => setEditingIndex(index)}
                                    disabled={(editingIndex !== null && editingIndex !== index) || !permission.updateUser}
                                >
                                    <LuPen />
                                </button>
                            )}
                            {(editingIndex !== index) && (
                                <button
                                    type="button"
                                    className="btn plainBtn editBtn"
                                    title="Delete"
                                    onClick={() => deleteUserConfirm(row)}
                                    disabled={editingIndex !== null || !permission.updateUser}
                                >
                                    <LuTrash2 />
                                </button>
                            )}
                            {editingIndex === index && (
                                <button
                                    type="button"
                                    className="btn plainBtn deleteBtn"
                                    title="Save"
                                    onClick={() => onSave(row)}
                                >

                                    <LuCheck />
                                </button>
                            )}
                        </div>

                    </div>
                )}

                {selectedTab == "newusers" && <>
                    <div className="btnHolder d-flex">
                        <button className="btn icon-btn success" title="Accept" onClick={() => approveUserConfirm(row)}><LuCheckCircle2 /></button>
                        <button className="btn icon-btn danger" title="Reject" onClick={() => rejectUserConfirm(row)}><LuXCircle /></button>

                    </div>
                </>}

            </>),
        }
    ];





    return (<>
        {/* <h3 className="mb-0">Users Management</h3> */}
        <div className="userHolder">
            <div className="tabHeader">
                <div
                    className={`tabButton ${selectedTab === "allusers" ? "active" : ""}`}
                    onClick={() => onchnageTab("allusers")}>
                    All Users
                </div>
                <div
                    className={`tabButton ${selectedTab === "newusers" ? "active" : ""}`}
                    onClick={() => onchnageTab("newusers")}>
                    New Users
                </div>
            </div>
            <div className="dataTable">
                {tableData && (
                    <DataTable
                        noHeader={true}
                        columns={tableColumns}
                        data={tableData}
                        pagination
                        paginationServer
                        onChangeRowsPerPage={handleRowPerPageChange}
                        onChangePage={handlePageChange}
                        paginationTotalRows={totalNumberOfItem}
                    />
                )}
            </div>
        </div>

        <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
    </>)
}
export default UserManagement