

import React, { useContext, useRef, useState } from "react";
import { toastError, toastSuccess, toastWarning } from "../Toast/Toast";
import { useNavigate } from "react-router-dom";
import { ThreeDotLoader } from "../Loader/Loader";
import IAuditLog from "../../Services/AuditLogServices/IAuditLogServices";
import AuditLogService from "../../Services/AuditLogServices/AuditLogService";
import { MdOutlineSimCardDownload, MdSearch, } from "react-icons/md";
import DataTable, { TableColumn } from 'react-data-table-component';
import * as XLSX from 'xlsx';
import { TbFileExport } from "react-icons/tb";
import IUser from "../../Services/UserManagement/IUserManagementService";
import UserContext from "../../Context/UserContext/UserContext";
import { IPermissions } from "../../Services/RoleManagement/IRoleManagementService";
import PermissionContext from "../../Context/PermissionContext/PermissionContext";


const AuditLog = ({ Props }: any) => {
    const user = useContext<IUser>(UserContext)
    const permission = useContext<IPermissions>(PermissionContext)

    const [isLastPage, setIsLastPage] = useState<boolean>(false)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [logData, setLogData] = useState<IAuditLog[]>([]);
    const [userFilter, setUserFilter] = useState<string>('')
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalNumberRows, setTotalNumberRows] = useState(0)

    React.useEffect(() => {
        // if (user.email) getAllLogData(user.email)
        getAllLogData(userFilter)
    }, [pageNumber, pageSize]);

    const formatDate = (timestamp: any) => {
        const date = new Date(timestamp);
        return date.toLocaleString(); // Adjust the format as needed
    };


    const getAllLogData = async (filterText?: string) => {
        setShowLoader(true)
        AuditLogService.GetAllLog(pageNumber, pageSize, filterText).then(res => {
            setLogData(res.response)
            setTotalNumberRows(res.count ? res.count : 0)
            setIsLastPage(res.last_page ? res.last_page : false)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setShowLoader(false)
        })
    };


    const tableColumns: TableColumn<IAuditLog>[] = [
        {
            name: "Email",
            selector: (row: IAuditLog) => row.userId ? row.userId : '',
            sortable: true,
            width: "200px",
            cell: (row: IAuditLog) => (
                <div
                    title={row.userId}
                    style={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {row.userId && row.userId.length > 50 ? `${row.userId.substring(0, 50)}...` : row.userId}
                </div>
            ),
        },
        {
            name: "Created Date",
            selector: (row: IAuditLog) => row.timestamp ? row.timestamp : '',
            sortable: true,
            width: "150px",
            cell: (row: IAuditLog) => (
                <div
                    title={formatDate(row.timestamp)}
                    style={{
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {row.timestamp ? new Date(row.timestamp).toLocaleDateString() : ""}

                </div>
            ),
        },
        {
            name: "IPAddress",
            selector: (row: IAuditLog) => row.IPaddress ? row.IPaddress : '',
            sortable: true,
            width: "125px",
            cell: (row: IAuditLog) => (
                <div
                    title={row.IPaddress}
                    style={{
                        maxWidth: "125px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {row.IPaddress && row.IPaddress.length > 50 ? `${row.IPaddress.substring(0, 50)}...` : row.IPaddress}
                </div>
            ),
        },
        {
            name: "Action",
            selector: (row: IAuditLog) => row.url ? row.url : '',
            sortable: true,
            width: "250px",
            cell: (row: IAuditLog) => (
                <div
                    title={row.url}
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {row.url && row.url.length > 250 ? `${row.url.substring(0, 250)}...` : row.url}
                </div>
            ),
        },
        {
            name: "Method",
            selector: (row: IAuditLog) => row.action ? row.action : '',
            sortable: true,
            width: "100px",
            cell: (row: IAuditLog) => (
                <div
                    title={row.url}
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {row.action && row.action.length > 250 ? `${row.action.substring(0, 250)}...` : row.action}
                </div>
            ),
        },

        {
            name: "StatusCode",
            selector: (row: IAuditLog) => row.httpStatusCode ? row.httpStatusCode : '',
            sortable: true,
            width: "150px",
            cell: (row: IAuditLog) => (
                <div
                    title={row.httpStatusCode}
                    style={{
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {row.httpStatusCode}

                </div>
            ),
        },
        {
            name: "Details",
            selector: (row: IAuditLog) => row.statusDetails ? row.statusDetails : '',
            sortable: true,
            width: "150px",
            cell: (row: IAuditLog) => (
                <div
                    title={row.statusDetails}
                    style={{
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {row.statusDetails}

                </div>
            ),
        },


    ];


    const exportToExcel = () => {
        setShowLoader(true)
        const data: any = []
        // Define the specific order of keys
        const keyOrder: any = ['userId', 'timestamp', 'IPaddress', 'url', 'action', 'httpStatusCode', 'statusDetails'];

        // Reorder the object based on the keyOrder array
        logData.map((logObj:any) => {
            const reorderedObj = keyOrder.reduce((acc: any, key: any) => {
                if (logObj.hasOwnProperty(key)) {
                    acc[key] = logObj[key];
                }
                return acc;
            }, {});
            data.push(reorderedObj)
        })

        const Heading = [['User', 'Date Time', 'IP Address', 'URL', 'Action', 'HTTP Status', 'HTTP Status Details']];

        const wb = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, Heading);

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });

        XLSX.utils.book_append_sheet(wb, ws, 'Audit Log');

        XLSX.writeFile(wb, 'Audit_Log_Report.xlsx');
        setShowLoader(false)
    };


    const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        var filter = event.target.value
        setUserFilter(filter);
        if (filter == "") getAllLogData()
    }


    const onFilterClick = (e: React.FormEvent) => {
        getAllLogData(userFilter);
    }
    const onchangePage = (page: number) => {
        setPageNumber(page)
    }
    const onpageSizeChange = (PerPage: number, currentPage: number) => {
        setPageNumber(currentPage)
        setPageSize(PerPage)
    }

    return (<>
        <div className="d-flex">
            <div className="ms-auto">
                <div className="d-flex gap-2 align-items-center">
                    <button onClick={exportToExcel} className="btn icon-btn" title="Report file download"><MdOutlineSimCardDownload /></button>
                    <input
                        type="text"
                        onChange={onChangeFilter}
                        placeholder="Search by username"
                        className="form-control"
                    />
                    <button type="submit" className="btn icon-btn" onClick={(e) => onFilterClick(e)} disabled={!userFilter}>
                        <MdSearch />
                    </button>
                </div>
            </div>
        </div>
        <div className="userHolder">
            <div className="dataTable">
                {logData && (
                    <DataTable
                        noHeader={true}
                        columns={tableColumns}
                        data={logData}
                        pagination
                        paginationServer
                        paginationTotalRows={isLastPage ? pageNumber * pageSize : pageNumber * pageSize + 1}
                        onChangePage={onchangePage}
                        onChangeRowsPerPage={onpageSizeChange}
                    />
                )}
            </div>
        </div>

        <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
    </>)
}
export default AuditLog